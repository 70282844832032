/* ------------------------------------------------
  challenge
---------------------------------------------- */
[data-contents="original"] {
  .challenge-top {
    position: relative;
    margin: 0 auto 205px;
    padding: 35px 0 15px;
    border: 1px solid $blue;
    background: #f0f0f0;
    box-sizing: border-box;
    text-align: center;
    &:before {
      position: absolute;
      bottom: -135px;
      left: 50%;
      width: 50px;
      height: 50px;
      border-right: 1px solid $blue;
      border-top: 1px solid $blue;
      content: "";
      margin-left: -25px;
      transform: rotate(135deg);
      @include mq(sp) {
        bottom: -70px;
      }
    }
    &.challenge-page {
      margin: 0 auto 140px;
      @include mq(sp) {
        margin: 0 auto 100px;
      }
      &:before {
        bottom: -80px;
        @include mq(sp) {
          bottom: -60px;
        }
      }
    }
    @include mq(sp) {
      width: 100%;
      margin: 0 0 120px;
      box-sizing: border-box;
      padding: 10px 0;
    }
    &__list {
      text-align: center;
      margin: 0;
      &-note {
        text-align: left;
        margin: 0;
        padding: 0 20px;
        @include mq(sp) {
          font-size: 10px;
          padding: 0 10px;
        }
      }
    }
    &__item {
      display: inline-block;
      list-style: none;
      margin: 0;
      width: 300px;
      height: 300px;
      background: #0064aa;
      text-align: center;
      border-radius: 50%;
      margin:0 8px 20px;
      @include mq(sp) {
        width: 110px;
        height: 110px;
        margin: 0 5px 5px;
      }
    }
    &__ttl {
      font-size: 23px;
      text-align: center;
      background: transparent;
      text-align: center;
      border: none;
      box-shadow: none;
      overflow: inherit !important;
    }
    &__inner {
      position: relative;
      display: table;
      width: 300px;
      height: 300px;
      @include mq(sp) {
        width: 110px;
        height: 110px;
      }
    }
    &__txt {
      display: table-cell;
      vertical-align: middle;
      padding-top: 10px;
      color: #fff;
      font-size: 30px;
      line-height: 1.2;
      letter-spacing: 0.06em;
      font-weight: bold;
      margin: 0;
      @include mq(sp) {
        font-size: 12px;
      }
      span {
        display: block;
        font-size: 20px;
        @include mq(sp) {
          font-size: 10px;
        }
      }
    }
    &__vision {
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin: 0;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 10px;
        top: 10px;
      }
    }
    &-message {
      margin: 0 0 30px;
      font-size: 25px;
      text-align: center;
      color: $blue;
      @include mq(sp) {
        font-size: 15px;
      }
    }
    &--no-arrow {
      margin-bottom: 30px;
      &:before {
        display: none;
      }
    }
  }
  .challenge-page {
    &__list {
      text-align: center;
      margin: 0;
      @include mq(sp) {
      }
    }
    &__item {
      position: relative;
      display: inline-block;
      list-style: none;
      margin: 0;
      width: 170px;
      height: 170px;
      background: #808080;
      text-align: center;
      border-radius: 50%;
      margin:0 8px 20px;
      @include mq(sp) {
        width: 110px;
        height: 110px;
        margin: 0 5px 5px;
      }
    }
    &__ttl {
      font-size: 23px;
      text-align: center;
      background: transparent;
      text-align: center;
      border: none;
      padding: 0;
      box-shadow: none;
      overflow: inherit !important;
      @include mq(sp) {
        font-size: 16px;
      }
    }
    &__inner {
      position: relative;
      display: table;
      width: 170px;
      height: 170px;
      @include mq(sp) {
        width: 110px;
        height: 110px;
      }
    }
    &__txt {
      display: table-cell;
      vertical-align: middle;
      padding-top: 10px;
      color: #fff;
      font-size: 21px;
      line-height: 1.2;
      letter-spacing: 0.06em;
      font-weight: bold;
      margin: 0;
      @include mq(sp) {
        font-size: 12px;
      }
    }
    &__step {
      position: absolute;
      top: 20px;
      left: 0;
      width: 100%;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin: 0;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 10px;
        top: 10px;
      }
    }
  }
  .challenge-section {
      &__ttl {
      position: relative;
      margin: 0 auto 20px;
      font-size: 60px;
      color: $blue;
      padding: 0 0 23px;
      overflow: inherit !important;
        &:after {
          display: none;
        }
      @include mq(sp) {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
      &-sub {
        display: block;
        text-align: center;
        color: $blue;
        font-size: 20px;
        letter-spacing: 0.22em;
        font-weight: bold;
        @include mq(sp) {
          margin-bottom: 20px;
          font-size: 12px;
        }
      }
      &:before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 150px;
        height: 2px;
        background: $blue;
        transform: translateX(-50%);
        content: "";
        @include mq(sp) {
          width: 100px;
        }
      }
    }
  }
  .challenge-links {
    padding-top: 50px;
    padding-bottom: 20px;
    @include mq(sp) {
      padding-top: 20px;
    }
  }
  .challenge-page-txt {
    margin-top: 50px !important;
    font-size: 29px;
    font-weight: bold;
    color: $blue;
    text-align: center;
    @include mq(sp) {
      margin-top: 20px !important;
      font-size: 16px;
    }
  }
  .challenge-kadai {
    @include clearfix;
    margin-left: -30px;
    @include mq(sp) {
      margin-left: 0;
    }
    li {
      float: left;
      width: 340px;
      list-style: none;
      margin-left: 30px;
      @include mq(sp) {
        width: 100%;
        float: none;
        margin-left: 0;
      }
    }
    &__top {
      background: $blue;
      height: 340px;
      padding: 60px 0 0;
      box-sizing: border-box;
      color: #fff;
      text-align: center;
      @include mq(sp) {
        width: 100%;
        height: auto;
        padding: 15px 0;
      }
    }
    &__bottom {
      height: 595px;
      box-sizing: border-box;
      border: 1px solid $blue;
      padding: 30px;
      @include mq(sp) {
        height: auto;
        margin-bottom: 10px;
        padding: 15px 15px 1px;
        img {
          margin: 0 auto 20px;
        }
      }
    }
    &__number {
      color: #b4c8f5;
      font-size: 21px;
      margin: 0 0 50px;
      padding: 0;
      letter-spacing: 0.06em;
      overflow: inherit !important;
      @include mq(sp) {
        font-size: 13px;
        margin: 0 0 10px;
      }
      &:after {
        display: none;
      }
    }
    &__ttl {
      margin: 0;
      font-size: 26px;
      font-weight: bold;
      @include mq(sp) {
        font-size: 15px;
      }
    }
    &__txt {
      font-size: 21px;
      @include mq(sp) {
        font-size: 13px;
      }
    }
  }
  .challenge-bg-txt {
    margin-top: 0;
    margin-bottom: 50px;
    padding: 35px 10px;
    background: $blue;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    @include mq(sp) {
      font-size: 13px;
    }
  }
  .challenge-line-txt {
    margin-top: 0;
    margin-bottom: 30px;
    padding: 5px 10px;
    background: $blue;
    font-size: 26px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    @include mq(sp) {
      font-size: 13px;
    }
  }
  .challenge-table {
    margin: 0 0 20px;
    @include mq(sp) {
      display: table;
      width: 100%;
      tr {
        display: table-row;
      }
    }
    
    th {
      border-width: 10px;
      border-style: solid;
      border-collapse:separate;
      @include mq(sp) {
        display: table-cell;
        border: 3px solid #f5f5f5;
      }
    }
    td {
      border-width: 10px;
      border-style:  solid;
      border-collapse:separate;
      @include mq(sp) {
        display: table-cell;
        border: 3px solid #f5f5f5;
      }
    }
    thead {
      @include mq(sp) {
        display: table-row-group;
      }
      th {
        padding: 0;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        @include mq(sp) {
          width: 33.3%;
          display: table-cell;
          font-size: 13px;
        }
      }
    }
    tbody {
      @include mq(sp) {
        display: table-row-group;
      }
      th {
        background: #ccc;
        font-size: 24px;
        font-weight: bold;
        
        line-height: 1.4;
        @include mq(sp) {
          font-size: 13px;
          padding: 20px;
        }
        span {
          display: block;
          font-size: 20px;
          @include mq(sp) {
            font-size: 11px;
          }
        }
        .th-inner {
          position: relative;
          padding-left: 60px;
          @include mq(sp) {
            padding-left: 35px;
          }
        }
        .number {
          position: absolute;
          top: 50%;
          left: 10px;
          font-size: 25px;
          margin-right: 20px;
          transform: translateY(-50%);
          @include mq(sp) {
            font-size: 12px;
          }
        }
      }
      td {
        background: #e6e6e6 !important;
        
        text-align: center;
        font-weight: bold;
        line-height: 1.4;
        @include mq(sp) {
          font-size: 15px;
          padding: 20px;
        }
        span {
          display: block;
          font-size: 20px;
          @include mq(sp) {
            font-size: 11px;
          }
        }
      }
      .bg {
        th,td {
          background: $blue !important;
          color: #fff;
        }
      }
    }
    &--01 {
      th,td {
        border-color: #f5f5f5;
      }
      thead {
        th {
          background: #f5f5f5;
        }
      }
      tbody {
        th {
          padding: 32px 20px;
          text-align: center;
        }
        td {
          font-size: 36px;
          padding: 32px 20px;
        }
      }
      
    }
    &--02 {
      th,td {
        border-color: #f0f0f0;
      }
      thead {
        th {
          background: #f0f0f0;
        }
      }
      tbody {
        th {
          padding: 18px 20px;
          text-align: left;
          @include mq(sp) {
            padding: 15px 10px;
          }
        }
        td {
          padding: 18px 20px;
          font-size: 15px;
          @include mq(sp) {
            padding: 15px;
            font-size: 13px;
          }
        }
      }
      
    }
    &--03 {
      table-layout: fixed;
      th,td {
        border-color: #f5f5f5;
      }
      thead {
        th {
          background: #f5f5f5;
          &:not(:first-child) {
            width: 250px;
            box-sizing: border-box;
            @include mq(sp) {
              width: auto;
            }
          }
        }
      }
      tbody {
        th {
          padding: 18px 0 18px 10px;
          text-align: left;
          background: $blue;
          color: #fff;
          @include mq(sp) {
            padding: 15px 10px;
          }
        }
        td {
          font-size: 15px;
          padding: 20px 20px;
          background: #005096 !important;
          color: #fff;
          @include mq(sp) {
            padding: 15px;
            font-size: 13px;
          }
          &:nth-of-type(2n) {
            background: #5064aa !important;
          }
        }
      }
      
    }
  }
  .challenge-system-fig {
    position: relative;
    border: 1px solid $blue;
    background: #f0f0f0;
    margin-bottom: 20px;
    padding: 10px 10px;
    @include mq(sp) {
      padding: 20px 10px 10px;
    }
    &__ttl {
      position: absolute;
      top: -1px;
      left: -1px;
      display: inline-block;
      border: 1px solid $blue;
      background: #f0f0f0;
      font-size: 21px;
      @include mq(sp) {
        font-size: 12px;
      }
    }
  }
  .challenge-system-flow {
    text-align: center;
    margin: 0 0 20px;
    li {
      position: relative;
      display: inline-block;
      list-style: none;
      margin:0 3px 20px;
      vertical-align: top;
    }
    &__item {
      margin: 0;
      width: 170px;
      height: 170px;
      background: $blue;
      text-align: center;
      border-radius: 50%;
      margin-bottom: 15px;
      @include mq(sp) {
        width: 110px;
        height: 110px;
        margin: 0 5px 5px;
      }
    }
    &__inner {
      position: relative;
      display: table;
      width: 170px;
      height: 170px;
      @include mq(sp) {
        width: 110px;
        height: 110px;
      }
    }
    &__ttl {
      display: table-cell;
      vertical-align: middle;
      padding-top: 10px;
      color: #fff;
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0.06em;
      font-weight: bold;
      margin: 0;
      @include mq(sp) {
        font-size: 12px;
      }
    }
    &__step {
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin: 0;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 10px;
        top: 10px;
      }
    }
    &__txt {
      display: table-cell;
      vertical-align: middle;
      font-size: 16px;
      margin: 0;
      line-height: 1.3;
      @include mq(sp) {
        font-size: 12px;
      }
    }
    &__note {
      position: relative;
      display: table;
      padding: 10px 0;
      width: 170px;
      height: 84px;
      box-sizing: border-box;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
      @include mq(sp) {
        width: 110px;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 11px;
        height: 100%;
        border-top: 1px solid $blue;
        border-bottom: 1px solid $blue;
        content: "";
        box-sizing: border-box;
      }
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        width: 11px;
        height: 100%;
        border-top: 1px solid $blue;
        border-bottom: 1px solid $blue;
        content: "";
        box-sizing: border-box;
      }
    }
  }
  .challenge-fig-list {
    @include clearfix;
    margin-left: -30px;
    margin-bottom: 50px;
    @include mq(sp) {
      margin-left: 0;
      margin-bottom: 0;
    }
    li {
      width: 340px;
      float: left;
      list-style: none;
      margin: 0;
      margin-left: 30px;
      @include mq(sp) {
        margin-left: 0;
        margin-bottom: 30px;
      }
    }
    &__inner {
      position: relative;
      width: 340px;
      height: 340px;
      margin-bottom: 10px;
      border: 1px solid $blue;
      box-sizing: border-box;
      @include mq(sp) {
        width: 100%;
        height: auto;
        padding: 10px;
        margin-bottom: 5px;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include mq(sp) {
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0);
        }
      }
    }
    &__ttl {
      margin: 0;
      text-align: center;
      font-size: 16px;
      color: $blue;
      @include mq(sp) {
        font-size: 13px;
      }
    }
  }
  .challenge-ex-ttl {
    font-size: 30px;
    color: #0064aa;
    font-weight: bold;
    border: none;
    padding: 0;
    margin: 60px 0 10px;
    box-shadow: none;
    overflow: inherit !important;
    @include mq(sp) {
      font-size: 16px;
      margin: 40px 0 10px;
    }
    span {
      font-size: 23px;
      @include mq(sp) {
        font-size: 13px;
      }
    }
  }
}