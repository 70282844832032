/* ------------------------------------------------
  career
---------------------------------------------- */
.container-career {
  width: 1000px;
  margin: 0 auto;
  @include mq(sp) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
  }
}
.career-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border-bottom: 3px solid $blue;
  box-sizing: border-box;
  background: #fff;
  padding: 10px 0 0;
  z-index: 90;
  @include mq(sp) {
    padding: 10px 15px;
    height: 50px;
    .header__logo {
      float: left;
      padding: 0;
    }
  }
  .container-career {
    @include clearfix;
    @include mq(sp) {
      padding: 0;
    }
  }
  &__logo-sub {
    font-size: 12px;
    font-weight: bold;
    float: left;
    display: inline-block;
    border: 2px solid $blue;
    margin-top: 26px;
    margin-left: 15px;
    padding: 5px 20px;
    line-height: 1;
    color: $blue;
    @include mq(sp) {
      font-size: 10px;
      float: right;
      padding: 5px 10px;
      margin: 2px 0 0 0;
    }
  }
  &__nav-list {
    float: right;
    margin-top: 26px;
    @include mq(sp) {
      display: none;
    }
  }
  &__nav-item {
    float: left;
    font-size: 12px;
    width: 100px;
    text-align: center;
    color: $blue;
    font-weight: bold;
    border-left: 2px solid $blue;
    a {
      color: $blue;
      display: block;
      transition: all 300ms;
      &:hover {
        background: $blue;
          color:#fff;;
        text-decoration: none;
      }
    }
  }
}
.career-wrap {
  background: #fff;
  &--dtl {
    margin-top: 100px;
    @include mq(sp) {
      margin-top: 0;
    }
  }
}
.career-mv {
  position: relative;
  margin-top: 100px;
  min-width: 1080px;
  @include mq(sp) {
    min-width: inherit;
    margin-top: 0;
  }
  &__txt-wrap {
    position: absolute;
    top: 65px;
    left: 50%;
    margin-left: -535px;
    @include mq(sp) {
      position: static;
      top: 0;
      left: 0;
      margin-left: 0;
    }
  }
  &__ttl {
    font-size: 53px;
    letter-spacing: 0.16em;
    line-height: 1.4;
    color: #002870;
    margin-bottom: 20px;
    @include mq(sp) {
      position: absolute;
      top: 50%;
      left: 15px;
      font-size: 20px;
      width: 175px;
      transform: translateY(-50%);
      margin-top: -100px;
    }
  }
  &__txt {
    font-size: 17px;
    font-family: 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    color: #002870;
    font-weight: bold;
    letter-spacing: 0.14em;
    @include mq(sp) {
      font-size: 12px;
      padding: 0 15px;
      text-align: center;
    }
  }
}
.career-block {
  position: relative;
  &__ttl {
    position: relative;
    text-align: center;
    font-size: 50px;
    letter-spacing: 0.1em;
    margin-bottom: 5px;
    color: $blue;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 20px;
    @include mq(sp) {
      font-size: 24px;
      padding-bottom: 10px;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -60px;
      width: 120px;
      height: 5px;
      background: $blue;
      content: "";
    }
  }
  &__ttl-sub {
    text-align: center;
    font-size: 20px;
    color: $blue;
    font-weight: bold;
    margin-bottom: 30px;
    letter-spacing: 0.16em;
    @include mq(sp) {
      font-size: 12px;
    }
  }
  &--appeal {
    margin-top: -325px;
    padding-top: 50px;
    background: transparent;
    @include mq(sp) {
      margin-top: 0;
      padding: 40px 0;
    }
    &:before {
      position: absolute;
      bottom: 109px;
      width: 100%;
      height: 71px;
      background: url(../img/carrer/bg_mv_bottom_01.png) no-repeat center top;
      content: "";
      z-index: 1;
      @include mq(sp) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 109px;
      background: #f1f1f1;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
  }
  &--works {
    padding: 130px 0 30px;
    margin-top: -100px;
    background: #f1f1f1;   
    @include mq(sp) {
      margin-top: 0;
    }
  }
  &--interview {
    padding: 140px 0 1px;
    background: $blue;
    @include mq(sp) {
      padding: 40px 0 1px;
    }
    .career-block__ttl {
      color: #fff;
      &:before {
        background: #fff;
      }
    }
    .career-block__ttl-sub {
      color: #fff;
    }
  }
  &--recruit {
    padding: 140px 0 90px;
    @include mq(sp) {
      padding: 40px 0;
    }
  }
}
.appeal-list {
  position: relative;
  @include clearfix;
  width: 825px;
  margin: 0 auto;
  z-index: 2;
  @include mq(sp) {
    width: 100%;
    text-align: center;
  }
  &__item {
    position: relative;
    float: left;
    width: 235px;
    height: 235px;
    padding: 15px 10px;
    margin-left: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    @include gradient(rgba(25,59,148,0.9), #00005a, horizontal);
    color: #fff;
    letter-spacing: 0.1em;
    line-height: 1.4;
    @include mq(sp) {
      float: none;
      display: inline-block;
      width: 150px;
      height: 150px;
      margin: 0 10px 15px;
      padding: 5px 10px;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../img/carrer/bg_logo.png);
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      content: "";
    }
  }
  &__num {
    display: block;
    margin-bottom: 15px;
    font-size: 19px;
    font-weight: bold;
    @include mq(sp) {
      font-size: 10px;
      margin-bottom: 5px;
    }
  }
  &__ttl {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 12px;
    }

  }
  &__txt {
    font-size: 12px;
    @include mq(sp) {
      font-size: 10px;
    }
  }
}
.career-works {
  position: relative;
  margin-top: 40px;
  @include clearfix;
  &:before {
    position: absolute;
    left: 50%;
    margin-left: -3.5px;
    width: 7px;
    height: 100%;
    background: $blue;
    content: "";
    z-index: 4;
    @include mq(sp) {
      display: none;
    }
  }
  &__logo {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto 30px;
    z-index: 5;
    @include mq(sp) {
      width: auto;
      height: auto;
      
      margin: 0 auto 0;
      img {
        width: 100px;
      }
    }
    &-txt {
      position: absolute;
      right: -335px;
      top: 30px;
      font-size: 21px;
      color: $blue;
      font-weight: normal;
      @include mq(sp) {
        position: relative;
        right: 0;
        top: 0;
        margin: 5px 0 15px;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}
.career-works-block {
  position: relative;
  margin: 0 auto 65px;
  width: 450px;
  @include mq(sp) {
    margin: 0 0 20px;
    width: 100%;
  }
  &__txt {
    position: relative;
    width: 450px;
    font-size: 15px;
    font-weight: normal;
    z-index: 5;
    @include mq(sp) {
      width: 100%;
      background: #f1f1f1;
      padding: 10px 0;
      font-size: 13px;
    }
  }
  &--01 {
    float: left;
    @include mq(sp) {
      float: none;
    }
    .career-works-item:before {
      right: -50px;
      @include mq(sp) { 
        display: none;
      }
    }
  }
  &--02 {
    float: right;
    margin-top: 40px;
    @include mq(sp) {
      float: none;
      margin-top: 0;
    }
    .career-works-item:before {
      left: -50px;
      @include mq(sp) { 
        display: none;
      }
    }
  }
  &:last-child {
    margin-bottom: 20px;
    &:before {
      position: absolute;
      top: 117px;
      right: -120px;
      width: 150px;
      height: 200px;
      background: #f1f1f1;
      transform: rotate(-20deg);
      content: "";
      z-index: 4;
      @include mq(sp) {
        display: none;
      }
    }
  }
}
.career-works-item {
  position: relative;
  margin-bottom: 20px;
  @include clearfix;
  @include mq(sp) {
    margin-bottom: 0;
  }
  &:before {
    position: absolute;
    transform: rotate(-20deg);
    top: 50%;
    width: 60px;
    height: 7px;
    background: $blue;
    content: "";
    z-index: 2;
  }
  &__img {
    position: relative;
    float: left;
    z-index: 3;
    @include mq(sp) {
      position: absolute;top: 0;
      left: 0;
      z-index: 10;
      width: 100px;
      height: 170px;
      overflow: hidden;
    }
  }
  &__txt-wrap {
    position: relative;
    display: table;
    margin-left: 132px;
    width: 318px;
    height: 225px;
    background: #003a8a;
    z-index: 5;
    @include mq(sp) {
      width: 100%;
      height: 170px;
      margin-left: 0;
      padding-left: 100px;
      box-sizing: border-box;

    }
  }
  &__txt-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }
  &__num {
    position: absolute;
    top: 2px;
    right: 0;
    padding-right: 27px;
    border-bottom: 2px solid #fff;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 0.1em;
    @include mq(sp) {
      font-size: 12px;
    }
  }
  &__ttl {
    padding-top: 17px;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
    @include mq(sp) {
      font-size: 18px;
      font-weight: bold;
    }
    span {
      font-size: 22px;
      display: block;
      @include mq(sp) {
        font-size: 14px;
      }
    }
  }
  &__txt {
    position: relative;
    font-size: 16px;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 12px;
    }
  }
}
_:-ms-lang(x), .career-works-item__num {
  top: 10px;
  line-height: 1;
}
.career-interview {
  padding-top: 50px;
  @include mq(sp) {
    padding-top: 0;
  }
}
.career-interview-item {
  margin-bottom: 50px;
  background: #fff;
  &__ttl {
    position: relative;
    font-size: 17px;
    margin-bottom: 10px;
    color: $blue;
    font-weight: bold;
    border-bottom: 3px solid #c7c7c7;
    letter-spacing: 0.12em;
    @include mq(sp) {
      font-size: 15px;
    }
    &:before {
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 230px;
      height: 3px;
      background: $blue;
      content: "";
    }
  }
  &__txt {
    font-size: 13px;
    letter-spacing: 0.12em;
    font-weight: normal;
    line-height: 2;
    @include mq(sp) {
      font-size: 12px;
      line-height: 1.7;
    }
    +.career-interview-item__ttl {
      margin-top: 30px;
      @include mq(sp) {
        margin-top: 20px;
      }
    }
  }
  &__txt-wrap {
    width: 650px;
    padding: 50px;
    box-sizing: border-box;
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding: 15px;
      float: none !important;
    }
  }
  &__img {
    position: relative;
    @include mq(sp) {
      width: 200px;
      height: 250px;
      margin: 0 auto !important;
      overflow: hidden;
    }
  }
  &__name {
    position: absolute;
    bottom: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 12px;
      bottom: 10px;
    }
    span {
      font-size: 23px;
      display: block;
      @include mq(sp) {
        font-size: 18px;
      }
    }
  }
  &--01 {
    .career-interview-item__txt-wrap {
      float: right;
    }
    .career-interview-item__img {
      margin-right: 650px;
    }
    .career-interview-item__name {
      left: 25px;
    }
  }
  &--02 {
    .career-interview-item__txt-wrap {
      float: left;
    }
    .career-interview-item__img {
      margin-left: 650px;
    }
    .career-interview-item__name {
      right: 25px;
      text-align: right;
    }
  }
}

.career-recruit {
  
}
.career-recruit-block {
  text-align: center;
  margin-bottom: 50px;
  &__category {
    display: inline-block;
    border: 1px solid $blue;
    margin: 60px 0;
    width: 330px;
    padding: 5px 0;
    font-size: 21px;
    color: #464646;
    font-weight: bold;
    @include mq(sp) {
      font-size: 16px;
      width: 280px;
      margin: 20px 0;
    }
  }
  &__list {
    @include clearfix;
    margin-left: -50px;
    @include mq(sp) {
      margin-left: 0;
    }
  }
  &__item {
    position: relative;
    width: 300px;
    float: left;
    margin-left: 50px;
    margin-bottom: 50px;
    padding-bottom: 30px;
    @include mq(sp) {
      margin-left: 0;
      float: none;
      width: 100%;
      height: auto !important;
      margin: 0 auto 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed$blue;
    }
    >a {
      display: block;
      @include clearfix;
      &:hover {
        .career-recruit-block__dtl {
          opacity: .7;
          
        }
      }
    }
  }
  &__dtl {
    position: relative;
    height: 250px;
    margin-bottom: 10px;
    opacity: 1;
    transition: opacity 300ms;
    @include mq(sp) {
      width: 180px;
      height: 150px;
      float: left;
    }
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 250px;
    display: table;
    text-align: center;
    @include mq(sp) {
      width: 180px;
      height: 150px;
    }
  }
  &__txt-wrap {
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    padding: 0 15px;
    @include mq(sp) {
      padding: 0 10px;
    }
  }
  &__ttl {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 10px;
    @include mq(sp) {
      font-size: 16px;
    }
    span {
      display: block;
      font-size: 22px;
      @include mq(sp) {
        font-size: 15px;
      }
    }
  }
  &__txt {
    font-size: 16px;
    @include mq(sp) {
      font-size: 12px;
    }
  }
  &__note {
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    line-height: 1.4;
    margin-bottom: 20px;
    @include mq(sp) {
      margin-left: 195px;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  &__contact {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include mq(sp) {
      clear: both;
      position: relative;
    }
    li {
      display: inline-block;
      font-size: 13px;
      border: 2px solid $blue;
      text-align: center;
      border-radius: 5px;
      margin: 0 5px;
      @include mq(sp) {
        display: inline-block !important;
      }
      a {
        display: block;
        padding: 5px 25px;
        line-height: 1;
        color: $blue;
        transition: all 300ms;
        font-weight: bold;
        &:hover {
          background: $blue;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  &__announce {
    color: $blue;
    font-size: 24px;
    @include mq(sp) {
      font-size: 14px;
    }
  }
}
_:-ms-lang(x), .career-recruit-block__category {
  padding: 11px 0 5px;
}

.career-footer {
  background: $blue;
  color: #fff;
  text-align: center;
  
  .container-career {
    @include clearfix;
  }
  &__ttl {
    margin-bottom: 15px;
    padding-top: 15px;
    font-size: 20px;
    @include mq(sp) {
      font-size: 16px;
    }
  }
  &__nav {
    padding-bottom: 20px;
    @include mq(sp) {
      padding-bottom: 10px;
    }
    &-list {
    }
    li {
      display: inline-block;
      border-left: 1px solid #fff;
      line-height: 1;
      @include mq(sp) {
        margin-bottom: 10px;
      }
    }
    a {
      display: block;
      padding: 10px 20px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      transition: all 300ms;
      min-width: 150px;
      box-sizing: border-box;
      @include mq(sp) {
        font-size: 12px;
        min-width: inherit;
        padding: 5px 15px;
      }
      &:hover {
        background: #fff;
        color: $blue;
        text-decoration: none;
      }
    }
    
  }
  &__logo {
    display: block;
    float: left;
    @include mq(sp) {
      float: none;
      padding: 0;
    }
  }
  &__copyright {
    padding: 10px 0;
    
    .footer__copyright-txt {
      text-align: left;
      padding-top: 5px;
      margin-left: 290px;
      font-size: 13px;
      color: $blue;
      @include mq(sp) {
        text-align: left;
        margin-left: 0;
        font-size: 10px;
      }
    }
  }
}
.career-post {
  padding: 100px 0 1px;
  background: #f5f5f5;
  @include mq(sp) {
    padding: 40px 0 10px;
  }
  &__ttl {
    font-size: 36px;
    font-weight: bold;
    color: $blue;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  +.career-wrap {
    padding: 80px 0 0;
    @include mq(sp) {
      padding: 40px 0 0;
    }
  }
}
.career-post-container {
  background: #fff;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 30px 65px 1px; 
  border: 1px solid #999;
  @include mq(sp) {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 15px 15px 1px;
  }
}
.career-table {
  margin-bottom: 50px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  th {
    width: 150px;
    background: #f0f0f0;
    padding: 25px 20px;
    box-sizing: border-box;
    font-size: 16px;
    vertical-align: middle;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    @include mq(sp) {
      display: block;
      width: 100%;
      padding: 10px 10px;
      text-align: center;
      font-size: 13px;
      border-bottom: none;
    }
  }
  td {
    padding: 30px 30px;
    font-size: 16px;
    background: #fff !important;
    border-bottom: 1px solid #ccc;
    @include mq(sp) {
      display: block;
      padding: 10px;
      font-size: 13px;
      border-left: 1px solid #ccc;
    }
  }
}
#contact_form {
  padding-top: 140px;
  margin-top: -90px;
  @include mq(sp) {
    padding-top: 0;
    margin-top: 0;
  }
}