/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap,.fade {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration:none;
  }
}
.ib {
  display: inline-block;
}
.phoneTxt {
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.phoneTxt-wrap {
  position: relative;
}
.phoneTxt-number {
  display: none;
  position: absolute;
  top: -20px;
  left: 5px;
  width: 200px;
  height: 50px;
  line-height: 50px;
  border: 1px solid $blue;
  text-align: center;
  background: $blue;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.1em;
  &.show {
    display: block;
    animation: showAnime 300ms;
  }
  &-close {
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid $blue;
    box-sizing: border-box;
    content: "";
    cursor: pointer;
    &:before {
      position: absolute;
      top: 12px;
      left: 4px;
      width: 18px;
      height: 2px;
      background: $blue;
      content: "";
      transform: rotate(45deg);
    }
    &:after {
      position: absolute;
      top: 12px;
      left: 4px;
      width: 18px;
      height: 2px;
      background: $blue;
      content: "";
      transform: rotate(-45deg);
    }
  }
}
.is_pc {
  @include mq(sp) {
    display: none;
  }
}
.is_sp {
  display: none !important;
  @include mq(sp) {
    display: block !important;
  }
}
.container {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  @include mq(sp) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    
  }
}