/* ------------------------------------------------
  page
---------------------------------------------- */
.mv {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 500px;
  padding-top: 250px;
  box-sizing: border-box;
  @include mq(sp) {
    height: 150px;
    padding-top: 50px;
  }
  &--company {
    background-image: url(../img/company/mv_top_01.jpg);
  }
  &__ttl {
    margin-bottom: 10px;
    font-size: 65px;
    letter-spacing: 0.06em;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 28px;
    }
  }
  &__sub {
    font-size: 27px;
    letter-spacing: 0.2em;
    color: #fff;
    @include mq(sp) {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
.head {
  border-bottom: 1px solid $blue;
  padding:  50px 0 40px;
  border-top: solid 194px $blue;
  @include mq(sp) {
    border-top: none;
    padding: 20px 0;
  }
  background: #fff;
  text-align: center;
  &__ttl {
    font-size: 65px;
    letter-spacing: 0.06em;
    font-weight: bold;
    color: $blue;
    line-height: 1;
    margin-bottom: 10px;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 28px;
    }
  }
  &__sub {
    font-size: 27px;
    letter-spacing: 0.2em;
    color: $blue;
    @include mq(sp) {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
.page-block {
  padding: 40px 0;
  @include mq(sp) {
    padding: 10px 0 20px;
  }
  &__ttl {
    font-weight: bold;
    color: $blue;
    margin-bottom: 40px;
    font-size: 30px;
    overflow: inherit !important;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 18px;
    }
    &--center {
      text-align: center;
      font-size: 36px;
      @include mq(sp) {
        font-size: 18px;
      }
    }
  }
  &__line-ttl {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 5px solid #b4b4b4;
    font-size: 30px;
    font-weight: bold;
    color: $blue;
    letter-spacing: 0.06em;
    margin: 0 0 40px;
    padding: 0 0 10px;
    text-align: left;
    overflow: inherit;
    @include mq(sp) {
      margin-bottom: 10px;
      padding-bottom: 5px;
      font-size: 18px;
      border-bottom: 3px solid #b4b4b4;
    }
    &:before {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 150px;
      height: 5px;
      background: $blue;
      content: "";
      @include mq(sp) {
        height: 3px;
        bottom: -3px;
      }
    }
    &:after {
      display: none;
    }
    &--min {
        font-size: 24px;
        @include mq(sp) {
          font-size: 18px;
        }
      }
    a {
      color: $blue;
    }
  }
  &--mb {
      margin-bottom: 50px;
    }
}
[data-contents="original"] {
  .page-block {
    padding: 40px 0;
    @include mq(sp) {
      padding: 10px 0 20px;
    }
    &__ttl {
      font-weight: bold;
      color: $blue;
      padding: 0;
      margin: 0 0 40px;
      font-size: 30px;
      text-align: left;
      overflow: inherit !important;
      &:after {
        display: none;
      }
      @include mq(sp) {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
    &__line-ttl {
      position: relative;
      margin-bottom: 40px;
      padding-bottom: 10px;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 5px solid #b4b4b4;
      font-size: 30px;
      font-weight: bold;
      color: $blue;
      letter-spacing: 0.06em;
      margin: 0 0 40px;
      padding: 0 0 10px;
      text-align: left;
      background: transparent;
      overflow: inherit;
      box-shadow: none;
      span {
        font-size: 18px;
      }
      @include mq(sp) {
        margin-bottom: 10px;
        padding-bottom: 5px;
        font-size: 18px;
        border-bottom: 3px solid #b4b4b4;
      }
      &:before {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 150px;
        height: 5px;
        background: $blue;
        content: "";
        @include mq(sp) {
          height: 3px;
          bottom: -3px;
        }
      }
      &:after {
        display: none;
      }
      &--min {
        font-size: 24px;
        @include mq(sp) {
          font-size: 18px;
        }
      }
    }
    &__inner {
      padding: 0 30px;
      @include mq(sp) {
        padding: 0;
      }
      &--mb {
        margin-bottom: 50px;
      }
    }
    &__txt {
      margin: 0 0 30px;
      font-size: 21px;
      @include mq(sp) {
        margin: 0 0 20px;
        font-size: 13px;
      }
      &--center {
        text-align: center;
        font-size: 27px;
        color: $blue;
        letter-spacing: 0.1em;
        @include mq(sp) {
          font-size: 13px;
        }
      }
    }
    &__img {
      margin: 0 auto 30px;
      &--kigyorinen {
        width: 630px;
        @include mq(sp) {
          width: auto;
        }
      }
      &--left {
        margin: 0 auto 50px;
        img {
          margin: 0 auto 0 0;
        }
      }
    }
    &__img-txt {
      @include clearfix;

      .page-block__img {
        float:right;
        margin: 0 0 20px 20px;
        @include mq(sp) {
          float: none;
          margin: 0 auto 10px;
        }
      }
      .page-block__txt {
        margin: 0 0 30px;
      }
    }
    &__img-half {
      @include clearfix;
      .page-block__img {
        float: left;
        margin-left: 10px;
      }
    }
    .business-check-box {
      width: 690px;
      @include mq(sp) {
        width: 100%;
        margin-top: 20px;
      }
    }
    &__thumb-txt {
      @include clearfix;

      .page-block__img {
        float:left;
        margin: 0 0 20px 20px;
        @include mq(sp) {
          float: none;
          margin: 0 auto 10px;
        }
      }
      .page-block__txt-wrap {
        margin-left: 330px;
        @include mq(sp) {
          margin-left: 0;
        }
      }
    }
    &--arrow {
      position: relative;
      margin: 0 auto 140px;
      padding: 0 0 15px;
      @include mq(sp) {
        margin: 0 0 80px;
      }
      &:before {
        position: absolute;
        bottom: -60px;
        left: 50%;
        width: 50px;
        height: 50px;
        border-right: 1px solid $blue;
        border-top: 1px solid $blue;
        content: "";
        margin-left: -25px;
        transform: rotate(135deg);
        @include mq(sp) {
          bottom: -40px;
        }
      }
    }
    &--mb {
      margin-bottom: 50px;
    }
  }
  .page-contents-ttl {
    font-size: 30px;
    color: #0064aa;
    margin: 0 0 30px;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    text-align: left;
    font-weight: bold;
    overflow: inherit !important;
    @include mq(sp) {
      font-size: 18px;
      margin: 0 0 20px;
    }
    &:after {
      display: none;
    }
    &--mb0 {
      margin-bottom: 0;
    }
  }
  .page-contents-head {
    margin-bottom: 100px;
    display: block;
    background: $blue;
    padding: 5px;
    text-align: center;
    &:hover {
      text-decoration: none;
    }
    @include mq(sp) {
      margin-bottom: 40px;
    }
    &__inner {
      border: 2px solid #fff;
      padding: 20px 0 20px;
      @include mq(sp) {
        padding: 10px 0;
      }
    }
    &__sub {
      display: block;
      margin: 0 0 5px;
      font-size: 21px;
      color: #fff;
      letter-spacing: 0.1em;
      line-height: 1;
      @include mq(sp) {
        font-size: 10px;
      }
    }
    &__ttl {
      margin: 0;
      font-size: 34px;
      letter-spacing: 0.06em;
      background: transparent;
      border: none;
      box-shadow: none;
      color: #fff;
      padding: 0;
      overflow: inherit !important;
      span {
        font-size: 30px;
        font-weight: normal;
        @include mq(sp) {
          font-size: 13px;
        }
      }
      @include mq(sp) {
        font-size: 13px;
      }
    }
    .recruit-bnr-list {
      margin-top: 30px;
      @include mq(sp) {
        margin-top: 10px;
        padding: 0 10px;
      }
    }
  }
  .page-form-info {
    margin-bottom: 100px;
    display: block;
    background: $blue;
    padding: 5px;
    text-align: center;
    &:hover {
      text-decoration: none;
      .page-form-info__ttl {
        transform: scale(1.1);
      }
    }
    @include mq(sp) {
      margin-bottom: 40px;
    }
    &__inner {
      border: 2px solid #fff;
      padding: 20px 0 20px;
      @include mq(sp) {
        padding: 10px 0;
      }
    }
    &__ttl {
      margin: 0;
      font-size: 34px;
      letter-spacing: 0.06em;
      background: transparent;
      border: none;
      box-shadow: none;
      color: #fff;
      padding: 0;
      transition: transform 300ms;
      overflow: inherit !important;
      span {
        font-size: 30px;
        font-weight: normal;
        @include mq(sp) {
          font-size: 13px;
        }
      }
      @include mq(sp) {
        font-size: 13px;
      }
    }
  }
  .page-bg-box {
    position: relative;
    background: #f0f0f0;
    border: 1px solid $blue;
    margin: 0 auto 50px;
    width: 1027px;
    padding: 25px 30px;
    box-sizing: border-box;
    @include clearfix;
    @include mq(sp) {
      width: 100%;
      padding: 15px;
    }
    &--flow {
      &:before {
        position: absolute;
        bottom: -30px;
        right: 130px;
        width: 14px;
        height: 14px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
        content: "";
        transform: rotate(135deg);
        @include mq(sp) {
          right: inherit;
          left: 50%;
          margin-left: -14px;
        }
      }
    }
    &__ttl {
      position: relative;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 0.06em;
      background: transparent;
      border: none;
      box-shadow: none;
      padding: 0 0 0 20px;
      margin: 0 0 10px;
      color: $blue;
      overflow: inherit !important;
      @include mq(sp) {
        font-size: 14px;
      }
      &:before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: $blue;
        content: "";
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  
  _:-ms-lang(x), .page-bg-box--flow:before {
    margin-top: -6px;
    
  }
  .page-jump-link {
    @include clearfix;
    margin-left: -20px;
    &--2 {
      .page-jump-link__item {
        width: 530px;
        @include mq(sp) {
          width: 100%;
          left: 0 !important;
        }
      }
    }
    &--3 {
      .page-jump-link__item {
        width: 345px;
        @include mq(sp) {
          width: 100%;
          left: 0 !important;
        }
      }
    }
    @include mq(sp) {
      margin-left: 0;
      padding: 0 5px;
    }
    &__item {
      float: left;
      margin-left: 20px;
      margin-bottom: 20px;
      
      list-style: none;
      width: 255px;
      box-sizing: border-box;
      font-size: 21px;
      text-align: center;
      letter-spacing: 0.06em;
      @include mq(sp) {
        position: relative;
        width: 50%;
        margin-left: 0;
        margin-bottom: 10px;
        font-size: 13px;
        &:nth-child(odd) {
          left: -5px;
        }
        &:nth-child(even) {
          left: 5px;
        }
      }
      &-link {
        background: #f5f5f5;
        border: 1px solid $blue;
        display: block;
        padding: 5px 0;
        color: $blue;
        @include hover-opacity;
      }
    }
  }
  _:-ms-lang(x), .page-jump-link__item-link {
    padding: 11px 0 0;
  }
  .page-top-wrap {
    text-align: right;
    margin-bottom: 40px;
    .page-top {
      position: relative;
      display: inline-block;
      color: $blue;
      font-size: 18px;
      @include mq(sp) {
        font-size: 13px;
      }
      &:before {
        position: absolute;
        top: 50%;
        width: 15px;
        height: 15px;
        background: $blue;
        left: -30px;
        transform: translateY(-50%);
        content: "";
      }
      &:after {
        position: absolute;
        top: 50%;
        width: 8px;
        height: 8px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        left: -24px;
        transform: rotate(-45deg) translateY(-50%);
        content: "";
      }
    }
  }
  
  //privacy
  .page-top-txt {
    margin: 60px 0 40px !important;
    font-size: 26px;
    color: $blue;
    text-align: center;
    @include mq(sp) {
      margin: 20px 0 20px !important;
      font-size: 15px;
    }
  }
  .privacy-sub-ttl {
    color: $blue;
    font-size: 19px;
    font-weight: bold;
    border: none;
    margin: 0;
    box-shadow: none;
    padding: 0;
    overflow: inherit !important;
    @include mq(sp) {
      font-size: 15px;
    }
  }
  .privacy-txt {
    font-size: 19px;
    margin: 0;
    @include mq(sp) {
      margin: 0 0 20px;
      font-size: 13px;
    }
    li{
      list-style: none;
    }
    &-ttl {
      color: $blue;
      font-weight: bold;
      display: block;
    }
    +.privacy-sub-ttl {
      margin-top: 40px;
      @include mq(sp) {
        margin-top: 20px;
      }
    }
  }
  .privacy-abc-list {
    font-size: 19px;
    margin: 0 0 0 2em;
    text-indent: -2em;
    @include mq(sp) {
      margin: 0 0 20px;
      font-size: 13px;
    }
    li {
      list-style: none;
      overflow: inherit;
    }
  }
  .privacy-btm-txt {
    padding-bottom: 60px;
    font-weight: bold;
    text-align: right;
  }
  .security-number-list {
    margin-top: 40px;
    font-size: 19px;
    @include mq(sp) {
      margin: 0 0 20px;
      font-size: 13px;
    }
    li {
      margin: 0 0 10px 1em;
      text-indent: -1em;
      list-style: none;
      overflow: inherit;
      line-height: 1.94;
    }
  }
  .security-list {
    margin-top: 40px;
    margin-left: 40px;
    @include mq(sp) {
      margin-left: 0;
      margin-top: 20px;
    }
    li {
      text-indent: -40px;
      line-height: 1.9;
      @include mq(sp) {
        text-indent: -1em;
        margin-left: 1em;
      }
      &:first-letter {
        margin-right: 20px;
        @include mq(sp) {
          margin-right: 0;
        }
      }
    }
  }
  .privacy-center-ttl {
    margin-bottom: 90px;
    margin-top: 40px;
    text-align: center !important;
    @include mq(sp) {
      margin-bottom: 60px;
    }
  }
  .sitemap-wrap {
    margin-top: 60px;
    padding-bottom: 60px;
    @include clearfix;
    @include mq(sp) {
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }
  .sitemap-block {
    @include clearfix;
    margin-left: 286px;
    @include mq(sp) {
      margin-left: 0;
    }
    &--career {
      position: relative;
      margin-left: 510px;
      @include mq(sp) {
        margin-left: 0;
      }
    }
  }
  .sitemap-item-wrap {
    position: relative;
    float: left;
    @include mq(sp) {
      float: none;
    }
    &:before,
    &:after{
      position: absolute;
      content: "";
      background: $blue;
      z-index: 2;
      @include mq(sp) {
        display: none;
      }
    }
    &--line {
      &:before {
        top: 30px;
        left: 30px;
        width: 2px;
      }
    }
    &--company {
      &:before {
        height: 482px;
      }
    }
    &--business {
      &:before {
        height: 402px;
      }
    }
    &--challenge {
      &:before {
        height: 162px;
      }
    }
    &--recruit {
      &:before {
        height: 242px;
      }
      &:after {
        top: 30px;
        left: 0;
        width: 35px;
        height: 2px;
      }
    }
    &--recruit-info {
      &:before {
        height: 322px;
        top: 30px;
        left: 34px;
        width: 2px;
      }
    }
  }
  .sitemap-item {
    position: relative;
    width: 220px;
    height: 60px;
    box-sizing: border-box;
    border: 2px solid $blue;
    text-align: center;
    @include mq(sp) {
      width: auto;
      height: auto;
      border: none;
      text-align: left;
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      background: $blue;
      z-index: 2;
      @include mq(sp) {
        display: none;
      }
    }
    &:before {
      position: absolute;
      content: "";
      background: $blue;
      z-index: 2;
    }
    a {
      height: 56px;
      display: block;
      background: #fff;
      color: $blue;
      font-size: 22px;
      line-height: 56px;
      font-weight: bold;
      transition: all 300ms;
      box-sizing: border-box;
      @include mq(sp) {
        font-size: 13px;
        line-height: 1.3;
        height: auto;
        position: relative;
        padding: 5px 20px;
        border: 1px solid $blue;
      }
      &:hover {
        background: $blue;
        color: #fff;
        text-decoration: none;
      }
    }
    .small {
      font-size: 18px;
      line-height: 1.3;
      padding-top: 5px;
      @include mq(sp) {
        font-size: 13px;
      }
    }
    .small-line {
      font-size: 18px;
      line-height: 56px;
      @include mq(sp) {
        font-size: 13px;
        line-height: 1.3;
      }
    }
    &--1 {
      position: absolute;
      top: 0;
      left: 0;
      @include mq(sp) {
        position: relative;
        margin-bottom: 10px;
      }
      &:before {
        top: 28px;
        right: -35px;
        width: 35px;
        height: 2px;
      }
      &:after {
        top: 28px;
        right: -35px;
        width: 2px;
        height: 1282px;
      }
    }
    &--2 {
      @include mq(sp) {
        margin-left: 30px;
        margin-bottom: 10px;
        margin-top: 15px;
        a {
          &:before {
            position: absolute;
            top: 50%;
            left: -10px;
            width: 10px;
            height: 2px;
            background: $blue;
            content: "";
          }
        }
      }
      &:before {
        top: 28px;
        left: -35px;
        width: 35px;
        height: 2px;
        
      }
      &:after {
        top: 28px;
        right: -37px;
        width: 35px;
        height: 2px;
      }
    }
    &--3 {
      margin-left: 66px;
      margin-bottom: 20px;
      @include mq(sp) {
        margin-left: 60px;
        margin-bottom: 10px;
      }
      &:before {
        top: 28px;
        left: -37px;
        width: 35px;
        height: 2px;
      }
    }
    &--4 {
      margin-left: 66px;
      margin-bottom: 20px;
      @include mq(sp) {
        margin-left: 90px;
        margin-bottom: 10px;
      }
      &:before {
        top: 28px;
        left: -37px;
        width: 35px;
        height: 2px;
      }
    }
  }
}
.pager {
  text-align: center;
  @extend .en-font;
  a {
    display: block;
    background: #fff;
    color: $blue;
    transition: all 300ms;
    height: 31px;
    line-height: 31px;
    @include mq(sp) {
      height: 28px;
      line-height: 28px;
    }
    &:hover {
      background: $blue;
      color: #fff;
      text-decoration: none;
    }
  }
  &__item {
    display: inline-block;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
    font-size: 20px;
    border: 2px solid $blue;
    vertical-align: middle;
    margin-left: 5px;
    font-weight: bold;
    position: relative;
    line-height: 1;
    @include mq(sp) {
      font-size: 12px;
      width: 30px;
      height: 30px;
      border: 1px solid $blue;
    }
    &--current {
      a {
        background: $blue;
        color: #fff;
      }
    }
    &--prev {
      a {
        background: #ebebff;
      }
      &:before {
        position: absolute;
        top: 10px;
        left: 13px;
        width: 9px;
        height: 9px;
        border-top: 2px solid $blue;
        border-left: 2px solid $blue;
        transform: rotate(-45deg);
        content: "";
        @include mq(sp) {
          width: 6px;
          height: 6px;
        }
      }
      &:after {
        position: absolute;
        bottom: -14px;
        width: 100%;
        left: 0;
        text-align: center;
        content: "PREV";
        font-size: 10px;
        letter-spacing: 0.1em;
        color: $blue;
        white-space: nowrap;
      }
    }
    &--next {
      a {
        background: #ebebff;
      }
      &:before {
        position: absolute;
        top: 10px;
        right: 13px;
        width: 9px;
        height: 9px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
        transform: rotate(45deg);
        content: "";
        @include mq(sp) {
          width: 6px;
          height: 6px;
        }
      }
      &:after {
        position: absolute;
        bottom: -14px;
        width: 100%;
        left: 0;
        text-align: center;
        content: "NEXT";
        font-size: 10px;
        letter-spacing: 0.1em;
        color: $blue;
        white-space: nowrap;
      }
    }
  }
}
[data-bgb] {
  margin: 0 !important;
}
