/* ------------------------------------------------
  recruit
---------------------------------------------- */
[data-contents="original"] {
  .recruit-wrap {
    padding: 100px 0;
    @include mq(sp) {
      padding: 40px 0;
    }
  }
  .recruit-bnr {
    position: relative;
    width: 1080px;
    box-sizing: border-box;
    border: 2px solid $blue;
    @include mq(sp) {
      width: 100%;
    }
    &__txt {
      display: table;
      height: 317px;
      box-sizing: border-box;
      @include mq(sp) {
        width: 100% !important;
        height: 80px;
        float: none !important;
        text-align: center;
        border: none !important;
        border-bottom: 2px solid $blue !important;;
      }
      &-inner {
        display: table-cell;
        vertical-align: middle;
      }
      &-sub {
        font-size: 36px;
        font-weight: bold;
        display: block;
        line-height: 1;
        margin-bottom: 10px;
        letter-spacing: 0.06em;
        @include mq(sp) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      &-year {
        font-size: 48px;
        font-weight: bold;
        line-height: 1;
        display: block;
        margin-bottom: 10px;
        letter-spacing: 0.06em;
        @include mq(sp) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
    &__img {
      @include mq(sp) {
        float: none !important;
      }
    }
    &__ttl {
      line-height: 1.2;
      letter-spacing: 0.12em; 
      text-align: left;
      padding: 0;
      margin: 0;
      @include mq(sp) {
        font-size: 18px !important;
        text-align: center;
        font-weight: bold !important;
      }
      &:after {
        display: none;
      }
    }
    &__link {
      @include clearfix;
      display: block;
      opacity: 1;
      transition: opacity 300ms;
      &:hover {
        opacity: 0.7;
      }
    }
    &--new {
      margin-bottom: 60px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      .recruit-bnr__img {
        float: right;
      }
      .recruit-bnr__txt {
        float: right;
        background: $blue;
        color: #fff;
        padding-left: 33px;
        width: 271px;
        @include mq(sp) {
          padding-left: 0;
        }
      }
      .recruit-bnr__ttl {
        font-size: 32px;
        font-weight: bold;
        overflow: inherit !important;
      }
    }
    &--career {
      margin-top: 100px;
      @include mq(sp) {
        margin-top: 20px;
      }
      .recruit-bnr__img {
        float: left;
      }
      .recruit-bnr__txt {
        float: left;
        text-align: center;
        width: 268px;
        border-right: 2px solid $blue;
        background: #fff;
        box-sizing: border-box;
        border-left: none;
      }
      .recruit-bnr__ttl {
        font-size: 32px;
        color: $blue;
        font-weight: bold;
        text-align: center;
        overflow: inherit !important;
      }
    }
  }
  .recruit-bnr-list {
    text-align: center;
    @include clearfix;
    margin: 0;
    @include mq(sp) {
      padding: 0 5px;
    }
    li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
      @include mq(sp) {
        position: relative;
        margin: 0;
        float: left;
        width: 50%;
        display: block;
        &:nth-child(odd) {
          left: -5px;
        }
        &:nth-child(even) {
          left: 5px;
        }
      }
    }
    &__link {
      display: block;
      @include hover-opacity;
    }
    &__ttl {
      margin: 0 0 10px;
      text-align: center;
      font-size: 20px;
      color: $blue;
      box-shadow: none;
      border: none;
      background: transparent;
      font-weight: bold;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 16px;
      }
    }
    &--mb {
      margin-bottom: 100px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
    }
  }
  .recruit-nav {
    &__list {
      margin-left: -80px;
      @include clearfix;
      @include mq(sp) {
        margin-left: 0;
      }
    }
    &__item {
      float: left;
      margin-left: 80px;
      margin-bottom: 80px;
      width: 500px;
      list-style: none;
      @include mq(sp) {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-bottom: 40px;
      }
      &:nth-child(odd) {
        clear: both;
      }
    }
    &__link {
      display: block;
      &:hover {
        text-decoration: none;
        img {
          transform: scale(1.1);
        }
      }
    }
    &__img {
      overflow: hidden;
      img {
        transition: transform 300ms;
      }
      @include mq(sp) {
        position: relative;
        height: 200px;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-40%);
        }
      }
    }
    &__ttl {
      padding: 27px 0;
      line-height: 1;
      margin: 0 0 20px;
      font-size: 23px;
      color: #fff;
      font-weight: bold;
      letter-spacing: 0.28em;
      background: $blue;
      @include mq(sp) {
        margin-bottom: 10px;
        font-size: 18px;
        padding: 15px 0;
      }
      &:after {
        display: none;
      }
    }
    &__txt {
      margin: 0;
      font-size: 20px;
      color: $blue;
      line-height: 1.6;
      @include mq(sp) {
        font-size: 13px;
      }
    }
  }
  .recruit-message {
    position: relative;
    @include clearfix;
    margin-bottom: 70px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
    .page-block__line-ttl {
      letter-spacing: 0;
    }
    &__txt {
      position: relative;
      width: 543px;
      font-size: 20px;
      line-height: 2;
      z-index: 2;
      font-weight: normal;
      letter-spacing: 0.08em;
      @include mq(sp) {
        width: 100%;
        font-size: 13px;
        float: none !important;
        margin-bottom: 10px;
      }
    }
    &__img {
      position: relative;
      @include mq(sp) {
        float: none !important;
      }
    }
    &__name-wrap {
      position: absolute;
      bottom: 18px;
      right: -23px;
      width: 236px;
      height: 100px;
      box-sizing: border-box;
      background: #002870;
      color: #fff;
      box-sizing: border-box;
      padding: 10px 20px 10px 30px;
      box-shadow: 0 0 10px rgba(248,248,249,.75);
      @include mq(sp) {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        text-align: center;
        box-shadow: none;
      }
      &:before {
        position: absolute;
        top: 0;
        left: -14px;
        border-right: 14px solid #002870;
        border-bottom: 14px solid transparent;
        content: "";
        @include mq(sp) {
          display: none;
        }
      }

    }
    &__name {
      font-size: 16px;
      line-height: 1.2;
      @include mq(sp) {
        font-size: 10px;
      }
      span {
        margin-top: 10px;
        font-size: 27px;
        display: block;
        line-height: 1;
        @include mq(sp) {
          font-size: 15px;
        }
      }
    }
    &--01 {
      .recruit-message__txt {
        float: right;
      }
      .recruit-message__img {
        float: left;
      }
    }
    &--02 {
      .recruit-message__txt {
        float: left;
      }
      .recruit-message__img {
        position: absolute;
        top: 110px;
        right: 0;
        z-index: 1;
        @include mq(sp) {
          position: relative;
          top: 0;
        }
      }
    }
    &--03 {
      margin-bottom: 100px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
      .recruit-message__txt {
        width: auto;
      }
      .recruit-message__img {
        float: left;
        margin: 0 20px 10px 0;
      }
    }
  }
  .recruit-talk {
    padding-bottom: 100px;
    @include mq(sp) {
      padding-bottom: 40px;
    }
    &__mv {
      margin-bottom: 40px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
    }
    &__top-txt {
      font-size: 21px;
      text-align: center;
      color: $blue;
      letter-spacing: 0.08em;
      margin-bottom: 80px;
      @include mq(sp) {
        margin-bottom: 20px;
        font-size: 12px;
      }
    }
    &__theme {
      margin-bottom: 10px;
      @include mq(sp) {
        width: 110px;
        margin: 0 auto 5px;
      }
    }
    &__ttl {
      margin: 0 0 50px;
      font-weight: bold;
      text-align: center;
      font-size: 32px;
      letter-spacing: 0.12em;
      color: $blue;
      &:after {
        display: none;
      }
      @include mq(sp) {
        margin: 0 0 20px;
        font-size: 16px;
      }
    }
    &__block {
      @include clearfix;
      @include mq(sp) {
        margin: 0 0 20px !important;
      }
      &-txt {
        font-size: 20px;
        line-height: 2;
        font-weight: normal;
        @include mq(sp) {
          font-size: 13px;
        }
        &--right {
          text-align: right;
        }
        &--center {
          padding-top: 40px;
          @include mq(sp) {
            padding-top: 20px;
          }
        }
      }
      &-name {
        display: block;
        font-size: 18px;
        font-weight: bold;
        margin-top: 5px;
        @include mq(sp) {
          font-size: 10px;
          margin-top: 0;
        }
      }
      &-img {
        @include mq(sp) {
          width: 85px;
        }
      }
      &--01 {
        margin: 0 60px 30px 140px;
        .recruit-talk__block-img {
          float: right;
          @include mq(sp) {
            margin: 0 0 0 10px;
          }
        }
        .recruit-talk__block-txt {
          margin: 0 200px 0 0;
          @include mq(sp) {
            margin: 0;
          }
        }
        .recruit-talk__block-name {
          margin-left: 50px;
          color: $blue;
          @include mq(sp) {
            margin-left: 35px;
          }
        }
      }
      &--02 {
        margin: 0 140px 30px 60px;
        .recruit-talk__block-img {
          float: left;
          @include mq(sp) {
            margin: 0 10px 0 0;
          }
        }
        .recruit-talk__block-txt {
          margin: 0 0 0 200px;
          @include mq(sp) {
            margin: 0;
          }
        }
        .recruit-talk__block-name {
          margin-left: 25px;
          color: #F91E46;
          @include mq(sp) {
            margin-left: 15px;
          }
        }
      }
      &--03 {
        margin: 0 60px 30px 140px;
        .recruit-talk__block-img {
          float: right;
          @include mq(sp) {
            margin: 0 0 0 10px;
          }
        }
        .recruit-talk__block-txt {
          margin: 0 200px 0 0;
          @include mq(sp) {
            margin: 0;
          }
        }
        .recruit-talk__block-name {
          margin-left: 50px;
          color: #F91E46;
          @include mq(sp) {
            margin-left: 35px;
          }
        }
      }
      &--04 {
        margin: 0 140px 30px 60px;
        .recruit-talk__block-img {
          float: left;
          @include mq(sp) {
            margin: 0 10px 0 0;
          }
        }
        .recruit-talk__block-txt {
          margin: 0 0 0 200px;
          @include mq(sp) {
            margin: 0;
          }
        }
        .recruit-talk__block-name {
          margin-left: 25px;
          color: $blue;
          @include mq(sp) {
            margin-left: 15px;
          }
        }
      }
      +.recruit-talk__theme {
        margin-top: 100px;
        @include mq(sp) {
          margin-top: 40px;
        }
      }
    }
    &__img {
      margin: 100px 0;
      @include mq(sp) {
        margin: 20px 0 40px;
      }
    }
    &-fig-block {
      background: #e5ebf3;
      margin: 50px 0;
      padding: 25px 0;
      @include mq(sp) {
        margin: 20px 0;
      }
      .container {
        display: flex;
        flex-direction: row-reverse;
        @include mq(sp) {
          display: block;
        }          
      }
      &__txt-wrap {
        position: relative;
        width: 720px;
        vertical-align: middle;
        @include mq(sp) {
          width: 100%;
          text-align: center;
        }
        &-inner {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          padding: 0 0 0 100px;
          box-sizing: border-box;
          transform: translate(-50%,-50%);
          @include mq(sp) {
            position: relative;
            padding: 0;
            top: 0;
            left: 0;
            transform: translate(0)
          }
        }
      }
      &__ttl {
        background: $blue;
        color: #fff;
        display: inline-block;
        padding: 10px 30px;
        line-height: 1;
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 25px;
        border: none;
        box-shadow: none;
        @include mq(sp) {
          font-size: 12px;
          margin: 0 0 10px;
        }
      }
      &__txt {
        font-size: 21px;
        letter-spacing: 0.1em;
        font-weight: normal;
        margin: 0;
        @include mq(sp) {
          font-size: 13px;
          margin: 0 0 20px;
        }
      }
      &__fig {
        @include mq(sp) {
          width: 178px;
          margin: 0 auto;
        }
      }
    }
  }
  _:-ms-lang(x), .recruit-talk-fig-block__ttl {
    padding: 16px 30px 10px;
  }
  .recruit-internship {
    background: #fff;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 30px 65px 1px; 
    border: 1px solid #999;
    @include mq(sp) {
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 15px 15px 1px;
    }
    &__list {
      @include clearfix;
      margin-left: -6px;
      @include mq(sp) {
        margin-left: 0;
        padding: 0 4px;
      }
      li {
        list-style: none;
        float: left;
        margin-left: 6px;
        width: 232px;
        @include mq(sp) {
          position: relative;
          width: 50%;
          margin-left: 0;
          margin-bottom: 4px;
          &:nth-child(odd) {
            left: -2px;
          }
          &:nth-child(even) {
            left: 2px;
          }
        }
      }
    }
  }
  .recruit-table {
    margin-bottom: 50px;
    th {
      width: 150px;
      background: #f0f0f0;
      padding: 30px 30px;
      box-sizing: border-box;
      font-size: 16px;
      vertical-align: middle;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 10px 10px;
        text-align: center;
        font-size: 13px;
      }
    }
    td {
      padding: 30px 30px;
      font-size: 16px;
      background: #fff !important;
      line-height: 2;
      @include mq(sp) {
        display: block;
        padding: 10px;
        font-size: 13px;
      }
      strong {
        font-size: 20px;
        @include mq(sp) {
          font-size: 16px;
        }
      }
    }
  }
}