/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  -webkit-text-size-adjust: 100%;
  color: $color;
  background: $bg;
  font-size: 14px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK JP Subset",'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  word-break: break-all;
  @include mq(sp) {
    font-size: 13px;
  }
}
a {
  color: $aColor;
  @include txtLine(hLine);
  @include mq(sp) {
    color: black;
  }
}
strong {
  font-weight: bold;
}
/*font*/
@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans CJK JP'),
       local('NotoSansCJKjp-Regular'),
       local('NotoSansJP-Regular'),
       url('../font/NotoSansCJKjp-Regular.woff2') format('woff2'),
       url('../font/NotoSansCJKjp-Regular.woff') format('woff'),
       url('../font/NotoSansCJKjp-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans CJK JP'),
       local('NotoSansCJKjp-Bold'),
       local('NotoSansJP-Bold'),
       url('../font/NotoSansCJKjp-Bold.woff2') format('woff2'),
       url('../font/NotoSansCJKjp-Bold.woff') format('woff'),
       url('../font/NotoSansCJKjp-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'gidoleregular';
    src: url('../font/gidole-regular.woff2') format('woff2'),
         url('../font/gidole-regular.woff') format('woff'),
         url('../font/gidole-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

} 
.en-font {
  font-family: 'gidoleregular';
}


/* wrapper
-------------------------*/
.wrapper {
  position: relative;
  @include mq(sp) {
    padding-top: 50px;
  }
  img {
    display: block;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
  
}
.top-search {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  &__input {
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 14px;
    padding-left: 40px;
    box-sizing: border-box;
    width: 176px;
    background: #fff;
    height: 29px;
    line-height: 29px;
    border: 1px solid #464646;
    border-radius: 20px;
    z-index: 10;
    @include input-placeholder {
      color: rgba(25,59,136,0.3);
      font-weight: bold;
    }
  }
  &__btn {
    position: absolute;
    top: 25px;
    right: 147px;
    width: 18px;
    height: 19px;
    background: url(../img/common/ico_search_01.png) no-repeat left top;
    background-size: 18px auto;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    z-index: 10;
    overflow: hidden;
  }
}
/* header
-------------------------*/
.header {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 90;
  background: transparent;
  height: 75px;
  transition: opacity 300ms;
  @include mq(sp) {
    position: fixed;
    top: 0;
    height: auto;
    .container {
      padding: 0;
    }
  }
  &.none {
    opacity: 0;
    @include mq(sp) {
      opacity: 1;
    }
    &.fix {
      opacity: 1;
    }
  }
  &.fix {
    position: fixed;
    top: 0;
  }
  
  &__logo {
    float: left;
    margin: 15px 0 0 20px;
    @include mq(sp) {
      width: 200px;
      float: none;
      display: block;
      margin: 0;
      padding: 10px 0 0 15px;
    }
  }
  &__bar {
    position: relative;
    height: 75px;
    padding-right: 150px;
    @include clearfix;
    background: #fff;
    box-shadow: 0 3.45px 1.72px rgba(35,24,21,0.6);
    @include mq(sp) {
      height: 50px;
      padding-right: 0;
    }
  }
  &__contact-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 75px;
    background: $blue url(../img/common/btn_contact_header.png) no-repeat center center;
    background-size: 98px auto;
    @include txtoff;
    @include hover-opacity;
    @include mq(sp) {
      position: relative;
      width: 100%;
      height: auto;
      background: none;
      color: #fff;
      display: block;
      color: #fff;
      padding: 10px 15px;
      text-indent: 0;
      box-sizing: border-box;
      &:after {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 10px;
        height: 10px;
        transform: translateY(-50%) rotate(45deg) ;
        border-top: 2px solid rgba(#fff,.8);
        border-right: 2px solid rgba(#fff,.8);
        content: "";
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__search {
    font-size: 14px;
    padding-left: 40px;
    box-sizing: border-box;
    width: 176px;
    background: #fff;
    height: 29px;
    line-height: 29px;
    border: 1px solid #464646;
    border-radius: 20px;
    @include input-placeholder {
      color: rgba(25,59,136,0.3);
      font-weight: bold;
    }
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      border-radius: 0;
      font-size: 16px;
      border: 1px solid #fff;
      padding-left: 10px;
      -webkit-appearance: none;
      padding: 0 10px;
    }
    &-wrap {
      position: absolute;
      top: -50px;
      right: 0;
      @include mq(sp) {
        position: relative;
        top: 0;
        width: 100%;
        padding: 9px 75px 9px 15px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(#fff,.4);
      }
    }
    &-btn {
      position: absolute;
      top: 5px;
      right: 147px;
      width: 18px;
      height: 19px;
      background: url(../img/common/ico_search_01.png) no-repeat left top;
      background-size: 18px auto;
      border: none;
      cursor: pointer;
      text-indent: -9999px;
      overflow: hidden;
      @include mq(sp) {
        -webkit-appearance: none;
        border-radius: 0;
        top: 9px;
        right: 15px;
        width: 50px;
        height: 29px;
        background: #fff;
        text-indent: 0;
        font-weight: bold;
        color: $blue;
        letter-spacing: 0.1em;
        font-size: 12px;
      }
    }
  }
  &__menu-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding-top: 30px;
    box-sizing: border-box;
    background: $blue;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #fff;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
    &:before {
      position: absolute;
      top: 10px;
      left: 13px;
      box-sizing: border-box;
      width: 24px;
      height: 18px;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;
      content: "";
      transition: all 300ms;
    }
    &:after {
      position: absolute;
      top: 18px;
      left: 13px;
      content: "";
      width: 24px;
      height: 2px;
      background: #fff;
      transition: all 300ms;
    }
    &.open {
      &:before {
        transform: rotate(45deg);
        border-bottom: none;
        top: 17px;
        left: 7px;
      }
      &:after {
        transform: rotate(-45deg);
        top: 19px;
      }
    }
  }
}
.gnav {
  float: right;
  @include mq(sp) {
    float: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background: rgba($blue,.8);
    box-sizing: border-box;
    transition: height 200ms;
    &.open {
      height: 288px;
    }
  }
  &__list {
    @include clearfix;
    margin-right: 20px;
    @include mq(sp) {
      margin-right: 0;
    }
  }
  &__item {
    float: left;
    margin-right: 30px;
    line-height: 75px;
    @include mq(sp) {
      float: none;
      margin-right: 0;
      line-height: 2;
      border-bottom: 1px solid rgba(#fff,.4);
    }
    a{
      position: relative;
      color: $blue;
      transition: all 300ms;
      text-decoration: none;
      font-weight: bold;
      font-size: 15px;
      @include mq(sp) {
        display: block;
        font-size: 14px;
        padding: 10px 15px;
        color: #fff;
        box-sizing: border-box;
        &:after {
          position: absolute;
          top: 50%;
          right: 15px;
          width: 10px;
          height: 10px;
          transform: translateY(-50%) rotate(45deg) ;
          border-top: 2px solid rgba(#fff,.8);
          border-right: 2px solid rgba(#fff,.8);
          content: "";
        }
      }
      &:hover {
        color: $blue;
        @include mq(sp) {
          color: #fff;
        }
        &:before {
          width: 100%;
        }
      }
      &:before {
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 0;
        height: 2px;
        background: $blue;
        content: "";
        transition: all 300ms;
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
}
_:-ms-lang(x), .gNav {
  margin-top: 6px;
}
/* breadcrumb
-------------------------*/
.breadcrumb {
  padding: 10px 0;
  color: $blue;
  @include mq(sp) {
    font-size: 10px;
  }
  a {
    color: $blue;  
    @include mq(sp) {
      font-weight: bold;
    }
  }
  &__item {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    line-height: 1;
    vertical-align: middle;
    @include mq(sp) {
      padding: 0 10px 0 0;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: -8px;
      width: 8px;
      height: 8px;
      transform: rotate(45deg) ;
      border-top: 1px solid $blue;
      border-right: 1px solid $blue;
      content: "";
      margin-top: -5px;
      
      @include mq(sp) {
        width: 4px;
        height: 4px;
      }
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
}
_:-ms-lang(x), .breadcrumb__item:before {
  margin-top: -7px;
} 
/* contents
-------------------------*/
.contents {
  background: #fff;
  &--bg {
    background: #f5f5f5;
  }
}

/* footer
-------------------------*/
.footer {
  background: $blue;
  padding: 30px 0 0;
  color: #fff;
  
  a {
    color: #fff;
    &:hover {
      img {
        opacity: .7;
      }
    }
  }
  .container {
    @include clearfix;
  }
  &__container {
    width: 20%;
    float: left;
    margin-bottom: 40px;
    @include mq(sp) {
      width: 50%;
      margin-bottom: 20px;
      &:nth-child(odd) {
        clear: both;
      }
    }
  }
  &__link {
    margin-bottom: 18px;
    font-size: 21px;
    font-weight: bold;
    @include mq(sp) {
      font-size: 13px;
      margin-bottom: 5px;
      text-decoration: underline;
    }
  }
  &__link-list-item {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 16px;
    @include mq(sp) {
      font-size: 12px;
      line-height: 1.7;
      a {
        text-decoration: underline;
      }
    }
    img {
      opacity: 1;
      margin: 0 auto 0 0;
      transition: opacity 300ms;
    }
    &--bnr {
      margin-top: 25px;
      @include mq(sp) {
        margin-top: 10px;
      }
      +.footer__link-list-item--bnr {
        margin-top: 10px;
      }
    }
  }
  &__link-sub-list {
    margin-top: 10px;
    margin-bottom: 1em;
    margin-left: 1em;
    li {
      margin-left: 1em;
      margin-bottom: 10px;
      text-indent: -1em;
      line-height: 1.2;
    }
  }
  &__btm {
    padding: 20px 0 20px;
    clear: both;
    border-top: 1px solid #fff;
    @include mq(sp) {
      padding: 15px 0;
    }
    &-list {
      text-align: center;
      @include mq(sp) {
        text-align: left;
      }
      li {
        display: inline-block;
        line-height: 1;
        a {
          display: block;
          padding: 0 20px;
          @include mq(sp) {
            padding: 0 10px;
            font-size: 12px;
          }
        }
        &:nth-child(odd) {
          padding-left: 10px;
          border-left: 1px solid #fff;
          @include mq(sp) {
            padding-left: 0;
          }
        }
        &:nth-child(even) {
          @include mq(sp) {
            border-left: 1px solid #fff;
          }
          
        }
        &:first-child {
          border-left: none;
        }
      }
    }
  }
  &__copyright {
    padding: 30px 0;
    background: #fff;
    color: #000;
    @include mq(sp) {
      padding: 15px 0;
      font-size: 10px;
    }
    img {
      margin: 0 auto 0 0;
    }
  }
}