/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.top-slider {
  @include mq(sp) {
    margin-bottom: 20px;
  }
  .slick-dots {
    position: absolute;
    width: 100%;
    height: 36px;
    background: $blue;
    bottom: 0;
    left: 0;
    text-align: center;
    @include mq(sp) {
      height: auto;
      padding: 5px 0;
      background: transparent;
      bottom: -22px;
    }
    li {
      display: inline-block;
      margin-right: 12px;
    }
    button {
      display: block;
      width: 12px;
      height: 12px;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      border-radius: 50%;
      outline: none;
      background: #fff;
      z-index: 5;
      font-size: 0;
      line-height: 0;
      @include mq(sp) {
        width: 8px;
        height: 8px;
        border: 1px solid $blue;
      }
    }
    .slick-active {
      button {
        background: #b9f4ff;
        @include mq(sp) {
          background: $blue;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 25px;
    height: 67px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 0;
    line-height: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 25px auto;
    @include mq(sp) {
      display: none !important;
    }
  }
  .slick-prev {
    left: 47px;
    background-image: url(../img/top/btn_prev_01.png);
  }
  .slick-next {
    right: 47px;
    background-image: url(../img/top/btn_next_01.png);
  }
}
.topics-list {
  .slick-dots {
    text-align: center;
    li {
      display: inline-block;
      margin-right: 12px;
    }
    button {
      display: block;
      width: 60px;
      height: 8px;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: #f0f0f0;
      z-index: 5;
      font-size: 0;
      line-height: 0;
      @include mq(sp) {
        width: 30px;
      }
    }
    .slick-active {
      button {
        background: #b9f4ff;
      }
    }
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 92px;
    display: block;
    width: 16px;
    height: 32px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 5;
    font-size: 0;
    line-height: 0;
    background-color: transparent;
    @include mq(sp) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      content: "";
    }
  }
  .slick-prev {
    left: -28px;
    @include mq(sp) {
      left: -12px;
    }
    &:before {
      border-right: 16px solid rgba(255,255,255,.5);
    }
  }
  .slick-next {
    right: -28px;
    @include mq(sp) {
      right: -12px;
    }
    &:before {
      border-left: 16px solid rgba(255,255,255,.5);
    }
  }
}