/* ------------------------------------------------
  TOP
---------------------------------------------- */
.slider-wrap {
  z-index: 80;
}
.slider {
  width: 100%;
  min-width: 1080px;
  @include mq(sp) {
    min-width: inherit;
  }
  img {
    display: block;
    width: 100%;
  }
  &__item {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#000,.3);
      content: "";
      z-index: 1;
    }
    &--03 {
      .slider__item-txt {
        margin-bottom: 20px;
        @include mq(sp) {
          margin-bottom: 10px;
        }
      }
    }
    &-inner {
      position: absolute;
      width: 1040px;
      top: 50%;
      left: 50%;
      margin-top: 30px;
      z-index: 2;
      transform: translateX(-50%) translateY(-50%);
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 20px;
        top: 0;
        left: 0;
        margin-top: 0;
        transform: translateX(0);
      }
    }
    &-ttl {
      color: #fff;
      font-size: 37px;
      line-height: 55px;
      letter-spacing: 0.1em;
      margin-bottom: 40px;
      text-shadow: 0 0 5px #000;
      @include mq(sp) {
        margin-top: 4px;
        margin-bottom: 10px;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: 1.6;
      }
    }
    &-txt {
      margin-bottom: 60px;
      color: #fff;
      font-size: 19px;
      line-height: 39px;
      letter-spacing: 0.06em;
      text-shadow: 0 0 5px #000;
      @include mq(sp) {
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 1.6;
      }
    }
    &-btn {
      display: block;
      width: 253px;
      @include hover-opacity;
      @include mq(sp) {
        width: 120px;
      }
    }
  }
}


.top-section {
  
  &__ttl {
    position: relative;
    margin: 0 auto;
    padding-bottom: 23px;
    @include mq(sp) {
      padding-bottom: 10px;
    }
    &-sub {
      display: block;
      margin-bottom: 70px;
      text-align: center;
      color: $blue;
      font-size: 18px;
      letter-spacing: 0.22em;
      font-weight: bold;
      @include mq(sp) {
        margin-bottom: 20px;
        font-size: 12px;
      }
    }
    &:before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 150px;
      height: 2px;
      background: $blue;
      transform: translateX(-50%);
      content: "";
      @include mq(sp) {
        width: 100px;
      }
    }
  }
  
  &--works {
    padding: 110px 0 100px;
    @include mq(sp) {
      padding: 40px 0;
    }
    .top-section__ttl {
      width: 236px;
      @include mq(sp) {
        width: 118px;
      }
    }
  }
  &--topics {
    padding: 50px 0 40px;
    background: rgba(0,0,0,.6);
    @include mq(sp) {
      padding: 40px 0;
    }
    .top-section__ttl {
      width: 234px;
      margin: 0 auto 60px 0;
      padding-left: 86px;
      @include mq(sp) {
        width: 117px;
        padding-left: 40px;
        margin: 0 auto 20px 0;
      }
      &:before {
        width: 320px;
        background: #fff;
        @include mq(sp) {
          width: 180px;
        }
      }
    }
  }
  &--news {
    padding: 110px 0 100px;
    @include mq(sp) {
      padding: 40px 0;
    }
    .top-section__ttl {
      width: 185px;
      @include mq(sp) {
        width: 92px;
      }
    }
  }
}
.works-list {
  &__list {
    @include clearfix;
    margin-bottom: 50px;
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
  &__item {
    float: left;
    width: 360px; 
    margin-bottom: 50px;
    @include mq(sp) {
      width: 100%;
      float: none;
      background: #f0f0f0;
      margin-bottom: 10px;
    }
    &:first-child {
      .works-list__txt-wrap {
        &:before {
          display: none;
        }
      }
    }
  }
  &__link {
    @include clearfix;
    @include mq(sp) {
      display: block;
      position: relative;
      padding-right: 10px;
      &:before {
        position: absolute;
        right: 15px;
        top: 50%;
        width: 8px;
        height: 8px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
        transform: rotate(45deg) translateY(-50%);
        content: "";
        z-index: 3;
      }
    }
    img {
      transition: transform 300ms;
    }
    &:hover {
      text-decoration: none;
      img {
        transform: scale(1.1);
      }
      .company-links__ttl {
        text-decoration: underline;
      }
    }
  }
  &__img-wrap {
    position: relative;
    z-index: 2;
    overflow: hidden;
    @include mq(sp) {
      float: left;
      width: 120px;
      margin: 10px 0 0 10px;
    }
  }
  &__txt-wrap {
    position: relative;
    background: #f0f0f0;
    padding: 30px 30px 0;
    min-height: 195px;
    box-sizing: border-box;
    @include mq(sp) {
      padding: 10px 10px 10px 0;
      margin-left: 140px;
      min-height: inherit;
      min-height: 110px;
    }
    &:before {
      position: absolute;
      top: 10px;
      left: 0px;
      width: 3px;
      height: 175px;
      background: url(../img/company/line_01.png) no-repeat left top;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__ttl {
    position: relative;
    display: inline-block;
    font-size: 24px;
    line-height: 1.4;
    color: $blue;
    margin-bottom: 10px;
    &--ls {
      margin-bottom: 5px;
      +.company-links__txt {
        line-height: 1.6;
      }
    }
    @include mq(sp) {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
    &:before {
      position: absolute;
      right: -30px;
      top: 50%;
      width: 8px;
      height: 8px;
      border-top: 2px solid $blue;
      border-right: 2px solid $blue;
      transform: rotate(45deg) translateY(-50%);
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__txt {
    margin: 0;
    font-size: 15px;
    @include mq(sp) {
      font-size: 12px;
      line-height: 1.4;
    }
  }
}
_:-ms-lang(x), .works-list__ttl:before {
  margin-top: -7px;
}
.topics-list {
  width: 1020px;
  @include mq(sp) {
    width: auto;
  }
  .wrap {
    color: #fff;
    @include clearfix;
    img {
      opacity: 1;
      transition: transform 300ms;
      @include mq(sp) {
        float: left;
        width: 100px
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
        transform-origin: center center;
      }
      .topics-list__ttl {
        text-decoration: underline;
      }
    }
  }
  &__img-wrap {
    overflow: hidden;
  }
  &__item {
    margin-bottom: 30px;
    @include mq(sp) {
      margin-bottom: 10px;
    }
  }
  &__ttl {
    margin: 10px 0;
    padding-left: 24px;
    font-size: 22px;
    font-weight: bold;
    background-image: url(../img/common/ico_link_01.png);
    background-repeat:  no-repeat;
    background-position: left 4px;
    background-size: 16px auto;
    line-height: 1.1;
    height: 2.2em;
    @include mq(sp) {
      margin: 10px 0 5px 110px;
      padding-left: 14px;
      font-size: 14px;
      background-size: 12px auto;
      background-position: left 2px;
    }
  }
  &__txt {
    padding: 0 10px;
    line-height: 19.5px;
    @include mq(sp) {
      margin-left: 110px;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
  }
}
_:-ms-lang(x), .topics-list__ttl {
  padding-top: 5px;
}
.news-tab {
  margin-top: -40px;
  margin-bottom: 50px;
  text-align: center;
  @include clearfix;
  @include mq(sp) {
    margin-top: 0;
    width: 100%;
    margin-bottom: 0;
  }
  &__item {
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    border-left: 1px solid #000;
    @include mq(sp) {
      font-size: 12px;
      width: 20%;
      box-sizing: border-box;
      font-size: 10px;
      text-align: center;
      vertical-align: middle;
      border-top: 1px solid $blue;
      border-left: 1px solid $blue;
      border-bottom: 1px solid $blue;
      display: block;
      float: left;
      border-radius: 6px 6px 0 0;
    }
    &:first-child {
      border-left: none;
      @include mq(sp) {
        border-left: 1px solid $blue;
      }
    }
    &:last-child {
      @include mq(sp) {
        border-right: 1px solid $blue;
      }
    }
    a {
      position: relative;
      display: block;
      padding: 0 30px 10px;
      transition: color 300ms;
      @include mq(sp) {
        text-align: center;
        background: #fff;
        height: 36px;
        border-radius: 6px 6px 0 0;
        padding: 0;
      }
      &:before {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100px;
        height: 2px;
        transform: translateX(-50%);
        background: $blue;
        content: "";
        transition: opacity 300ms;
        opacity: 0;
        @include mq(sp) {
          width: 100%;
          left: 0;
          bottom: -2px;
          transform: translateX(0);
          height: 3px;
          background: #f5f5f5;
        }
      }
      &:hover {
        color: $blue;
        text-decoration: none;
        @include mq(sp) {
          color: #000;
        }
        &:before {
          opacity: 1;
          @include mq(sp) {
            opacity: 0;
          }
        }
      }
      &.select {
        color: $blue;
        @include mq(sp) {
          background: #f5f5f5;
        }
        &:before {
          opacity: 1;
        }
      }
    }
    &-inner {
      @include mq(sp) {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}
.news-list {
  margin-bottom: 40px;
  padding: 40px 30px;
  background: #f5f5f5;
  border-left: 3px solid $blue;
  @include mq(sp) {
    position: relative;
    border: none;
    padding: 15px 15px 5px;
    margin-bottom: 0;
    &:before {
      position: absolute;
      bottom: -60px;
      left: 0;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      content: "";
      z-index: 1;
    }
  }
  &__item {
    margin-bottom: 10px;
    line-height: 1.6;
    @include mq(sp) {
      border-bottom: 1px solid rgba($blue,.3);
    }
    a {
      display: block;
    }
  }
  &__date {
    float: left;
    width: 110px;
    font-size: 16px;
    @include mq(sp) {
      font-size: 10px;
      width: 60px;
      margin-bottom: 5px;
    }
  }
  &__category {
    float: left;
    width: 133px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    @include mq(sp) {
      margin-bottom: 5px;
      margin-left: 10px;
      font-size: 10px;
      width: 110px;
    }
    &--recruit {
      background: #e155af;
    }
    &--topics {
      background: $blue;
    }
    &--message {
      background: #58a200;
    }
    &--important {
      background: #f00;
    }
    &--other {
      background: #b99600;
    }
    &--company {
      background: #005bb2;
    }
    &--group {
      background: $blue;
    }
  }
  &__ttl {
    font-size: 17px;
    margin-left: 265px;
    @include mq(sp) {
      font-size: 13px;
      margin-left: 0;
      clear: both;
    }
  }
}
_:-ms-lang(x), .news-list__category {
  padding: 6px 0 0;
  line-height: 1;
}
.more-btn {
  display: none;
  width: 260px;
  height: 53px;
  margin: 0 auto;
  line-height: 53px;
  color: $blue;
  border: 2px solid $blue;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.2em;
  transition: all 300ms;
  background: #fff;
  @include mq(sp) {
    position: relative;
    z-index: 2;
    width: 140px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }
  &:hover {
    background: $blue;
    color: #fff;
    text-decoration: none;
  }
  &.show {
    display: block;
  }
  &--all {
    display: block;
  }
}
_:-ms-lang(x), .more-btn {
  line-height: 57px;
}