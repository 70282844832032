/* ------------------------------------------------
  business
---------------------------------------------- */
[data-contents="original"] {
  .business-top {
    position: relative;
    padding: 0 0 40px 50%;
    background: $blue;
    z-index: 1;
    overflow: hidden;
    @include mq(sp) {
      padding: 15px 0;
    }
    &--01 {
      margin-bottom: 100px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      &:before {
      background-image: url(../img/business/bg_top_01.png);
      }
    }
    &--02 {
      margin-bottom: 70px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      &:before {
        background-image: url(../img/business/bg_top_02.png);
      }
    }
    &--03 {
      margin-bottom: 70px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      &:before {
        background-image: url(../img/challenge/bg_top_01.jpg);
      }
    }
    &-wrap {
      padding-top: 100px;
      @include mq(sp) {
        padding-top: 40px;
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-repeat:  no-repeat;
      background-position: left top;
      background-size: cover;
      content: "";
      z-index: 2;
      @include mq(sp) {
        top: 100%;
        width: 100%;
        height: 30px;
      }
    }
    &__ttl {
      font-weight: bold;
      color: #fff;
      padding: 40px 0 0 40px;
      margin: 0 0 10px 0;
      text-align: left;
      font-size: 40px;
      letter-spacing: 0.22em;
      @include mq(sp) {
        font-size: 18px;
        padding: 0;
        letter-spacing: 1;
      }
      &:after {
        display: none;
      }
    }
    &__txt {
      color: #fff;
      padding-left: 40px;
      font-size: 18px;
      letter-spacing: 0.14em;
      margin: 0;
      @include mq(sp) {
        padding-left: 0;
        font-size: 13px;
      }
    }
    &__catch {
      display: inline-block;
      position: relative;
      color: #fff;
      padding-left: 40px;
      font-size: 26px;
      letter-spacing: 0.14em;
      margin: 0;
      &:after {
        position: absolute;
        top: 50%;
        right: -60px;
        width: 50px;
        height: 1px;
        background: #fff;
        content: "";
      }
      @include mq(sp) {
        padding-left: 0;
        font-size: 13px;
      }
    }
  }
  .business-nayami {
    width: 1027px;
    margin: 0 auto 70px;
    padding: 35px 0 15px;
    border: 1px solid $blue;
    background: #f0f0f0;
    box-sizing: border-box;
    text-align: center;
    @include mq(sp) {
      width: 100%;
      margin: 0 0 40px;
      box-sizing: border-box;
      padding: 10px 0;
    }
    &__list {
      text-align: center;
      margin: 0;
      @include mq(sp) {
      }
    }
    &__item {
      display: inline-block;
      list-style: none;
      margin: 0;
      width: 180px;
      height: 180px;
      background: #0064aa;
      text-align: center;
      border-radius: 50%;
      margin:0 8px 20px;
      @include mq(sp) {
        width: 105px;
        height: 105px;
        margin: 0 5px 5px;
      }
    }
    &__inner {
      display: table;
      width: 180px;
      height: 180px;
      @include mq(sp) {
        width: 105px;
        height: 105px;
      }
    }
    &__txt {
      display: table-cell;
      vertical-align: middle;
      color: #c8dcdc;
      font-size: 16px;
      line-height: 1.2;
      margin: 0;
      @include mq(sp) {
        font-size: 10px;
      }
      strong {
        font-weight: bold;
        font-size: 21px;
        color: #f5f5f5;
        @include mq(sp) {
          font-size: 13px;
        }
        &.min {
          font-size: 16px;
          @include mq(sp) {
            font-size: 10px;
          }
        }
      }
    }
    &__ttl {
      font-size: 20px;
      text-align: center;
      color: $blue;
      margin: 0;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 15px;
      }
    }
  }
  .business-flow {
    @include clearfix;
    margin-bottom: 90px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
    &__item {
      position: relative;
      list-style: none;
      float: left;
      width: 320px;
      margin-left: 30px;
      overflow: inherit;
      &:first-child {
        margin-left: 0;
      }
      @include mq(sp) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 30px;
      }
      &:before {
        position: absolute;
        top: 80px;
        right: -22px;
        width: 14px;
        height: 14px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
        content: "";
        transform: rotate(45deg);
        @include mq(sp) {
          top: 100%;
          right: inherit;
          left: 50%;
          transform: rotate(135deg) ;
          margin-left: -14px;
        }
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
    &__ttl-wrap {
      display: table;
      width: 320px;
      margin-bottom: 10px;
      height: 180px;
      background: #0064aa;
      text-align: center;
      @include mq(sp) {
        width: 100%;
        height: auto;
        padding: 10px 0;
      }
    }
    &__ttl {
      border: none;
      padding: 0;
      color: #fff;
      display: table-cell;
      background: transparent;
      box-shadow: none;
      border: none;
      vertical-align: middle;
      font-size: 30px;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 15px;
      }
    }
    &__txt {
      font-size: 18px;
      letter-spacing: 0.1em;
      margin: 0;
      line-height: 1.6;
      @include mq(sp) {
        font-size: 13px;
      }
    }
    &--reverse {
      .business-flow__item {
        float: right;
        margin-right: 60px;
        margin-left: 0 !important;
        @include mq(sp) {
          margin-right: 0;
          float: none;
        }
      }
      .business-flow__item:first-child {
        margin-left: 0 !important;
        margin-right: 0;
      }
      .business-flow__item:before {
        right: inherit;
        left: -35px;
        transform: rotate(-135deg);
        @include mq(sp) {
          left: 50%;
          transform: rotate(135deg);
        }
      }
    }
  }
  .page-bg-box {
    .business-flow {
      margin-bottom: 0;
    }
    .business-flow__item {
      width: 280px;
      margin-left: 60px;
      @include mq(sp) {
        margin-left: 0;
        width: 100%;
      }
      &:first-child {
        margin-left: 0;
      }
      &:before {
        top: 70px;
        right: -35px;
        @include mq(sp) {
          top: 100%;
          right: inherit;
        }
      }
      &:last-child {
        @include mq(sp) {
          margin-bottom: 10px;
        }
      }
    }
    .business-flow__ttl-wrap {
      width: 280px;
      height: 150px;
      @include mq(sp) {
        width: 100%;
        height: auto;
      }
    }
  }
  .business-fig {
    float: left;
    width: 50%;
    text-align: center;
    &__ttl {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.06em;
      margin: 0 0 10px;
      @include mq(sp) {
        font-size: 14px;
      }
    }
  }
  .business-check-box {
    border: 1px solid $blue;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    @include mq(sp) {
      padding: 10px;
    }
    &__ttl {
      position: relative;
      font-size: 21px;
      font-weight: bold;
      color: $blue;
      margin: 0 0 10px;
      padding-left: 20px;
      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        content: "";
        border-left: 10px solid $blue;
        @include mq(sp) {
          border-left: 5px solid $blue;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }
      @include mq(sp) {
        font-size: 15px;
        padding-left: 10px;
      }
    }
    &__list {
      margin: 0;
      >li {
        list-style: none;
        font-size: 22px;
        color: $blue;
        padding-left: 35px;
        background-image: url(../img/common/ico_check_01.png);
        background-repeat: no-repeat;
        background-position: left 4px;
        background-size: 21px auto;
        @include mq(sp) {
          font-size: 13px;
          background-size: 12px auto;
          padding-left: 18px;
          line-height: 1.6;
          background-position: left 2px;
        }
      }
    }
    &__sub-list {
      li {
        position: relative;
        padding-left: 20px;
        list-style: none;
        line-height: 1.4;
        &:before {
          position: absolute;
          left: 0;
          top: 10px;
          width: 10px;
          height: 10px;
          background: $blue;
          border-radius: 50%;
          content: "";
        }
      }
    }
  }
  _:-ms-lang(x), .business-check-box__ttl:before {
    margin-top: -6px;
  }
  .business-txt-list {
    @include clearfix;
    margin-bottom: 40px;
    li {
      position: relative;
      font-size: 22px;
      color: $blue;
      letter-spacing: 0.1em;
      padding-left: 20px;
      box-sizing: border-box;
      list-style: none;
      line-height: 1.4;
      @include mq(sp) {
        font-size: 13px;
        padding-left: 15px;
      }
      &:before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: $blue;
        content: "";
        border-radius: 50%;
        left: 0;
        top: 10px;
        @include mq(sp) {
          width: 7px;
          height: 7px;
          top: 5px;
        }
      }
      a {
        color: #0071bc;
      }
    }
    &--3 {
      width: 600px;
      @include mq(sp) {
        width: 100%;
      }
      li {
        float: left;
        width: 33.3%;
      }
    }
    &--2 {
      width: 500px;
      @include mq(sp) {
        width: 100%;
      }
      li {
        float: left;
        width: 50%;
      }
    }
    &--2-to-1 {
      width: 100%;
      li {
        float: left;
        &:nth-child(odd) {
          width: 380px;
          clear: both;
          @include mq(sp) {
            width: 100%;
          }
        }
        &.to1 {
          width: 100%;
        }
      }
    }
    &--mb0 {
      margin-bottom: 0;
    }
  }
  .product-price {
    margin: 0;
    vertical-align: bottom;
    color: $blue;
    font-size: 24px;
    font-weight: bold;
    @include mq(sp) {
      margin-top: 5px !important;
      font-size: 13px;
    }
    .en-font {
      font-size: 52px;
      font-weight: bold;
      letter-spacing: 0.08em;
      @include mq(sp) {
        font-size: 24px;
      }
    }
    &__note {
      display: inline-block;
      font-size: 18px;
      margin-left: 5px;
      color: #000;
      @include mq(sp) {
        font-size: 10px;
      }
    }
    &__tel {
      margin-left: 10px;
      width: 164px;
      display: inline-block;
      vertical-align: middle;
      @include mq(sp) {
        display: block;
        margin-left: 0;
        margin-bottom: 5px;
        width: 120px;
      }
    }
  }
  .product-contact-list {
    @include clearfix;
    margin-left: -30px;
    margin-bottom: 10px;
    @include mq(sp) {
      margin-left: 0;
    }
    &__item {
      float: left;
      margin-left: 30px;
      width: 340px;
      height: 60px;
      list-style: none;
      @include mq(sp) {
        width: 280px;
        height: 40px;
        margin-bottom: 10px;
      }
    }
    &__link {
      display: block;
      background: #0064aa;
      color: #fff;
      font-size: 22px;
      background-repeat: no-repeat;
      line-height: 60px;
      @include hover-opacity;
      @include mq(sp) {
        font-size: 15px;
        line-height: 40px;
        padding: 0 !important;
        text-align: center;
      }
      &--download {
        padding: 0 0 0 100px;
        background-position: 40px center;
        background-image: url(../img/common/ico_download_01.png);
        background-size: 27px auto;
        @include mq(sp) {
          background-size: 16px auto;
          background-position: 20px center;
        }
      }
      &--phone {
        padding: 0 0 0 100px;
        background-position: 42px center;
        background-image: url(../img/common/ico_phone_01.png);
        background-size: 27px auto;
        @include mq(sp) {
          background-size: 16px auto;
          background-position: 20px center;
        }
      }
      &--mail {
        padding: 0 0 0 130px;
        background-position: 60px center;
        background-image: url(../img/common/ico_mail_01.png);
        background-size: 40px auto;
        @include mq(sp) {
          background-size: 24px auto;
          background-position: 20px center;
        }
      }
    }
    .en-font {
      font-size: 29px;
      font-weight: bold;
      letter-spacing: 0.1em;
      @include mq(sp) {
        font-size: 24px;
      }
    }
  }
  _:-ms-lang(x), .product-contact-list__link {
    line-height: 68px;
  }
  _:-ms-lang(x), .product-contact-list__link--download {
    background-position: 40px 8px;
  }
  _:-ms-lang(x), .product-contact-list__link--phone {
    background-position: 42px 8px;
  }
  _:-ms-lang(x), .product-contact-list__link--mail {
    background-position: 60px 14px;
  }
  .product-dtl {
    dd {
      margin-left: 320px;
      @include mq(sp) {
        margin-left: 0;
      }
    }
  }
}