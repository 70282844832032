/* ------------------------------------------------
  company
---------------------------------------------- */
.company-info {
  background: #e1e1e1;
  padding: 10px 0 60px;
  @include mq(sp) {
    padding: 20px 0 20px;
  }
  &__ttl {
    margin-bottom: 20px;
    font-weight: bold;
    color: $blue;
    font-size: 30px;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
  &__list {
    @include clearfix;
    margin-left: -15px;
    @include mq(sp) {
      margin-left: 0;
      padding: 0 10px;
    }
  }
  &__item {
    float: left;
    width: 350px;
    height: 150px;
    margin-left: 15px;
    text-align: center;
    @include mq(sp) {
      width: 33.3%;
      height: 80px;
      margin-left: 0;
      margin-bottom: 10px;
      &:nth-child(3n+1) {
        position: relative;left: -10px;
      }
      &:nth-child(3n) {
        position: relative;left: 10px;
      }
    }
    a {
      display: block;
      height: 150px;
      background: $blue;
      color: #fff;
      @include hover-opacity;
      @include mq(sp) {
        height: 80px;
      }
    }
    &-ttl {
      position: relative;
      display: block;
      margin-bottom: 35px;
      font-size: 29px;
      line-height: 1;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 13px;
        margin-bottom: 20px;
        font-weight: bold;
      }
      &:before {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 74px;
        height: 1px;
        background: #fff;
        content: "";
        @include mq(sp) {
          bottom: -10px;
        }
      }
    }
    &-sub {
      display: block;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.06em;
      @include mq(sp) {
        font-size: 10px;
      }
    }
    &-inner {
      padding-top: 38px;
      @include mq(sp) {
        padding-top: 20px;
      }

    }
  }
}
.company-btm-links {
  background: #f5f5f5;
  padding: 40px 0 80px;
  @include mq(sp) {
    padding: 20px 0;
  }
  &__ttl {
    margin-bottom: 20px;
    font-weight: bold;
    color: $blue;
    font-size: 30px;
    letter-spacing: 0.06em;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 16px;
    }
    &-inner {
      font-weight: normal;
      font-size: 25px;
      @include mq(sp) {
        font-size: 14px;
      }
    }
  }
  &__list {
    @include clearfix;
    margin-left: -20px;
    @include mq(sp) {
      margin-left: 0;
      padding: 0 5px;
    }
  }
  &__item {
    float: left;
    margin-bottom: 15px;
    margin-left: 20px;
    min-width: 255px;
    height: 50px;
    background: #fff;
    box-sizing: border-box;
    border: 1px solid $blue;
    @include mq(sp) {
      width: 100%;
      min-width: inherit;
      margin-bottom: 10px;
      box-sizing: border-box;
      margin-left: 0;
      height: 40px;
    }
    a {
      position: relative;
      display: block;
      padding: 13px 20px 0 45px;
      font-size: 21px;
      line-height: 1;
      color: $blue;
      @include hover-opacity;
      @include mq(sp) {
        padding: 13px 10px 0 25px;
        font-weight: bold;
        font-size: 12px;
      }
      &:before {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 8px;
        height: 8px;
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        content: "";
        transform: rotate(45deg);
        @include mq(sp) {
          top: 17px;
          left: 10px;
          width: 5px;
          height: 5px;
        }
      }
    }
  }
}
_:-ms-lang(x), .company-btm-links__item a {
  padding: 17px 20px 0 45px;
}
/*news*/
.news-category {

  &__tab {
    @include clearfix;
    text-align: center;
    background: #fff;
    border: 2px solid $blue;
    padding: 15px 0;
    @include mq(sp) {
      padding: 10px 0;
    }
    &-item {
      display: inline-block;
      font-size: 20px;
      letter-spacing: 0.12em;
      font-weight: bold;
      line-height: 1;
      border-left: 1px solid #000;
      @include mq(sp) {
        font-size: 11px;
        box-sizing: border-box;
        text-align: center;
        vertical-align: middle;
        border-radius: 6px 6px 0 0;
      }
      &:first-child {
        border-left: none;
      }
      a {
        position: relative;
        display: block;
        padding: 5px 30px 5px;
        transition: color 300ms;
        @include mq(sp) {
          text-align: center;
          background: #fff;
          padding: 0 10px;
        }
        &:before {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100px;
          height: 2px;
          transform: translateX(-50%);
          background: $blue;
          content: "";
          transition: opacity 300ms;
          opacity: 0;
          @include mq(sp) {
            width: 100%;
            left: 0;
            bottom: -2px;
            transform: translateX(0);
            height: 3px;
            background: #f5f5f5;
          }
        }
        &:hover {
          color: $blue;
          text-decoration: none;
          @include mq(sp) {
            color: #000;
          }
          &:before {
            opacity: 1;
            @include mq(sp) {
              opacity: 0;
            }
          }
        }
        &.select {
          color: $blue;
          text-decoration: none;
          &:before {
            opacity: 1;
            @include mq(sp) {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
  _:-ms-lang(x), .news-category__tab-item a {
    padding: 11px 30px 0;
  }
.news-page-list {
  .news-list {
    border-left: none;
    padding: 0;
    background: transparent;
    @include mq(sp) {
      margin-bottom: 20px;
      &:before {
        display: none;
      }
    }
    &__item {
      display: block !important;
      margin-bottom: 0;
      border-bottom: 1px dashed $blue;
    }
    &__link {
      position: relative;
      padding: 19px 24px 19px 0;
      &:before {
        position: absolute;
        top: 50%;
        right: 18px;
        width: 8px;
        height: 8px;
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        transform: rotate(45deg) translateY(-50%);
        content: "";
        @include mq(sp) {
          right: 8px;
        }
      }
      @include mq(sp) {
        padding: 5px 12px 5px 0;
      }    
    }
    &__date {
      width: 185px;
      font-size: 21px;
      font-weight: normal;
      @include mq(sp) {
        font-size: 10px;
        width: 80px;
      }
    }
    &__category {
      position: relative;
      top: 5px;
      float: left;
      width: 133px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      border-radius: 4px;
      @include mq(sp) {
        top: 0;
        margin-bottom: 5px;
        margin-left: 10px;
        font-size: 10px;
      }
    }
    &__ttl {
      font-size: 18px;
      margin-left: 337px;
      @include mq(sp) {
        margin-left: 0;
        font-size: 13px;
      }
    }
  }
}
_:-ms-lang(x), .news-page-list .news-list__category {
  top: 2px;
  padding: 6px 0 0;
  line-height: 1;
}
.news-archive {
  margin-top: -20px;
  margin-bottom: 40px;
  @include mq(sp) {
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__item {
    display: inline-block;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    @include mq(sp) {
      margin-left: 10px;
    }
    a {
      position: relative;
      display: block;
      width: 255px;
      padding-left: 45px;
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      font-size: 21px;
      border: 1px solid #4d4d4d;
      background: #e6e6e6;
      @include hover-opacity;
      @include mq(sp) {
        font-size: 12px;
        width: 100px;
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
      }
      &:before {
        position: absolute;
        top: 20px;
        left: 18px;
        width: 8px;
        height: 8px;
        border-top: 1px solid #4d4d4d;
        border-right: 1px solid #4d4d4d;
        transform: rotate(45deg);
        content: "";
        @include mq(sp) {
          top: 13px;
          left: 9px;
          width: 4px;
          height: 4px;
        }
      }
    }
  }
}
_:-ms-lang(x), .news-archive__item a {
  line-height: 54px;
}
.news-post-header {
  margin-bottom: 40px;
  padding-top: 50px;
  @include mq(sp) {
    padding-top: 20px;
    margin-bottom: 20px;
  }
  &__ttl {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: bold;
    color: $blue;
    letter-spacing: 0.06em;
    @include mq(sp) {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  &__date {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    @include mq(sp) {
      font-size: 12px;
    }
  }
  &__category {
    display: inline-block;
    line-height: 1;
    padding: 4px 25px;
    font-size: 14px;
    color: #fff;
    margin-left: 20px;
    vertical-align: middle;
    @include mq(sp) {
      font-size: 10px;
      margin-left: 10px;
      padding: 4px 20px;
    }
    &--recruit {
      background: #e155af;
    }
    &--topics {
      background: $blue;
    }
    &--message {
      background: #58a200;
    }
    &--important {
      background: #f00;
    }
    &--other {
      background: #b99600;
    }
    &--company {
      background: #005bb2;
    }
    &--group {
      background: $blue;
    }
  }
}
.news-post-inner {
  width: 800px;
  margin: 0 auto 60px;
  @include mq(sp) {
    width: 100%;
    margin: 0 0 40px;
  }
}
.news-post-footer {
  width: 800px;
  margin: 0 auto 100px;
  @include mq(sp) {
    width: 100%;
    margin: 0 0 40px;
  }
  &__link {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-size: 21px;
    color: $blue;
    @include mq(sp) {
      font-size: 13px;
      padding-left: 10px;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 8px;
      border-left: 1px solid $blue;
      border-top: 1px solid $blue;
      transform: rotate(-45deg) translateY(-50%);
      content: "";
      @include mq(sp) {
        width: 6px;
        height: 6px;
      }
    }
  }
}


[data-contents="original"] {
  .company-catch {
    padding: 120px 0 90px;
    @include mq(sp) {
      padding: 40px 0;
    }
    &__txt {
      width: 850px;
      margin: 0 auto ;
      @include mq(sp) {
        width: 100%;
      }
    }
  }
  .company-message {
    
    height: 490px;
    overflow: hidden;
    @include mq(sp) {
      height: 200px;
      background: url(../img/company/bg_top_01_sp.png) no-repeat left top;
      background-size: cover;
    }
    .container {
      display: table;
      height: 490px;
      @include mq(sp) {
        height: auto;
      }
    }
    &__link {
      position: relative;
      display: block;
      height: 490px;
      overflow: hidden;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../img/company/bg_top_01.png) no-repeat left top;
        background-size: cover;
        content: "";
        transition: opacity 300ms;
      }
      &:hover {
        text-decoration: none;
        &:before {
          opacity: 0.7;
        }
      }
      
      @include mq(sp) {
        display: block;
        position: relative;
        &:before {
          position: absolute;
          right: 0;
          top: 50%;
          width: 8px;
          height: 8px;
          border-top: 2px solid $blue;
          border-right: 2px solid $blue;
          transform: rotate(45deg) translateY(-50%);
          content: "";
          z-index: 3;
        }
      }
    }
    &__txt-wrap {
      position: relative;
      left: 50%;
      padding-top: 80px;
      margin-left: 70px;
      @include mq(sp) {
        padding-top: 15px;
        margin-left: -50px;
      }
    }
    &__tag {
      margin-bottom: 20px;
      line-height: 1;
      padding: 8px 10px;
      font-size: 23px;
      background: $blue;
      color: #fff;
      display: inline-block;
      @include mq(sp) {
        font-weight: bold;
        font-size: 10px;
        margin-bottom: 10px;
      }
    }
    &__ttl {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 47px;
      color: $blue;
      @include mq(sp) {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.4;
      }
    }
    &__name {
      color: $blue;
      margin-top: 0;
      font-size: 22px;
      @include mq(sp) {
        font-size: 10px;
      }
      &-inner {
        font-size: 25px;
        @include mq(sp) {
          font-size: 12px;
        }
      }
    }
  }
  _:-ms-lang(x), .company-message__tag {
    padding: 10px 10px 0;
  }
  .company-links {
    padding: 100px 0 100px;
    @include mq(sp) {
      padding: 20px 0;
    }
    &__list {
      @include clearfix;
      margin-bottom: 50px;
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
    &__item {
      float: left;
      width: 360px; 
      margin-bottom: 50px;
      @include mq(sp) {
        width: 100%;
        float: none;
        background: #fff;
        margin-bottom: 10px;
      }
      &:first-child {
        .company-links__txt-wrap {
          &:before {
            display: none;
          }
        }
      }
    }
    &__link {
      @include clearfix;
      @include mq(sp) {
        display: block;
        position: relative;
        padding-right: 10px;
        &:before {
          position: absolute;
          right: 15px;
          top: 50%;
          width: 8px;
          height: 8px;
          border-top: 2px solid $blue;
          border-right: 2px solid $blue;
          transform: rotate(45deg) translateY(-50%);
          content: "";
          z-index: 3;
        }
      }
      img {
        transition: transform 300ms;
      }
      &:hover {
        text-decoration: none;
        img {
          transform: scale(1.1);
        }
        .company-links__ttl {
          text-decoration: underline;
        }
      }
    }
    &__img-wrap {
      position: relative;
      z-index: 2;
      overflow: hidden;
      @include mq(sp) {
        float: left;
        width: 120px;
        margin: 10px 0 0 10px;
      }
    }
    &__txt-wrap {
      position: relative;
      background: #fff;
      padding: 30px 30px 0;
      min-height: 195px;
      box-sizing: border-box;
      @include mq(sp) {
        padding: 10px 10px 10px 0;
        margin-left: 140px;
        min-height: inherit;
        min-height: 110px;
      }
      &:before {
        position: absolute;
        top: 10px;
        left: 0px;
        width: 3px;
        height: 175px;
        background: url(../img/company/line_01.png) no-repeat left top;
        content: "";
        @include mq(sp) {
          display: none;
        }
      }
    }
    &__ttl {
      position: relative;
      display: inline-block;
      font-size: 24px;
      line-height: 1.4;
      color: $blue;
      margin-bottom: 10px;
      &--ls {
        margin-bottom: 5px;
        +.company-links__txt {
          line-height: 1.6;
        }
      }
      @include mq(sp) {
        display: block;
        font-weight: bold;
        font-size: 16px;
      }
      &:before {
        position: absolute;
        right: -30px;
        top: 50%;
        width: 8px;
        height: 8px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
        transform: rotate(45deg) translateY(-50%);
        content: "";
        @include mq(sp) {
          display: none;
        }
      }
    }
    &__txt {
      margin: 0;
      font-size: 15px;
      @include mq(sp) {
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
  _:-ms-lang(x), .company-links__ttl:before {
    margin-top: -7px;
  }

  /*message*/
  .top-message {
    padding: 30px 0;
    @include mq(sp) {
      padding: 10px 0 30px;
    }
    &__img {
      margin-bottom: 40px;
      @include mq(sp) {
        margin-bottom: 10px;
      }
    }
    &__txt {
      width: 800px;
      margin: 0 auto 50px;
      font-size: 18px;
      letter-spacing: 0.1em;
      line-height: 37px;
      @include mq(sp) {
        font-size: 13px;
        line-height: 1.7;
        width: auto;
        margin: 0 auto 20px;
      }
    }
    &__name {
      width: 800px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      @include mq(sp) {
        text-align: right;
        width: auto;
        font-size: 13px;
      }
      &-inner {
        display: block;
        font-size: 23px;
        @include mq(sp) {
          font-size: 15px;
        }
      }
    }
  }
  /*outline*/
  .company-dtl {
    
    dt {
      position: relative;
      padding-left: 20px;
      font-size: 22px;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: $blue;
      margin-bottom: 10px;
      @include mq(sp) {
        margin-bottom: 0;
        font-size: 15px;
        padding-left: 15px;
      }
      &:before {
        position: absolute;
        top: 14px;
        left: 0;
        width: 10px;
        height: 10px;
        background: $blue;
        border-radius: 50%;
        content: "";
        @include mq(sp) {
          top: 11px;
          width: 7px;
          height: 7px;
        }
      }
    }
    dd {
      font-size: 21px;
      @include mq(sp) {
        font-size: 13px;
      }

      + dt {
        margin-top: 40px;
        @include mq(sp) {
          margin-top: 10px;
        }
      }
    }
    &--line {
      @include clearfix;
      dt {
        float: left;
        @include mq(sp) {
          float: none;
        }
      }
      dd {
        padding-top: 2px;
        margin-bottom: 10px;
        margin-left: 195px;
        @include mq(sp) {
          margin-left: 0;
          padding-top: 0;
          margin-bottom: 0;
        }
        + dt {
          margin-top: 0;
          @include mq(sp) {
            margin-top: 10px;
          }
        }
      }
    }
    &--recruit {
      dd {
        margin-left: 210px;
        @include mq(sp) {
          margin-left: 0;
        }
      }
    }
    &__map {
      font-size: 17px;
      display: inline-block;
      background: $blue;
      color: #fff;
      line-height: 1;
      padding: 2px 8px;
      vertical-align: middle;
      margin-left: 10px;
      @include hover-opacity;
      @include mq(sp) {
        font-size: 13px;
        padding: 4px 10px;
      }
    }
    &__note {
      display: block;
      margin-left: 1em;
      text-indent: -1em;
      font-size: 19px;
      @include mq(sp) {
        font-size: 12px;
      }
    }
    &__sub-ttl {
      margin: 30px 0 10px;
      display: inline-block;
      padding-left: 10px;
      width: 420px;
      box-sizing: border-box;
      background: $blue;
      color: #fff;
      font-size: 19px;
      border-left: 13px solid #b3b3b3;
      @include mq(sp) {
        margin: 10px 0 10px;
        width: 100%;
        font-size: 14px;
      }
      &:first-child {
        margin: 0 0 10px;
      }
    }
    &__sub-txt {
      padding: 0 20px;
      font-size: 19px;
      @include mq(sp) {
        font-size: 13px;
        padding: 0 15px;
      }
      +.company-dtl__sub-list {
        margin-top: 15px;
        @include mq(sp) {
          margin-top: 10px;
        }
      }
    }
    &__sub-list {
      position: relative;
      color: $blue;
      font-weight: bold;
      font-size: 22px;
      padding-left: 20px;
      letter-spacing: 0.1em;
      @include mq(sp) {
        font-size: 14px;
        padding-left: 15px;
      }
      &:before {
        position: absolute;
        top: 14px;
        left: 0;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        border: 3px solid $blue;
        border-radius: 50%;
        content: "";
        @include mq(sp) {
          top: 7px;
        }
      }
    }
  }
  _:-ms-lang(x), .company-dtl__sub-ttl {
    padding-top: 6px;
    
  }
  .company-work-txt {
    width: 950px;
    margin: 0 auto;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.1em;
    @include mq(sp) {
      width: auto;
      font-size: 13px;
      line-height: 1.6;
    }
  }
  .company-dtl-block {
    width: 988px;
    margin: 40px auto 0;
    @include mq(sp) {
      width: auto;
      margin: 20px 0 0;
    }
    &__ttl {
      margin: 0;
      padding: 0;
      border: none;
      box-shadow: none;
      background: transparent;
      font-weight: bold;
      color: $blue;
      font-size: 21px;
      overflow: inherit !important;
      @include mq(sp) {
        font-size: 15px;
      }
    }
    &__txt {
      background: #dcdcdc;
      color: $blue;
      padding: 10px 20px;
      font-size: 20px;
      letter-spacing: 0.1em;
      @include mq(sp) {
        font-size: 13px;
        padding: 10px 10px;
      }
    }
  }
  _:-ms-lang(x), .company-dtl__map {
    padding: 8px 8px 0;
  }
  .company-mark-block {
    @include clearfix;
    padding: 0 135px 0 0;
    @include mq(sp) {
      padding: 0;
    }
    &__img {
      float: right;
      margin: 0 0 10px 10px;
    }
    &__txt {
      margin-bottom: 60px;
      margin-right: 265px;
      font-size: 20px;
      letter-spacing: 0.1em;
      text-indent: 1em;
      @include mq(sp) {
        margin: 0;
        font-size: 13px;
      }
      +.company-mark-block__txt {
        margin-top: -60px;
        @include mq(sp) {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .company-group {
    @include clearfix;
    &__logo {
      float: left;
      width: 197px;
      border: 1px solid #d0d0d0;
      @include mq(sp) {
        float: none;
        margin: 0 auto 10px;
      }
      &-link {
        display: block;
        @include hover-opacity;
      }
    }
    .company-dtl {
      margin-left: 240px;
      @include mq(sp) {
        margin-left: 0;
      }
    }
  }
  .company-tsuyomi {
    width: 950px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 25px;
    @include clearfix;
    @include mq(sp) {
      width: auto;
      padding: 0;
    }
    &__item {
      position: relative;
      width: 50%;
      float: left;
      margin-bottom: 60px;
      @include mq(sp) {
        width: 100%;
        left: 0 !important;
        margin-bottom: 10px;
      }
      &:nth-child(odd) {
        left: -25px;
        clear: both;
      }
      &:nth-child(even) {
        left: 25px;
      }
    }
    &__txt-wrap {
      background: #fff;
      height: 195px;
      box-sizing: border-box;
      padding: 30px 45px 0;
      @include mq(sp) {
        padding: 10px;
        height: auto;
      }
    }
    img {
      transition: transform 300ms;
      @include mq(sp) {
        width: 120px;
        margin: 10px 10px 0;
      }
    }
    &__link {
      @include clearfix;
      &:hover {
        text-decoration: none;
        img {
          transform: scale(1.1);
        }
        .company-tsuyomi__ttl {
          text-decoration: underline;
        }
      }
    }
    &__img-wrap {
      overflow: hidden;
      @include mq(sp) {
        float: left;
      }
    }
    &__ttl {
      position: relative;
      display: inline-block;
      color: $blue;
      background: none;
      padding: 0 35px 0 0;
      border: none;
      box-shadow: none;
      margin: 0 0 10px;
      font-size: 24px;
      letter-spacing: 0.06em;
      overflow: inherit !important;
      @include mq(sp) {
        display: block;
        margin-left: 130px;
        font-size: 16px;
        padding-top: 10px;
        font-weight: bold;
      }
      &:before {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 8px;
        height: 8px;
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        content: "";
        transform: rotate(45deg) translateY(-50%);
      }
    }
    &__txt {
      font-size: 15px;
      line-height: 25px;
      margin: 0;
      letter-spacing: 0.08em;
      @include mq(sp) {
        font-size: 13px;
        line-height: 1.6;
      }
    }
  }
  _:-ms-lang(x), .company-tsuyomi__ttl:before {
    margin-top: -7px;
  }
  .column-txt {
    font-size: 18px;
    margin-bottom: 50px;
    line-height: 2;
    @include mq(sp) {
      font-size: 13px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }
  .column-img {
    margin-bottom: 50px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
}