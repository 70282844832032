@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

@keyframes showAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  -webkit-text-size-adjust: 100%;
  color: #000;
  background: #fff;
  font-size: 14px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK JP Subset",'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 13px;
  }
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  a {
    color: black;
  }
}

strong {
  font-weight: bold;
}

/*font*/
@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans CJK JP"), local("NotoSansCJKjp-Regular"), local("NotoSansJP-Regular"), url("../font/NotoSansCJKjp-Regular.woff2") format("woff2"), url("../font/NotoSansCJKjp-Regular.woff") format("woff"), url("../font/NotoSansCJKjp-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP"), local("NotoSansCJKjp-Bold"), local("NotoSansJP-Bold"), url("../font/NotoSansCJKjp-Bold.woff2") format("woff2"), url("../font/NotoSansCJKjp-Bold.woff") format("woff"), url("../font/NotoSansCJKjp-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'gidoleregular';
  src: url("../font/gidole-regular.woff2") format("woff2"), url("../font/gidole-regular.woff") format("woff"), url("../font/gidole-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.en-font, .pager {
  font-family: 'gidoleregular';
}

/* wrapper
-------------------------*/
.wrapper {
  position: relative;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-top: 50px;
  }
}

.wrapper img {
  display: block;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.top-search {
  position: relative;
  width: 1080px;
  margin: 0 auto;
}

.top-search__input {
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 14px;
  padding-left: 40px;
  box-sizing: border-box;
  width: 176px;
  background: #fff;
  height: 29px;
  line-height: 29px;
  border: 1px solid #464646;
  border-radius: 20px;
  z-index: 10;
}

.top-search__input::-webkit-input-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.top-search__input:-moz-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
  -moz-osx-font-smoothing: grayscale;
}

.top-search__input::-moz-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
  -moz-osx-font-smoothing: grayscale;
  opacity: 1;
}

.top-search__input:-ms-input-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
}

.top-search__btn {
  position: absolute;
  top: 25px;
  right: 147px;
  width: 18px;
  height: 19px;
  background: url(../img/common/ico_search_01.png) no-repeat left top;
  background-size: 18px auto;
  border: none;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 10;
  overflow: hidden;
}

/* header
-------------------------*/
.header {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 90;
  background: transparent;
  height: 75px;
  transition: opacity 300ms;
}

@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    height: auto;
  }
  .header .container {
    padding: 0;
  }
}

.header.none {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .header.none {
    opacity: 1;
  }
}

.header.none.fix {
  opacity: 1;
}

.header.fix {
  position: fixed;
  top: 0;
}

.header__logo {
  float: left;
  margin: 15px 0 0 20px;
}

@media screen and (max-width: 768px) {
  .header__logo {
    width: 200px;
    float: none;
    display: block;
    margin: 0;
    padding: 10px 0 0 15px;
  }
}

.header__bar {
  position: relative;
  height: 75px;
  padding-right: 150px;
  background: #fff;
  box-shadow: 0 3.45px 1.72px rgba(35, 24, 21, 0.6);
}

.header__bar:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .header__bar {
    height: 50px;
    padding-right: 0;
  }
}

.header__contact-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 75px;
  background: #193b88 url(../img/common/btn_contact_header.png) no-repeat center center;
  background-size: 98px auto;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: opacity 300ms;
}

.header__contact-btn:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .header__contact-btn {
    position: relative;
    width: 100%;
    height: auto;
    background: none;
    color: #fff;
    display: block;
    color: #fff;
    padding: 10px 15px;
    text-indent: 0;
    box-sizing: border-box;
  }
  .header__contact-btn:after {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%) rotate(45deg);
    border-top: 2px solid rgba(255, 255, 255, 0.8);
    border-right: 2px solid rgba(255, 255, 255, 0.8);
    content: "";
  }
  .header__contact-btn:hover {
    text-decoration: none;
  }
}

.header__search {
  font-size: 14px;
  padding-left: 40px;
  box-sizing: border-box;
  width: 176px;
  background: #fff;
  height: 29px;
  line-height: 29px;
  border: 1px solid #464646;
  border-radius: 20px;
}

.header__search::-webkit-input-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.header__search:-moz-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
  -moz-osx-font-smoothing: grayscale;
}

.header__search::-moz-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
  -moz-osx-font-smoothing: grayscale;
  opacity: 1;
}

.header__search:-ms-input-placeholder {
  color: rgba(25, 59, 136, 0.3);
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .header__search {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 16px;
    border: 1px solid #fff;
    padding-left: 10px;
    -webkit-appearance: none;
    padding: 0 10px;
  }
}

.header__search-wrap {
  position: absolute;
  top: -50px;
  right: 0;
}

@media screen and (max-width: 768px) {
  .header__search-wrap {
    position: relative;
    top: 0;
    width: 100%;
    padding: 9px 75px 9px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.header__search-btn {
  position: absolute;
  top: 5px;
  right: 147px;
  width: 18px;
  height: 19px;
  background: url(../img/common/ico_search_01.png) no-repeat left top;
  background-size: 18px auto;
  border: none;
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .header__search-btn {
    -webkit-appearance: none;
    border-radius: 0;
    top: 9px;
    right: 15px;
    width: 50px;
    height: 29px;
    background: #fff;
    text-indent: 0;
    font-weight: bold;
    color: #193b88;
    letter-spacing: 0.1em;
    font-size: 12px;
  }
}

.header__menu-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding-top: 30px;
  box-sizing: border-box;
  background: #193b88;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  font-weight: bold;
}

.header__menu-btn:hover {
  text-decoration: none;
}

.header__menu-btn:before {
  position: absolute;
  top: 10px;
  left: 13px;
  box-sizing: border-box;
  width: 24px;
  height: 18px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  content: "";
  transition: all 300ms;
}

.header__menu-btn:after {
  position: absolute;
  top: 18px;
  left: 13px;
  content: "";
  width: 24px;
  height: 2px;
  background: #fff;
  transition: all 300ms;
}

.header__menu-btn.open:before {
  transform: rotate(45deg);
  border-bottom: none;
  top: 17px;
  left: 7px;
}

.header__menu-btn.open:after {
  transform: rotate(-45deg);
  top: 19px;
}

.gnav {
  float: right;
}

@media screen and (max-width: 768px) {
  .gnav {
    float: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background: rgba(25, 59, 136, 0.8);
    box-sizing: border-box;
    transition: height 200ms;
  }
  .gnav.open {
    height: 288px;
  }
}

.gnav__list {
  margin-right: 20px;
}

.gnav__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .gnav__list {
    margin-right: 0;
  }
}

.gnav__item {
  float: left;
  margin-right: 30px;
  line-height: 75px;
}

@media screen and (max-width: 768px) {
  .gnav__item {
    float: none;
    margin-right: 0;
    line-height: 2;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.gnav__item a {
  position: relative;
  color: #193b88;
  transition: all 300ms;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .gnav__item a {
    display: block;
    font-size: 14px;
    padding: 10px 15px;
    color: #fff;
    box-sizing: border-box;
  }
  .gnav__item a:after {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%) rotate(45deg);
    border-top: 2px solid rgba(255, 255, 255, 0.8);
    border-right: 2px solid rgba(255, 255, 255, 0.8);
    content: "";
  }
}

.gnav__item a:hover {
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .gnav__item a:hover {
    color: #fff;
  }
}

.gnav__item a:hover:before {
  width: 100%;
}

.gnav__item a:before {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0;
  height: 2px;
  background: #193b88;
  content: "";
  transition: all 300ms;
}

@media screen and (max-width: 768px) {
  .gnav__item a:before {
    display: none;
  }
}

_:-ms-lang(x), .gNav {
  margin-top: 6px;
}

/* breadcrumb
-------------------------*/
.breadcrumb {
  padding: 10px 0;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    font-size: 10px;
  }
}

.breadcrumb a {
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .breadcrumb a {
    font-weight: bold;
  }
}

.breadcrumb__item {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  line-height: 1;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .breadcrumb__item {
    padding: 0 10px 0 0;
  }
}

.breadcrumb__item:before {
  position: absolute;
  top: 50%;
  left: -8px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  border-top: 1px solid #193b88;
  border-right: 1px solid #193b88;
  content: "";
  margin-top: -5px;
}

@media screen and (max-width: 768px) {
  .breadcrumb__item:before {
    width: 4px;
    height: 4px;
  }
}

.breadcrumb__item:first-child:before {
  display: none;
}

_:-ms-lang(x), .breadcrumb__item:before {
  margin-top: -7px;
}

/* contents
-------------------------*/
.contents {
  background: #fff;
}

.contents--bg {
  background: #f5f5f5;
}

/* footer
-------------------------*/
.footer {
  background: #193b88;
  padding: 30px 0 0;
  color: #fff;
}

.footer a {
  color: #fff;
}

.footer a:hover img {
  opacity: .7;
}

.footer .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.footer__container {
  width: 20%;
  float: left;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .footer__container {
    width: 50%;
    margin-bottom: 20px;
  }
  .footer__container:nth-child(odd) {
    clear: both;
  }
}

.footer__link {
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .footer__link {
    font-size: 13px;
    margin-bottom: 5px;
    text-decoration: underline;
  }
}

.footer__link-list-item {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .footer__link-list-item {
    font-size: 12px;
    line-height: 1.7;
  }
  .footer__link-list-item a {
    text-decoration: underline;
  }
}

.footer__link-list-item img {
  opacity: 1;
  margin: 0 auto 0 0;
  transition: opacity 300ms;
}

.footer__link-list-item--bnr {
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .footer__link-list-item--bnr {
    margin-top: 10px;
  }
}

.footer__link-list-item--bnr + .footer__link-list-item--bnr {
  margin-top: 10px;
}

.footer__link-sub-list {
  margin-top: 10px;
  margin-bottom: 1em;
  margin-left: 1em;
}

.footer__link-sub-list li {
  margin-left: 1em;
  margin-bottom: 10px;
  text-indent: -1em;
  line-height: 1.2;
}

.footer__btm {
  padding: 20px 0 20px;
  clear: both;
  border-top: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .footer__btm {
    padding: 15px 0;
  }
}

.footer__btm-list {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer__btm-list {
    text-align: left;
  }
}

.footer__btm-list li {
  display: inline-block;
  line-height: 1;
}

.footer__btm-list li a {
  display: block;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .footer__btm-list li a {
    padding: 0 10px;
    font-size: 12px;
  }
}

.footer__btm-list li:nth-child(odd) {
  padding-left: 10px;
  border-left: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .footer__btm-list li:nth-child(odd) {
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer__btm-list li:nth-child(even) {
    border-left: 1px solid #fff;
  }
}

.footer__btm-list li:first-child {
  border-left: none;
}

.footer__copyright {
  padding: 30px 0;
  background: #fff;
  color: #000;
}

@media screen and (max-width: 768px) {
  .footer__copyright {
    padding: 15px 0;
    font-size: 10px;
  }
}

.footer__copyright img {
  margin: 0 auto 0 0;
}

/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap, .fade {
  display: block;
  text-decoration: none;
}

.wrap:hover, .fade:hover {
  text-decoration: none;
}

.ib {
  display: inline-block;
}

.phoneTxt {
  position: relative;
  text-decoration: none;
}

.phoneTxt:hover {
  text-decoration: none;
}

.phoneTxt-wrap {
  position: relative;
}

.phoneTxt-number {
  display: none;
  position: absolute;
  top: -20px;
  left: 5px;
  width: 200px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #193b88;
  text-align: center;
  background: #193b88;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.1em;
}

.phoneTxt-number.show {
  display: block;
  animation: showAnime 300ms;
}

.phoneTxt-number-close {
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 2px solid #193b88;
  box-sizing: border-box;
  content: "";
  cursor: pointer;
}

.phoneTxt-number-close:before {
  position: absolute;
  top: 12px;
  left: 4px;
  width: 18px;
  height: 2px;
  background: #193b88;
  content: "";
  transform: rotate(45deg);
}

.phoneTxt-number-close:after {
  position: absolute;
  top: 12px;
  left: 4px;
  width: 18px;
  height: 2px;
  background: #193b88;
  content: "";
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .is_pc {
    display: none;
  }
}

.is_sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .is_sp {
    display: block !important;
  }
}

.container {
  position: relative;
  width: 1080px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .top-slider {
    margin-bottom: 20px;
  }
}

.top-slider .slick-dots {
  position: absolute;
  width: 100%;
  height: 36px;
  background: #193b88;
  bottom: 0;
  left: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top-slider .slick-dots {
    height: auto;
    padding: 5px 0;
    background: transparent;
    bottom: -22px;
  }
}

.top-slider .slick-dots li {
  display: inline-block;
  margin-right: 12px;
}

.top-slider .slick-dots button {
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  background: #fff;
  z-index: 5;
  font-size: 0;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .top-slider .slick-dots button {
    width: 8px;
    height: 8px;
    border: 1px solid #193b88;
  }
}

.top-slider .slick-dots .slick-active button {
  background: #b9f4ff;
}

@media screen and (max-width: 768px) {
  .top-slider .slick-dots .slick-active button {
    background: #193b88;
  }
}

.top-slider .slick-prev,
.top-slider .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 25px;
  height: 67px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  transform: translateY(-50%);
  z-index: 5;
  font-size: 0;
  line-height: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 25px auto;
}

@media screen and (max-width: 768px) {
  .top-slider .slick-prev,
  .top-slider .slick-next {
    display: none !important;
  }
}

.top-slider .slick-prev {
  left: 47px;
  background-image: url(../img/top/btn_prev_01.png);
}

.top-slider .slick-next {
  right: 47px;
  background-image: url(../img/top/btn_next_01.png);
}

.topics-list .slick-dots {
  text-align: center;
}

.topics-list .slick-dots li {
  display: inline-block;
  margin-right: 12px;
}

.topics-list .slick-dots button {
  display: block;
  width: 60px;
  height: 8px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #f0f0f0;
  z-index: 5;
  font-size: 0;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .topics-list .slick-dots button {
    width: 30px;
  }
}

.topics-list .slick-dots .slick-active button {
  background: #b9f4ff;
}

.topics-list .slick-prev,
.topics-list .slick-next {
  position: absolute;
  top: 92px;
  display: block;
  width: 16px;
  height: 32px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 5;
  font-size: 0;
  line-height: 0;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .topics-list .slick-prev,
  .topics-list .slick-next {
    top: 50%;
    transform: translateY(-50%);
  }
}

.topics-list .slick-prev:before,
.topics-list .slick-next:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  content: "";
}

.topics-list .slick-prev {
  left: -28px;
}

@media screen and (max-width: 768px) {
  .topics-list .slick-prev {
    left: -12px;
  }
}

.topics-list .slick-prev:before {
  border-right: 16px solid rgba(255, 255, 255, 0.5);
}

.topics-list .slick-next {
  right: -28px;
}

@media screen and (max-width: 768px) {
  .topics-list .slick-next {
    right: -12px;
  }
}

.topics-list .slick-next:before {
  border-left: 16px solid rgba(255, 255, 255, 0.5);
}

/* ------------------------------------------------
  TOP
---------------------------------------------- */
.slider-wrap {
  z-index: 80;
}

.slider {
  width: 100%;
  min-width: 1080px;
}

@media screen and (max-width: 768px) {
  .slider {
    min-width: inherit;
  }
}

.slider img {
  display: block;
  width: 100%;
}

.slider__item {
  position: relative;
}

.slider__item:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  content: "";
  z-index: 1;
}

.slider__item--03 .slider__item-txt {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .slider__item--03 .slider__item-txt {
    margin-bottom: 10px;
  }
}

.slider__item-inner {
  position: absolute;
  width: 1040px;
  top: 50%;
  left: 50%;
  margin-top: 30px;
  z-index: 2;
  transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 768px) {
  .slider__item-inner {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    top: 0;
    left: 0;
    margin-top: 0;
    transform: translateX(0);
  }
}

.slider__item-ttl {
  color: #fff;
  font-size: 37px;
  line-height: 55px;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  text-shadow: 0 0 5px #000;
}

@media screen and (max-width: 768px) {
  .slider__item-ttl {
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 1.6;
  }
}

.slider__item-txt {
  margin-bottom: 60px;
  color: #fff;
  font-size: 19px;
  line-height: 39px;
  letter-spacing: 0.06em;
  text-shadow: 0 0 5px #000;
}

@media screen and (max-width: 768px) {
  .slider__item-txt {
    margin-bottom: 10px;
    font-size: 10px;
    line-height: 1.6;
  }
}

.slider__item-btn {
  display: block;
  width: 253px;
  opacity: 1;
  transition: opacity 300ms;
}

.slider__item-btn:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .slider__item-btn {
    width: 120px;
  }
}

.top-section__ttl {
  position: relative;
  margin: 0 auto;
  padding-bottom: 23px;
}

@media screen and (max-width: 768px) {
  .top-section__ttl {
    padding-bottom: 10px;
  }
}

.top-section__ttl-sub {
  display: block;
  margin-bottom: 70px;
  text-align: center;
  color: #193b88;
  font-size: 18px;
  letter-spacing: 0.22em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-section__ttl-sub {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.top-section__ttl:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 150px;
  height: 2px;
  background: #193b88;
  transform: translateX(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  .top-section__ttl:before {
    width: 100px;
  }
}

.top-section--works {
  padding: 110px 0 100px;
}

@media screen and (max-width: 768px) {
  .top-section--works {
    padding: 40px 0;
  }
}

.top-section--works .top-section__ttl {
  width: 236px;
}

@media screen and (max-width: 768px) {
  .top-section--works .top-section__ttl {
    width: 118px;
  }
}

.top-section--topics {
  padding: 50px 0 40px;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 768px) {
  .top-section--topics {
    padding: 40px 0;
  }
}

.top-section--topics .top-section__ttl {
  width: 234px;
  margin: 0 auto 60px 0;
  padding-left: 86px;
}

@media screen and (max-width: 768px) {
  .top-section--topics .top-section__ttl {
    width: 117px;
    padding-left: 40px;
    margin: 0 auto 20px 0;
  }
}

.top-section--topics .top-section__ttl:before {
  width: 320px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .top-section--topics .top-section__ttl:before {
    width: 180px;
  }
}

.top-section--news {
  padding: 110px 0 100px;
}

@media screen and (max-width: 768px) {
  .top-section--news {
    padding: 40px 0;
  }
}

.top-section--news .top-section__ttl {
  width: 185px;
}

@media screen and (max-width: 768px) {
  .top-section--news .top-section__ttl {
    width: 92px;
  }
}

.works-list__list {
  margin-bottom: 50px;
}

.works-list__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .works-list__list {
    margin-bottom: 0;
  }
}

.works-list__item {
  float: left;
  width: 360px;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .works-list__item {
    width: 100%;
    float: none;
    background: #f0f0f0;
    margin-bottom: 10px;
  }
}

.works-list__item:first-child .works-list__txt-wrap:before {
  display: none;
}

.works-list__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .works-list__link {
    display: block;
    position: relative;
    padding-right: 10px;
  }
  .works-list__link:before {
    position: absolute;
    right: 15px;
    top: 50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid #193b88;
    border-right: 2px solid #193b88;
    transform: rotate(45deg) translateY(-50%);
    content: "";
    z-index: 3;
  }
}

.works-list__link img {
  transition: transform 300ms;
}

.works-list__link:hover {
  text-decoration: none;
}

.works-list__link:hover img {
  transform: scale(1.1);
}

.works-list__link:hover .company-links__ttl {
  text-decoration: underline;
}

.works-list__img-wrap {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .works-list__img-wrap {
    float: left;
    width: 120px;
    margin: 10px 0 0 10px;
  }
}

.works-list__txt-wrap {
  position: relative;
  background: #f0f0f0;
  padding: 30px 30px 0;
  min-height: 195px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .works-list__txt-wrap {
    padding: 10px 10px 10px 0;
    margin-left: 140px;
    min-height: inherit;
    min-height: 110px;
  }
}

.works-list__txt-wrap:before {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 3px;
  height: 175px;
  background: url(../img/company/line_01.png) no-repeat left top;
  content: "";
}

@media screen and (max-width: 768px) {
  .works-list__txt-wrap:before {
    display: none;
  }
}

.works-list__ttl {
  position: relative;
  display: inline-block;
  font-size: 24px;
  line-height: 1.4;
  color: #193b88;
  margin-bottom: 10px;
}

.works-list__ttl--ls {
  margin-bottom: 5px;
}

.works-list__ttl--ls + .company-links__txt {
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .works-list__ttl {
    display: block;
    font-weight: bold;
    font-size: 16px;
  }
}

.works-list__ttl:before {
  position: absolute;
  right: -30px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-top: 2px solid #193b88;
  border-right: 2px solid #193b88;
  transform: rotate(45deg) translateY(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  .works-list__ttl:before {
    display: none;
  }
}

.works-list__txt {
  margin: 0;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .works-list__txt {
    font-size: 12px;
    line-height: 1.4;
  }
}

_:-ms-lang(x), .works-list__ttl:before {
  margin-top: -7px;
}

.topics-list {
  width: 1020px;
}

@media screen and (max-width: 768px) {
  .topics-list {
    width: auto;
  }
}

.topics-list .wrap {
  color: #fff;
}

.topics-list .wrap:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.topics-list .wrap img {
  opacity: 1;
  transition: transform 300ms;
}

@media screen and (max-width: 768px) {
  .topics-list .wrap img {
    float: left;
    width: 100px;
  }
}

.topics-list .wrap:hover img {
  transform: scale(1.1);
  transform-origin: center center;
}

.topics-list .wrap:hover .topics-list__ttl {
  text-decoration: underline;
}

.topics-list__img-wrap {
  overflow: hidden;
}

.topics-list__item {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .topics-list__item {
    margin-bottom: 10px;
  }
}

.topics-list__ttl {
  margin: 10px 0;
  padding-left: 24px;
  font-size: 22px;
  font-weight: bold;
  background-image: url(../img/common/ico_link_01.png);
  background-repeat: no-repeat;
  background-position: left 4px;
  background-size: 16px auto;
  line-height: 1.1;
  height: 2.2em;
}

@media screen and (max-width: 768px) {
  .topics-list__ttl {
    margin: 10px 0 5px 110px;
    padding-left: 14px;
    font-size: 14px;
    background-size: 12px auto;
    background-position: left 2px;
  }
}

.topics-list__txt {
  padding: 0 10px;
  line-height: 19.5px;
}

@media screen and (max-width: 768px) {
  .topics-list__txt {
    margin-left: 110px;
    padding: 0 10px 0 0;
    font-size: 12px;
  }
}

_:-ms-lang(x), .topics-list__ttl {
  padding-top: 5px;
}

.news-tab {
  margin-top: -40px;
  margin-bottom: 50px;
  text-align: center;
}

.news-tab:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .news-tab {
    margin-top: 0;
    width: 100%;
    margin-bottom: 0;
  }
}

.news-tab__item {
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
  border-left: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .news-tab__item {
    font-size: 12px;
    width: 20%;
    box-sizing: border-box;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
    border-top: 1px solid #193b88;
    border-left: 1px solid #193b88;
    border-bottom: 1px solid #193b88;
    display: block;
    float: left;
    border-radius: 6px 6px 0 0;
  }
}

.news-tab__item:first-child {
  border-left: none;
}

@media screen and (max-width: 768px) {
  .news-tab__item:first-child {
    border-left: 1px solid #193b88;
  }
}

@media screen and (max-width: 768px) {
  .news-tab__item:last-child {
    border-right: 1px solid #193b88;
  }
}

.news-tab__item a {
  position: relative;
  display: block;
  padding: 0 30px 10px;
  transition: color 300ms;
}

@media screen and (max-width: 768px) {
  .news-tab__item a {
    text-align: center;
    background: #fff;
    height: 36px;
    border-radius: 6px 6px 0 0;
    padding: 0;
  }
}

.news-tab__item a:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100px;
  height: 2px;
  transform: translateX(-50%);
  background: #193b88;
  content: "";
  transition: opacity 300ms;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .news-tab__item a:before {
    width: 100%;
    left: 0;
    bottom: -2px;
    transform: translateX(0);
    height: 3px;
    background: #f5f5f5;
  }
}

.news-tab__item a:hover {
  color: #193b88;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .news-tab__item a:hover {
    color: #000;
  }
}

.news-tab__item a:hover:before {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .news-tab__item a:hover:before {
    opacity: 0;
  }
}

.news-tab__item a.select {
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .news-tab__item a.select {
    background: #f5f5f5;
  }
}

.news-tab__item a.select:before {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .news-tab__item-inner {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.news-list {
  margin-bottom: 40px;
  padding: 40px 30px;
  background: #f5f5f5;
  border-left: 3px solid #193b88;
}

@media screen and (max-width: 768px) {
  .news-list {
    position: relative;
    border: none;
    padding: 15px 15px 5px;
    margin-bottom: 0;
  }
  .news-list:before {
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
    height: 60px;
    background: #f5f5f5;
    content: "";
    z-index: 1;
  }
}

.news-list__item {
  margin-bottom: 10px;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .news-list__item {
    border-bottom: 1px solid rgba(25, 59, 136, 0.3);
  }
}

.news-list__item a {
  display: block;
}

.news-list__date {
  float: left;
  width: 110px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .news-list__date {
    font-size: 10px;
    width: 60px;
    margin-bottom: 5px;
  }
}

.news-list__category {
  float: left;
  width: 133px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .news-list__category {
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 10px;
    width: 110px;
  }
}

.news-list__category--recruit {
  background: #e155af;
}

.news-list__category--topics {
  background: #193b88;
}

.news-list__category--message {
  background: #58a200;
}

.news-list__category--important {
  background: #f00;
}

.news-list__category--other {
  background: #b99600;
}

.news-list__category--company {
  background: #005bb2;
}

.news-list__category--group {
  background: #193b88;
}

.news-list__ttl {
  font-size: 17px;
  margin-left: 265px;
}

@media screen and (max-width: 768px) {
  .news-list__ttl {
    font-size: 13px;
    margin-left: 0;
    clear: both;
  }
}

_:-ms-lang(x), .news-list__category {
  padding: 6px 0 0;
  line-height: 1;
}

.more-btn {
  display: none;
  width: 260px;
  height: 53px;
  margin: 0 auto;
  line-height: 53px;
  color: #193b88;
  border: 2px solid #193b88;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.2em;
  transition: all 300ms;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .more-btn {
    position: relative;
    z-index: 2;
    width: 140px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }
}

.more-btn:hover {
  background: #193b88;
  color: #fff;
  text-decoration: none;
}

.more-btn.show {
  display: block;
}

.more-btn--all {
  display: block;
}

_:-ms-lang(x), .more-btn {
  line-height: 57px;
}

/* ------------------------------------------------
  company
---------------------------------------------- */
.company-info {
  background: #e1e1e1;
  padding: 10px 0 60px;
}

@media screen and (max-width: 768px) {
  .company-info {
    padding: 20px 0 20px;
  }
}

.company-info__ttl {
  margin-bottom: 20px;
  font-weight: bold;
  color: #193b88;
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .company-info__ttl {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.company-info__list {
  margin-left: -15px;
}

.company-info__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .company-info__list {
    margin-left: 0;
    padding: 0 10px;
  }
}

.company-info__item {
  float: left;
  width: 350px;
  height: 150px;
  margin-left: 15px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .company-info__item {
    width: 33.3%;
    height: 80px;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .company-info__item:nth-child(3n+1) {
    position: relative;
    left: -10px;
  }
  .company-info__item:nth-child(3n) {
    position: relative;
    left: 10px;
  }
}

.company-info__item a {
  display: block;
  height: 150px;
  background: #193b88;
  color: #fff;
  opacity: 1;
  transition: opacity 300ms;
}

.company-info__item a:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .company-info__item a {
    height: 80px;
  }
}

.company-info__item-ttl {
  position: relative;
  display: block;
  margin-bottom: 35px;
  font-size: 29px;
  line-height: 1;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  .company-info__item-ttl {
    font-size: 13px;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.company-info__item-ttl:before {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 74px;
  height: 1px;
  background: #fff;
  content: "";
}

@media screen and (max-width: 768px) {
  .company-info__item-ttl:before {
    bottom: -10px;
  }
}

.company-info__item-sub {
  display: block;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  .company-info__item-sub {
    font-size: 10px;
  }
}

.company-info__item-inner {
  padding-top: 38px;
}

@media screen and (max-width: 768px) {
  .company-info__item-inner {
    padding-top: 20px;
  }
}

.company-btm-links {
  background: #f5f5f5;
  padding: 40px 0 80px;
}

@media screen and (max-width: 768px) {
  .company-btm-links {
    padding: 20px 0;
  }
}

.company-btm-links__ttl {
  margin-bottom: 20px;
  font-weight: bold;
  color: #193b88;
  font-size: 30px;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  .company-btm-links__ttl {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.company-btm-links__ttl-inner {
  font-weight: normal;
  font-size: 25px;
}

@media screen and (max-width: 768px) {
  .company-btm-links__ttl-inner {
    font-size: 14px;
  }
}

.company-btm-links__list {
  margin-left: -20px;
}

.company-btm-links__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .company-btm-links__list {
    margin-left: 0;
    padding: 0 5px;
  }
}

.company-btm-links__item {
  float: left;
  margin-bottom: 15px;
  margin-left: 20px;
  min-width: 255px;
  height: 50px;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #193b88;
}

@media screen and (max-width: 768px) {
  .company-btm-links__item {
    width: 100%;
    min-width: inherit;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-left: 0;
    height: 40px;
  }
}

.company-btm-links__item a {
  position: relative;
  display: block;
  padding: 13px 20px 0 45px;
  font-size: 21px;
  line-height: 1;
  color: #193b88;
  opacity: 1;
  transition: opacity 300ms;
}

.company-btm-links__item a:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .company-btm-links__item a {
    padding: 13px 10px 0 25px;
    font-weight: bold;
    font-size: 12px;
  }
}

.company-btm-links__item a:before {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #193b88;
  border-right: 1px solid #193b88;
  content: "";
  transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  .company-btm-links__item a:before {
    top: 17px;
    left: 10px;
    width: 5px;
    height: 5px;
  }
}

_:-ms-lang(x), .company-btm-links__item a {
  padding: 17px 20px 0 45px;
}

/*news*/
.news-category__tab {
  text-align: center;
  background: #fff;
  border: 2px solid #193b88;
  padding: 15px 0;
}

.news-category__tab:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .news-category__tab {
    padding: 10px 0;
  }
}

.news-category__tab-item {
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.12em;
  font-weight: bold;
  line-height: 1;
  border-left: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .news-category__tab-item {
    font-size: 11px;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px 6px 0 0;
  }
}

.news-category__tab-item:first-child {
  border-left: none;
}

.news-category__tab-item a {
  position: relative;
  display: block;
  padding: 5px 30px 5px;
  transition: color 300ms;
}

@media screen and (max-width: 768px) {
  .news-category__tab-item a {
    text-align: center;
    background: #fff;
    padding: 0 10px;
  }
}

.news-category__tab-item a:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100px;
  height: 2px;
  transform: translateX(-50%);
  background: #193b88;
  content: "";
  transition: opacity 300ms;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .news-category__tab-item a:before {
    width: 100%;
    left: 0;
    bottom: -2px;
    transform: translateX(0);
    height: 3px;
    background: #f5f5f5;
  }
}

.news-category__tab-item a:hover {
  color: #193b88;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .news-category__tab-item a:hover {
    color: #000;
  }
}

.news-category__tab-item a:hover:before {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .news-category__tab-item a:hover:before {
    opacity: 0;
  }
}

.news-category__tab-item a.select {
  color: #193b88;
  text-decoration: none;
}

.news-category__tab-item a.select:before {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .news-category__tab-item a.select:before {
    opacity: 0;
  }
}

_:-ms-lang(x), .news-category__tab-item a {
  padding: 11px 30px 0;
}

.news-page-list .news-list {
  border-left: none;
  padding: 0;
  background: transparent;
}

@media screen and (max-width: 768px) {
  .news-page-list .news-list {
    margin-bottom: 20px;
  }
  .news-page-list .news-list:before {
    display: none;
  }
}

.news-page-list .news-list__item {
  display: block !important;
  margin-bottom: 0;
  border-bottom: 1px dashed #193b88;
}

.news-page-list .news-list__link {
  position: relative;
  padding: 19px 24px 19px 0;
}

.news-page-list .news-list__link:before {
  position: absolute;
  top: 50%;
  right: 18px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #193b88;
  border-right: 1px solid #193b88;
  transform: rotate(45deg) translateY(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  .news-page-list .news-list__link:before {
    right: 8px;
  }
}

@media screen and (max-width: 768px) {
  .news-page-list .news-list__link {
    padding: 5px 12px 5px 0;
  }
}

.news-page-list .news-list__date {
  width: 185px;
  font-size: 21px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .news-page-list .news-list__date {
    font-size: 10px;
    width: 80px;
  }
}

.news-page-list .news-list__category {
  position: relative;
  top: 5px;
  float: left;
  width: 133px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .news-page-list .news-list__category {
    top: 0;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 10px;
  }
}

.news-page-list .news-list__ttl {
  font-size: 18px;
  margin-left: 337px;
}

@media screen and (max-width: 768px) {
  .news-page-list .news-list__ttl {
    margin-left: 0;
    font-size: 13px;
  }
}

_:-ms-lang(x), .news-page-list .news-list__category {
  top: 2px;
  padding: 6px 0 0;
  line-height: 1;
}

.news-archive {
  margin-top: -20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .news-archive {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.news-archive__item {
  display: inline-block;
  margin-left: 20px;
}

.news-archive__item:first-child {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .news-archive__item {
    margin-left: 10px;
  }
}

.news-archive__item a {
  position: relative;
  display: block;
  width: 255px;
  padding-left: 45px;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  font-size: 21px;
  border: 1px solid #4d4d4d;
  background: #e6e6e6;
  opacity: 1;
  transition: opacity 300ms;
}

.news-archive__item a:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .news-archive__item a {
    font-size: 12px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
  }
}

.news-archive__item a:before {
  position: absolute;
  top: 20px;
  left: 18px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  transform: rotate(45deg);
  content: "";
}

@media screen and (max-width: 768px) {
  .news-archive__item a:before {
    top: 13px;
    left: 9px;
    width: 4px;
    height: 4px;
  }
}

_:-ms-lang(x), .news-archive__item a {
  line-height: 54px;
}

.news-post-header {
  margin-bottom: 40px;
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .news-post-header {
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.news-post-header__ttl {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  color: #193b88;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  .news-post-header__ttl {
    font-size: 18px;
    line-height: 1.4;
  }
}

.news-post-header__date {
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .news-post-header__date {
    font-size: 12px;
  }
}

.news-post-header__category {
  display: inline-block;
  line-height: 1;
  padding: 4px 25px;
  font-size: 14px;
  color: #fff;
  margin-left: 20px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .news-post-header__category {
    font-size: 10px;
    margin-left: 10px;
    padding: 4px 20px;
  }
}

.news-post-header__category--recruit {
  background: #e155af;
}

.news-post-header__category--topics {
  background: #193b88;
}

.news-post-header__category--message {
  background: #58a200;
}

.news-post-header__category--important {
  background: #f00;
}

.news-post-header__category--other {
  background: #b99600;
}

.news-post-header__category--company {
  background: #005bb2;
}

.news-post-header__category--group {
  background: #193b88;
}

.news-post-inner {
  width: 800px;
  margin: 0 auto 60px;
}

@media screen and (max-width: 768px) {
  .news-post-inner {
    width: 100%;
    margin: 0 0 40px;
  }
}

.news-post-footer {
  width: 800px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 768px) {
  .news-post-footer {
    width: 100%;
    margin: 0 0 40px;
  }
}

.news-post-footer__link {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  font-size: 21px;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .news-post-footer__link {
    font-size: 13px;
    padding-left: 10px;
  }
}

.news-post-footer__link:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 8px;
  height: 8px;
  border-left: 1px solid #193b88;
  border-top: 1px solid #193b88;
  transform: rotate(-45deg) translateY(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  .news-post-footer__link:before {
    width: 6px;
    height: 6px;
  }
}

[data-contents="original"] {
  /*message*/
  /*outline*/
}

[data-contents="original"] .company-catch {
  padding: 120px 0 90px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-catch {
    padding: 40px 0;
  }
}

[data-contents="original"] .company-catch__txt {
  width: 850px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-catch__txt {
    width: 100%;
  }
}

[data-contents="original"] .company-message {
  height: 490px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message {
    height: 200px;
    background: url(../img/company/bg_top_01_sp.png) no-repeat left top;
    background-size: cover;
  }
}

[data-contents="original"] .company-message .container {
  display: table;
  height: 490px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message .container {
    height: auto;
  }
}

[data-contents="original"] .company-message__link {
  position: relative;
  display: block;
  height: 490px;
  overflow: hidden;
}

[data-contents="original"] .company-message__link:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/company/bg_top_01.png) no-repeat left top;
  background-size: cover;
  content: "";
  transition: opacity 300ms;
}

[data-contents="original"] .company-message__link:hover {
  text-decoration: none;
}

[data-contents="original"] .company-message__link:hover:before {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message__link {
    display: block;
    position: relative;
  }
  [data-contents="original"] .company-message__link:before {
    position: absolute;
    right: 0;
    top: 50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid #193b88;
    border-right: 2px solid #193b88;
    transform: rotate(45deg) translateY(-50%);
    content: "";
    z-index: 3;
  }
}

[data-contents="original"] .company-message__txt-wrap {
  position: relative;
  left: 50%;
  padding-top: 80px;
  margin-left: 70px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message__txt-wrap {
    padding-top: 15px;
    margin-left: -50px;
  }
}

[data-contents="original"] .company-message__tag {
  margin-bottom: 20px;
  line-height: 1;
  padding: 8px 10px;
  font-size: 23px;
  background: #193b88;
  color: #fff;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message__tag {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .company-message__ttl {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 47px;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message__ttl {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.4;
  }
}

[data-contents="original"] .company-message__name {
  color: #193b88;
  margin-top: 0;
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message__name {
    font-size: 10px;
  }
}

[data-contents="original"] .company-message__name-inner {
  font-size: 25px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-message__name-inner {
    font-size: 12px;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .company-message__tag {
  padding: 10px 10px 0;
}

[data-contents="original"] .company-links {
  padding: 100px 0 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links {
    padding: 20px 0;
  }
}

[data-contents="original"] .company-links__list {
  margin-bottom: 50px;
}

[data-contents="original"] .company-links__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__list {
    margin-bottom: 0;
  }
}

[data-contents="original"] .company-links__item {
  float: left;
  width: 360px;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__item {
    width: 100%;
    float: none;
    background: #fff;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .company-links__item:first-child .company-links__txt-wrap:before {
  display: none;
}

[data-contents="original"] .company-links__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__link {
    display: block;
    position: relative;
    padding-right: 10px;
  }
  [data-contents="original"] .company-links__link:before {
    position: absolute;
    right: 15px;
    top: 50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid #193b88;
    border-right: 2px solid #193b88;
    transform: rotate(45deg) translateY(-50%);
    content: "";
    z-index: 3;
  }
}

[data-contents="original"] .company-links__link img {
  transition: transform 300ms;
}

[data-contents="original"] .company-links__link:hover {
  text-decoration: none;
}

[data-contents="original"] .company-links__link:hover img {
  transform: scale(1.1);
}

[data-contents="original"] .company-links__link:hover .company-links__ttl {
  text-decoration: underline;
}

[data-contents="original"] .company-links__img-wrap {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__img-wrap {
    float: left;
    width: 120px;
    margin: 10px 0 0 10px;
  }
}

[data-contents="original"] .company-links__txt-wrap {
  position: relative;
  background: #fff;
  padding: 30px 30px 0;
  min-height: 195px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__txt-wrap {
    padding: 10px 10px 10px 0;
    margin-left: 140px;
    min-height: inherit;
    min-height: 110px;
  }
}

[data-contents="original"] .company-links__txt-wrap:before {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 3px;
  height: 175px;
  background: url(../img/company/line_01.png) no-repeat left top;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__txt-wrap:before {
    display: none;
  }
}

[data-contents="original"] .company-links__ttl {
  position: relative;
  display: inline-block;
  font-size: 24px;
  line-height: 1.4;
  color: #193b88;
  margin-bottom: 10px;
}

[data-contents="original"] .company-links__ttl--ls {
  margin-bottom: 5px;
}

[data-contents="original"] .company-links__ttl--ls + .company-links__txt {
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__ttl {
    display: block;
    font-weight: bold;
    font-size: 16px;
  }
}

[data-contents="original"] .company-links__ttl:before {
  position: absolute;
  right: -30px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-top: 2px solid #193b88;
  border-right: 2px solid #193b88;
  transform: rotate(45deg) translateY(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__ttl:before {
    display: none;
  }
}

[data-contents="original"] .company-links__txt {
  margin: 0;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-links__txt {
    font-size: 12px;
    line-height: 1.4;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .company-links__ttl:before {
  margin-top: -7px;
}

[data-contents="original"] .top-message {
  padding: 30px 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .top-message {
    padding: 10px 0 30px;
  }
}

[data-contents="original"] .top-message__img {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .top-message__img {
    margin-bottom: 10px;
  }
}

[data-contents="original"] .top-message__txt {
  width: 800px;
  margin: 0 auto 50px;
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 37px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .top-message__txt {
    font-size: 13px;
    line-height: 1.7;
    width: auto;
    margin: 0 auto 20px;
  }
}

[data-contents="original"] .top-message__name {
  width: 800px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .top-message__name {
    text-align: right;
    width: auto;
    font-size: 13px;
  }
}

[data-contents="original"] .top-message__name-inner {
  display: block;
  font-size: 23px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .top-message__name-inner {
    font-size: 15px;
  }
}

[data-contents="original"] .company-dtl dt {
  position: relative;
  padding-left: 20px;
  font-size: 22px;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: #193b88;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl dt {
    margin-bottom: 0;
    font-size: 15px;
    padding-left: 15px;
  }
}

[data-contents="original"] .company-dtl dt:before {
  position: absolute;
  top: 14px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #193b88;
  border-radius: 50%;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl dt:before {
    top: 11px;
    width: 7px;
    height: 7px;
  }
}

[data-contents="original"] .company-dtl dd {
  font-size: 21px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl dd {
    font-size: 13px;
  }
}

[data-contents="original"] .company-dtl dd + dt {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl dd + dt {
    margin-top: 10px;
  }
}

[data-contents="original"] .company-dtl--line:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .company-dtl--line dt {
  float: left;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl--line dt {
    float: none;
  }
}

[data-contents="original"] .company-dtl--line dd {
  padding-top: 2px;
  margin-bottom: 10px;
  margin-left: 195px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl--line dd {
    margin-left: 0;
    padding-top: 0;
    margin-bottom: 0;
  }
}

[data-contents="original"] .company-dtl--line dd + dt {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl--line dd + dt {
    margin-top: 10px;
  }
}

[data-contents="original"] .company-dtl--recruit dd {
  margin-left: 210px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl--recruit dd {
    margin-left: 0;
  }
}

[data-contents="original"] .company-dtl__map {
  font-size: 17px;
  display: inline-block;
  background: #193b88;
  color: #fff;
  line-height: 1;
  padding: 2px 8px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: 1;
  transition: opacity 300ms;
}

[data-contents="original"] .company-dtl__map:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__map {
    font-size: 13px;
    padding: 4px 10px;
  }
}

[data-contents="original"] .company-dtl__note {
  display: block;
  margin-left: 1em;
  text-indent: -1em;
  font-size: 19px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__note {
    font-size: 12px;
  }
}

[data-contents="original"] .company-dtl__sub-ttl {
  margin: 30px 0 10px;
  display: inline-block;
  padding-left: 10px;
  width: 420px;
  box-sizing: border-box;
  background: #193b88;
  color: #fff;
  font-size: 19px;
  border-left: 13px solid #b3b3b3;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__sub-ttl {
    margin: 10px 0 10px;
    width: 100%;
    font-size: 14px;
  }
}

[data-contents="original"] .company-dtl__sub-ttl:first-child {
  margin: 0 0 10px;
}

[data-contents="original"] .company-dtl__sub-txt {
  padding: 0 20px;
  font-size: 19px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__sub-txt {
    font-size: 13px;
    padding: 0 15px;
  }
}

[data-contents="original"] .company-dtl__sub-txt + .company-dtl__sub-list {
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__sub-txt + .company-dtl__sub-list {
    margin-top: 10px;
  }
}

[data-contents="original"] .company-dtl__sub-list {
  position: relative;
  color: #193b88;
  font-weight: bold;
  font-size: 22px;
  padding-left: 20px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__sub-list {
    font-size: 14px;
    padding-left: 15px;
  }
}

[data-contents="original"] .company-dtl__sub-list:before {
  position: absolute;
  top: 14px;
  left: 0;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  border: 3px solid #193b88;
  border-radius: 50%;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl__sub-list:before {
    top: 7px;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .company-dtl__sub-ttl {
  padding-top: 6px;
}

[data-contents="original"] .company-work-txt {
  width: 950px;
  margin: 0 auto;
  font-size: 21px;
  line-height: 36px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-work-txt {
    width: auto;
    font-size: 13px;
    line-height: 1.6;
  }
}

[data-contents="original"] .company-dtl-block {
  width: 988px;
  margin: 40px auto 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl-block {
    width: auto;
    margin: 20px 0 0;
  }
}

[data-contents="original"] .company-dtl-block__ttl {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  font-weight: bold;
  color: #193b88;
  font-size: 21px;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl-block__ttl {
    font-size: 15px;
  }
}

[data-contents="original"] .company-dtl-block__txt {
  background: #dcdcdc;
  color: #193b88;
  padding: 10px 20px;
  font-size: 20px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-dtl-block__txt {
    font-size: 13px;
    padding: 10px 10px;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .company-dtl__map {
  padding: 8px 8px 0;
}

[data-contents="original"] .company-mark-block {
  padding: 0 135px 0 0;
}

[data-contents="original"] .company-mark-block:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-mark-block {
    padding: 0;
  }
}

[data-contents="original"] .company-mark-block__img {
  float: right;
  margin: 0 0 10px 10px;
}

[data-contents="original"] .company-mark-block__txt {
  margin-bottom: 60px;
  margin-right: 265px;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-indent: 1em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-mark-block__txt {
    margin: 0;
    font-size: 13px;
  }
}

[data-contents="original"] .company-mark-block__txt + .company-mark-block__txt {
  margin-top: -60px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-mark-block__txt + .company-mark-block__txt {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

[data-contents="original"] .company-group:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .company-group__logo {
  float: left;
  width: 197px;
  border: 1px solid #d0d0d0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-group__logo {
    float: none;
    margin: 0 auto 10px;
  }
}

[data-contents="original"] .company-group__logo-link {
  display: block;
  opacity: 1;
  transition: opacity 300ms;
}

[data-contents="original"] .company-group__logo-link:hover {
  opacity: .7;
  text-decoration: none;
}

[data-contents="original"] .company-group .company-dtl {
  margin-left: 240px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-group .company-dtl {
    margin-left: 0;
  }
}

[data-contents="original"] .company-tsuyomi {
  width: 950px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 25px;
}

[data-contents="original"] .company-tsuyomi:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi {
    width: auto;
    padding: 0;
  }
}

[data-contents="original"] .company-tsuyomi__item {
  position: relative;
  width: 50%;
  float: left;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi__item {
    width: 100%;
    left: 0 !important;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .company-tsuyomi__item:nth-child(odd) {
  left: -25px;
  clear: both;
}

[data-contents="original"] .company-tsuyomi__item:nth-child(even) {
  left: 25px;
}

[data-contents="original"] .company-tsuyomi__txt-wrap {
  background: #fff;
  height: 195px;
  box-sizing: border-box;
  padding: 30px 45px 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi__txt-wrap {
    padding: 10px;
    height: auto;
  }
}

[data-contents="original"] .company-tsuyomi img {
  transition: transform 300ms;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi img {
    width: 120px;
    margin: 10px 10px 0;
  }
}

[data-contents="original"] .company-tsuyomi__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .company-tsuyomi__link:hover {
  text-decoration: none;
}

[data-contents="original"] .company-tsuyomi__link:hover img {
  transform: scale(1.1);
}

[data-contents="original"] .company-tsuyomi__link:hover .company-tsuyomi__ttl {
  text-decoration: underline;
}

[data-contents="original"] .company-tsuyomi__img-wrap {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi__img-wrap {
    float: left;
  }
}

[data-contents="original"] .company-tsuyomi__ttl {
  position: relative;
  display: inline-block;
  color: #193b88;
  background: none;
  padding: 0 35px 0 0;
  border: none;
  box-shadow: none;
  margin: 0 0 10px;
  font-size: 24px;
  letter-spacing: 0.06em;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi__ttl {
    display: block;
    margin-left: 130px;
    font-size: 16px;
    padding-top: 10px;
    font-weight: bold;
  }
}

[data-contents="original"] .company-tsuyomi__ttl:before {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #193b88;
  border-right: 1px solid #193b88;
  content: "";
  transform: rotate(45deg) translateY(-50%);
}

[data-contents="original"] .company-tsuyomi__txt {
  font-size: 15px;
  line-height: 25px;
  margin: 0;
  letter-spacing: 0.08em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .company-tsuyomi__txt {
    font-size: 13px;
    line-height: 1.6;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .company-tsuyomi__ttl:before {
  margin-top: -7px;
}

[data-contents="original"] .column-txt {
  font-size: 18px;
  margin-bottom: 50px;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .column-txt {
    font-size: 13px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}

[data-contents="original"] .column-img {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .column-img {
    margin-bottom: 20px;
  }
}

/* ------------------------------------------------
  page
---------------------------------------------- */
.mv {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 500px;
  padding-top: 250px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .mv {
    height: 150px;
    padding-top: 50px;
  }
}

.mv--company {
  background-image: url(../img/company/mv_top_01.jpg);
}

.mv__ttl {
  margin-bottom: 10px;
  font-size: 65px;
  letter-spacing: 0.06em;
  font-weight: bold;
  color: #fff;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .mv__ttl {
    margin-bottom: 5px;
    font-size: 28px;
  }
}

.mv__sub {
  font-size: 27px;
  letter-spacing: 0.2em;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .mv__sub {
    font-size: 12px;
    font-weight: bold;
  }
}

.head {
  border-bottom: 1px solid #193b88;
  padding: 50px 0 40px;
  border-top: solid 194px #193b88;
  background: #fff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .head {
    border-top: none;
    padding: 20px 0;
  }
}

.head__ttl {
  font-size: 65px;
  letter-spacing: 0.06em;
  font-weight: bold;
  color: #193b88;
  line-height: 1;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .head__ttl {
    margin-bottom: 5px;
    font-size: 28px;
  }
}

.head__sub {
  font-size: 27px;
  letter-spacing: 0.2em;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .head__sub {
    font-size: 12px;
    font-weight: bold;
  }
}

.page-block {
  padding: 40px 0;
}

@media screen and (max-width: 768px) {
  .page-block {
    padding: 10px 0 20px;
  }
}

.page-block__ttl {
  font-weight: bold;
  color: #193b88;
  margin-bottom: 40px;
  font-size: 30px;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  .page-block__ttl {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.page-block__ttl--center {
  text-align: center;
  font-size: 36px;
}

@media screen and (max-width: 768px) {
  .page-block__ttl--center {
    font-size: 18px;
  }
}

.page-block__line-ttl {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 5px solid #b4b4b4;
  font-size: 30px;
  font-weight: bold;
  color: #193b88;
  letter-spacing: 0.06em;
  margin: 0 0 40px;
  padding: 0 0 10px;
  text-align: left;
  overflow: inherit;
}

@media screen and (max-width: 768px) {
  .page-block__line-ttl {
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    border-bottom: 3px solid #b4b4b4;
  }
}

.page-block__line-ttl:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 150px;
  height: 5px;
  background: #193b88;
  content: "";
}

@media screen and (max-width: 768px) {
  .page-block__line-ttl:before {
    height: 3px;
    bottom: -3px;
  }
}

.page-block__line-ttl:after {
  display: none;
}

.page-block__line-ttl--min {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .page-block__line-ttl--min {
    font-size: 18px;
  }
}

.page-block__line-ttl a {
  color: #193b88;
}

.page-block--mb {
  margin-bottom: 50px;
}

[data-contents="original"] .page-block {
  padding: 40px 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block {
    padding: 10px 0 20px;
  }
}

[data-contents="original"] .page-block__ttl {
  font-weight: bold;
  color: #193b88;
  padding: 0;
  margin: 0 0 40px;
  font-size: 30px;
  text-align: left;
  overflow: inherit !important;
}

[data-contents="original"] .page-block__ttl:after {
  display: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__ttl {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

[data-contents="original"] .page-block__line-ttl {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 5px solid #b4b4b4;
  font-size: 30px;
  font-weight: bold;
  color: #193b88;
  letter-spacing: 0.06em;
  margin: 0 0 40px;
  padding: 0 0 10px;
  text-align: left;
  background: transparent;
  overflow: inherit;
  box-shadow: none;
}

[data-contents="original"] .page-block__line-ttl span {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__line-ttl {
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    border-bottom: 3px solid #b4b4b4;
  }
}

[data-contents="original"] .page-block__line-ttl:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 150px;
  height: 5px;
  background: #193b88;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__line-ttl:before {
    height: 3px;
    bottom: -3px;
  }
}

[data-contents="original"] .page-block__line-ttl:after {
  display: none;
}

[data-contents="original"] .page-block__line-ttl--min {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__line-ttl--min {
    font-size: 18px;
  }
}

[data-contents="original"] .page-block__inner {
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__inner {
    padding: 0;
  }
}

[data-contents="original"] .page-block__inner--mb {
  margin-bottom: 50px;
}

[data-contents="original"] .page-block__txt {
  margin: 0 0 30px;
  font-size: 21px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__txt {
    margin: 0 0 20px;
    font-size: 13px;
  }
}

[data-contents="original"] .page-block__txt--center {
  text-align: center;
  font-size: 27px;
  color: #193b88;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__txt--center {
    font-size: 13px;
  }
}

[data-contents="original"] .page-block__img {
  margin: 0 auto 30px;
}

[data-contents="original"] .page-block__img--kigyorinen {
  width: 630px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__img--kigyorinen {
    width: auto;
  }
}

[data-contents="original"] .page-block__img--left {
  margin: 0 auto 50px;
}

[data-contents="original"] .page-block__img--left img {
  margin: 0 auto 0 0;
}

[data-contents="original"] .page-block__img-txt:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .page-block__img-txt .page-block__img {
  float: right;
  margin: 0 0 20px 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__img-txt .page-block__img {
    float: none;
    margin: 0 auto 10px;
  }
}

[data-contents="original"] .page-block__img-txt .page-block__txt {
  margin: 0 0 30px;
}

[data-contents="original"] .page-block__img-half:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .page-block__img-half .page-block__img {
  float: left;
  margin-left: 10px;
}

[data-contents="original"] .page-block .business-check-box {
  width: 690px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block .business-check-box {
    width: 100%;
    margin-top: 20px;
  }
}

[data-contents="original"] .page-block__thumb-txt:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .page-block__thumb-txt .page-block__img {
  float: left;
  margin: 0 0 20px 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__thumb-txt .page-block__img {
    float: none;
    margin: 0 auto 10px;
  }
}

[data-contents="original"] .page-block__thumb-txt .page-block__txt-wrap {
  margin-left: 330px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block__thumb-txt .page-block__txt-wrap {
    margin-left: 0;
  }
}

[data-contents="original"] .page-block--arrow {
  position: relative;
  margin: 0 auto 140px;
  padding: 0 0 15px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block--arrow {
    margin: 0 0 80px;
  }
}

[data-contents="original"] .page-block--arrow:before {
  position: absolute;
  bottom: -60px;
  left: 50%;
  width: 50px;
  height: 50px;
  border-right: 1px solid #193b88;
  border-top: 1px solid #193b88;
  content: "";
  margin-left: -25px;
  transform: rotate(135deg);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-block--arrow:before {
    bottom: -40px;
  }
}

[data-contents="original"] .page-block--mb {
  margin-bottom: 50px;
}

[data-contents="original"] .page-contents-ttl {
  font-size: 30px;
  color: #0064aa;
  margin: 0 0 30px;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: left;
  font-weight: bold;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-ttl {
    font-size: 18px;
    margin: 0 0 20px;
  }
}

[data-contents="original"] .page-contents-ttl:after {
  display: none;
}

[data-contents="original"] .page-contents-ttl--mb0 {
  margin-bottom: 0;
}

[data-contents="original"] .page-contents-head {
  margin-bottom: 100px;
  display: block;
  background: #193b88;
  padding: 5px;
  text-align: center;
}

[data-contents="original"] .page-contents-head:hover {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-head {
    margin-bottom: 40px;
  }
}

[data-contents="original"] .page-contents-head__inner {
  border: 2px solid #fff;
  padding: 20px 0 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-head__inner {
    padding: 10px 0;
  }
}

[data-contents="original"] .page-contents-head__sub {
  display: block;
  margin: 0 0 5px;
  font-size: 21px;
  color: #fff;
  letter-spacing: 0.1em;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-head__sub {
    font-size: 10px;
  }
}

[data-contents="original"] .page-contents-head__ttl {
  margin: 0;
  font-size: 34px;
  letter-spacing: 0.06em;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 0;
  overflow: inherit !important;
}

[data-contents="original"] .page-contents-head__ttl span {
  font-size: 30px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-head__ttl span {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-head__ttl {
    font-size: 13px;
  }
}

[data-contents="original"] .page-contents-head .recruit-bnr-list {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-contents-head .recruit-bnr-list {
    margin-top: 10px;
    padding: 0 10px;
  }
}

[data-contents="original"] .page-form-info {
  margin-bottom: 100px;
  display: block;
  background: #193b88;
  padding: 5px;
  text-align: center;
}

[data-contents="original"] .page-form-info:hover {
  text-decoration: none;
}

[data-contents="original"] .page-form-info:hover .page-form-info__ttl {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-form-info {
    margin-bottom: 40px;
  }
}

[data-contents="original"] .page-form-info__inner {
  border: 2px solid #fff;
  padding: 20px 0 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-form-info__inner {
    padding: 10px 0;
  }
}

[data-contents="original"] .page-form-info__ttl {
  margin: 0;
  font-size: 34px;
  letter-spacing: 0.06em;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 0;
  transition: transform 300ms;
  overflow: inherit !important;
}

[data-contents="original"] .page-form-info__ttl span {
  font-size: 30px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-form-info__ttl span {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-form-info__ttl {
    font-size: 13px;
  }
}

[data-contents="original"] .page-bg-box {
  position: relative;
  background: #f0f0f0;
  border: 1px solid #193b88;
  margin: 0 auto 50px;
  width: 1027px;
  padding: 25px 30px;
  box-sizing: border-box;
}

[data-contents="original"] .page-bg-box:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box {
    width: 100%;
    padding: 15px;
  }
}

[data-contents="original"] .page-bg-box--flow:before {
  position: absolute;
  bottom: -30px;
  right: 130px;
  width: 14px;
  height: 14px;
  border-top: 2px solid #193b88;
  border-right: 2px solid #193b88;
  content: "";
  transform: rotate(135deg);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box--flow:before {
    right: inherit;
    left: 50%;
    margin-left: -14px;
  }
}

[data-contents="original"] .page-bg-box__ttl {
  position: relative;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.06em;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0 0 0 20px;
  margin: 0 0 10px;
  color: #193b88;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box__ttl {
    font-size: 14px;
  }
}

[data-contents="original"] .page-bg-box__ttl:before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #193b88;
  content: "";
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .page-bg-box--flow:before {
  margin-top: -6px;
}

[data-contents="original"] .page-jump-link {
  margin-left: -20px;
}

[data-contents="original"] .page-jump-link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .page-jump-link--2 .page-jump-link__item {
  width: 530px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-jump-link--2 .page-jump-link__item {
    width: 100%;
    left: 0 !important;
  }
}

[data-contents="original"] .page-jump-link--3 .page-jump-link__item {
  width: 345px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-jump-link--3 .page-jump-link__item {
    width: 100%;
    left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-jump-link {
    margin-left: 0;
    padding: 0 5px;
  }
}

[data-contents="original"] .page-jump-link__item {
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
  list-style: none;
  width: 255px;
  box-sizing: border-box;
  font-size: 21px;
  text-align: center;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-jump-link__item {
    position: relative;
    width: 50%;
    margin-left: 0;
    margin-bottom: 10px;
    font-size: 13px;
  }
  [data-contents="original"] .page-jump-link__item:nth-child(odd) {
    left: -5px;
  }
  [data-contents="original"] .page-jump-link__item:nth-child(even) {
    left: 5px;
  }
}

[data-contents="original"] .page-jump-link__item-link {
  background: #f5f5f5;
  border: 1px solid #193b88;
  display: block;
  padding: 5px 0;
  color: #193b88;
  opacity: 1;
  transition: opacity 300ms;
}

[data-contents="original"] .page-jump-link__item-link:hover {
  opacity: .7;
  text-decoration: none;
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .page-jump-link__item-link {
  padding: 11px 0 0;
}

[data-contents="original"] .page-top-wrap {
  text-align: right;
  margin-bottom: 40px;
}

[data-contents="original"] .page-top-wrap .page-top {
  position: relative;
  display: inline-block;
  color: #193b88;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-top-wrap .page-top {
    font-size: 13px;
  }
}

[data-contents="original"] .page-top-wrap .page-top:before {
  position: absolute;
  top: 50%;
  width: 15px;
  height: 15px;
  background: #193b88;
  left: -30px;
  transform: translateY(-50%);
  content: "";
}

[data-contents="original"] .page-top-wrap .page-top:after {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  left: -24px;
  transform: rotate(-45deg) translateY(-50%);
  content: "";
}

[data-contents="original"] .page-top-txt {
  margin: 60px 0 40px !important;
  font-size: 26px;
  color: #193b88;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-top-txt {
    margin: 20px 0 20px !important;
    font-size: 15px;
  }
}

[data-contents="original"] .privacy-sub-ttl {
  color: #193b88;
  font-size: 19px;
  font-weight: bold;
  border: none;
  margin: 0;
  box-shadow: none;
  padding: 0;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .privacy-sub-ttl {
    font-size: 15px;
  }
}

[data-contents="original"] .privacy-txt {
  font-size: 19px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .privacy-txt {
    margin: 0 0 20px;
    font-size: 13px;
  }
}

[data-contents="original"] .privacy-txt li {
  list-style: none;
}

[data-contents="original"] .privacy-txt-ttl {
  color: #193b88;
  font-weight: bold;
  display: block;
}

[data-contents="original"] .privacy-txt + .privacy-sub-ttl {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .privacy-txt + .privacy-sub-ttl {
    margin-top: 20px;
  }
}

[data-contents="original"] .privacy-abc-list {
  font-size: 19px;
  margin: 0 0 0 2em;
  text-indent: -2em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .privacy-abc-list {
    margin: 0 0 20px;
    font-size: 13px;
  }
}

[data-contents="original"] .privacy-abc-list li {
  list-style: none;
  overflow: inherit;
}

[data-contents="original"] .privacy-btm-txt {
  padding-bottom: 60px;
  font-weight: bold;
  text-align: right;
}

[data-contents="original"] .security-number-list {
  margin-top: 40px;
  font-size: 19px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .security-number-list {
    margin: 0 0 20px;
    font-size: 13px;
  }
}

[data-contents="original"] .security-number-list li {
  margin: 0 0 10px 1em;
  text-indent: -1em;
  list-style: none;
  overflow: inherit;
  line-height: 1.94;
}

[data-contents="original"] .security-list {
  margin-top: 40px;
  margin-left: 40px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .security-list {
    margin-left: 0;
    margin-top: 20px;
  }
}

[data-contents="original"] .security-list li {
  text-indent: -40px;
  line-height: 1.9;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .security-list li {
    text-indent: -1em;
    margin-left: 1em;
  }
}

[data-contents="original"] .security-list li:first-letter {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .security-list li:first-letter {
    margin-right: 0;
  }
}

[data-contents="original"] .privacy-center-ttl {
  margin-bottom: 90px;
  margin-top: 40px;
  text-align: center !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .privacy-center-ttl {
    margin-bottom: 60px;
  }
}

[data-contents="original"] .sitemap-wrap {
  margin-top: 60px;
  padding-bottom: 60px;
}

[data-contents="original"] .sitemap-wrap:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-wrap {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

[data-contents="original"] .sitemap-block {
  margin-left: 286px;
}

[data-contents="original"] .sitemap-block:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-block {
    margin-left: 0;
  }
}

[data-contents="original"] .sitemap-block--career {
  position: relative;
  margin-left: 510px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-block--career {
    margin-left: 0;
  }
}

[data-contents="original"] .sitemap-item-wrap {
  position: relative;
  float: left;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item-wrap {
    float: none;
  }
}

[data-contents="original"] .sitemap-item-wrap:before, [data-contents="original"] .sitemap-item-wrap:after {
  position: absolute;
  content: "";
  background: #193b88;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item-wrap:before, [data-contents="original"] .sitemap-item-wrap:after {
    display: none;
  }
}

[data-contents="original"] .sitemap-item-wrap--line:before {
  top: 30px;
  left: 30px;
  width: 2px;
}

[data-contents="original"] .sitemap-item-wrap--company:before {
  height: 482px;
}

[data-contents="original"] .sitemap-item-wrap--business:before {
  height: 402px;
}

[data-contents="original"] .sitemap-item-wrap--challenge:before {
  height: 162px;
}

[data-contents="original"] .sitemap-item-wrap--recruit:before {
  height: 242px;
}

[data-contents="original"] .sitemap-item-wrap--recruit:after {
  top: 30px;
  left: 0;
  width: 35px;
  height: 2px;
}

[data-contents="original"] .sitemap-item-wrap--recruit-info:before {
  height: 322px;
  top: 30px;
  left: 34px;
  width: 2px;
}

[data-contents="original"] .sitemap-item {
  position: relative;
  width: 220px;
  height: 60px;
  box-sizing: border-box;
  border: 2px solid #193b88;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item {
    width: auto;
    height: auto;
    border: none;
    text-align: left;
  }
}

[data-contents="original"] .sitemap-item:before, [data-contents="original"] .sitemap-item:after {
  position: absolute;
  content: "";
  background: #193b88;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item:before, [data-contents="original"] .sitemap-item:after {
    display: none;
  }
}

[data-contents="original"] .sitemap-item:before {
  position: absolute;
  content: "";
  background: #193b88;
  z-index: 2;
}

[data-contents="original"] .sitemap-item a {
  height: 56px;
  display: block;
  background: #fff;
  color: #193b88;
  font-size: 22px;
  line-height: 56px;
  font-weight: bold;
  transition: all 300ms;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item a {
    font-size: 13px;
    line-height: 1.3;
    height: auto;
    position: relative;
    padding: 5px 20px;
    border: 1px solid #193b88;
  }
}

[data-contents="original"] .sitemap-item a:hover {
  background: #193b88;
  color: #fff;
  text-decoration: none;
}

[data-contents="original"] .sitemap-item .small {
  font-size: 18px;
  line-height: 1.3;
  padding-top: 5px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item .small {
    font-size: 13px;
  }
}

[data-contents="original"] .sitemap-item .small-line {
  font-size: 18px;
  line-height: 56px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item .small-line {
    font-size: 13px;
    line-height: 1.3;
  }
}

[data-contents="original"] .sitemap-item--1 {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item--1 {
    position: relative;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .sitemap-item--1:before {
  top: 28px;
  right: -35px;
  width: 35px;
  height: 2px;
}

[data-contents="original"] .sitemap-item--1:after {
  top: 28px;
  right: -35px;
  width: 2px;
  height: 1282px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item--2 {
    margin-left: 30px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  [data-contents="original"] .sitemap-item--2 a:before {
    position: absolute;
    top: 50%;
    left: -10px;
    width: 10px;
    height: 2px;
    background: #193b88;
    content: "";
  }
}

[data-contents="original"] .sitemap-item--2:before {
  top: 28px;
  left: -35px;
  width: 35px;
  height: 2px;
}

[data-contents="original"] .sitemap-item--2:after {
  top: 28px;
  right: -37px;
  width: 35px;
  height: 2px;
}

[data-contents="original"] .sitemap-item--3 {
  margin-left: 66px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item--3 {
    margin-left: 60px;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .sitemap-item--3:before {
  top: 28px;
  left: -37px;
  width: 35px;
  height: 2px;
}

[data-contents="original"] .sitemap-item--4 {
  margin-left: 66px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .sitemap-item--4 {
    margin-left: 90px;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .sitemap-item--4:before {
  top: 28px;
  left: -37px;
  width: 35px;
  height: 2px;
}

.pager {
  text-align: center;
}

.pager a {
  display: block;
  background: #fff;
  color: #193b88;
  transition: all 300ms;
  height: 31px;
  line-height: 31px;
}

@media screen and (max-width: 768px) {
  .pager a {
    height: 28px;
    line-height: 28px;
  }
}

.pager a:hover {
  background: #193b88;
  color: #fff;
  text-decoration: none;
}

.pager__item {
  display: inline-block;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  font-size: 20px;
  border: 2px solid #193b88;
  vertical-align: middle;
  margin-left: 5px;
  font-weight: bold;
  position: relative;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .pager__item {
    font-size: 12px;
    width: 30px;
    height: 30px;
    border: 1px solid #193b88;
  }
}

.pager__item--current a {
  background: #193b88;
  color: #fff;
}

.pager__item--prev a {
  background: #ebebff;
}

.pager__item--prev:before {
  position: absolute;
  top: 10px;
  left: 13px;
  width: 9px;
  height: 9px;
  border-top: 2px solid #193b88;
  border-left: 2px solid #193b88;
  transform: rotate(-45deg);
  content: "";
}

@media screen and (max-width: 768px) {
  .pager__item--prev:before {
    width: 6px;
    height: 6px;
  }
}

.pager__item--prev:after {
  position: absolute;
  bottom: -14px;
  width: 100%;
  left: 0;
  text-align: center;
  content: "PREV";
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #193b88;
  white-space: nowrap;
}

.pager__item--next a {
  background: #ebebff;
}

.pager__item--next:before {
  position: absolute;
  top: 10px;
  right: 13px;
  width: 9px;
  height: 9px;
  border-top: 2px solid #193b88;
  border-right: 2px solid #193b88;
  transform: rotate(45deg);
  content: "";
}

@media screen and (max-width: 768px) {
  .pager__item--next:before {
    width: 6px;
    height: 6px;
  }
}

.pager__item--next:after {
  position: absolute;
  bottom: -14px;
  width: 100%;
  left: 0;
  text-align: center;
  content: "NEXT";
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #193b88;
  white-space: nowrap;
}

[data-bgb] {
  margin: 0 !important;
}

/* ------------------------------------------------
  business
---------------------------------------------- */
[data-contents="original"] .business-top {
  position: relative;
  padding: 0 0 40px 50%;
  background: #193b88;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top {
    padding: 15px 0;
  }
}

[data-contents="original"] .business-top--01 {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top--01 {
    margin-bottom: 20px;
  }
}

[data-contents="original"] .business-top--01:before {
  background-image: url(../img/business/bg_top_01.png);
}

[data-contents="original"] .business-top--02 {
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top--02 {
    margin-bottom: 20px;
  }
}

[data-contents="original"] .business-top--02:before {
  background-image: url(../img/business/bg_top_02.png);
}

[data-contents="original"] .business-top--03 {
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top--03 {
    margin-bottom: 20px;
  }
}

[data-contents="original"] .business-top--03:before {
  background-image: url(../img/challenge/bg_top_01.jpg);
}

[data-contents="original"] .business-top-wrap {
  padding-top: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top-wrap {
    padding-top: 40px;
  }
}

[data-contents="original"] .business-top:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  content: "";
  z-index: 2;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top:before {
    top: 100%;
    width: 100%;
    height: 30px;
  }
}

[data-contents="original"] .business-top__ttl {
  font-weight: bold;
  color: #fff;
  padding: 40px 0 0 40px;
  margin: 0 0 10px 0;
  text-align: left;
  font-size: 40px;
  letter-spacing: 0.22em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top__ttl {
    font-size: 18px;
    padding: 0;
    letter-spacing: 1;
  }
}

[data-contents="original"] .business-top__ttl:after {
  display: none;
}

[data-contents="original"] .business-top__txt {
  color: #fff;
  padding-left: 40px;
  font-size: 18px;
  letter-spacing: 0.14em;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top__txt {
    padding-left: 0;
    font-size: 13px;
  }
}

[data-contents="original"] .business-top__catch {
  display: inline-block;
  position: relative;
  color: #fff;
  padding-left: 40px;
  font-size: 26px;
  letter-spacing: 0.14em;
  margin: 0;
}

[data-contents="original"] .business-top__catch:after {
  position: absolute;
  top: 50%;
  right: -60px;
  width: 50px;
  height: 1px;
  background: #fff;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-top__catch {
    padding-left: 0;
    font-size: 13px;
  }
}

[data-contents="original"] .business-nayami {
  width: 1027px;
  margin: 0 auto 70px;
  padding: 35px 0 15px;
  border: 1px solid #193b88;
  background: #f0f0f0;
  box-sizing: border-box;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami {
    width: 100%;
    margin: 0 0 40px;
    box-sizing: border-box;
    padding: 10px 0;
  }
}

[data-contents="original"] .business-nayami__list {
  text-align: center;
  margin: 0;
}

[data-contents="original"] .business-nayami__item {
  display: inline-block;
  list-style: none;
  margin: 0;
  width: 180px;
  height: 180px;
  background: #0064aa;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami__item {
    width: 105px;
    height: 105px;
    margin: 0 5px 5px;
  }
}

[data-contents="original"] .business-nayami__inner {
  display: table;
  width: 180px;
  height: 180px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami__inner {
    width: 105px;
    height: 105px;
  }
}

[data-contents="original"] .business-nayami__txt {
  display: table-cell;
  vertical-align: middle;
  color: #c8dcdc;
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami__txt {
    font-size: 10px;
  }
}

[data-contents="original"] .business-nayami__txt strong {
  font-weight: bold;
  font-size: 21px;
  color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami__txt strong {
    font-size: 13px;
  }
}

[data-contents="original"] .business-nayami__txt strong.min {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami__txt strong.min {
    font-size: 10px;
  }
}

[data-contents="original"] .business-nayami__ttl {
  font-size: 20px;
  text-align: center;
  color: #193b88;
  margin: 0;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-nayami__ttl {
    font-size: 15px;
  }
}

[data-contents="original"] .business-flow {
  margin-bottom: 90px;
}

[data-contents="original"] .business-flow:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow {
    margin-bottom: 20px;
  }
}

[data-contents="original"] .business-flow__item {
  position: relative;
  list-style: none;
  float: left;
  width: 320px;
  margin-left: 30px;
  overflow: inherit;
}

[data-contents="original"] .business-flow__item:first-child {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow__item {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

[data-contents="original"] .business-flow__item:before {
  position: absolute;
  top: 80px;
  right: -22px;
  width: 14px;
  height: 14px;
  border-top: 2px solid #193b88;
  border-right: 2px solid #193b88;
  content: "";
  transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow__item:before {
    top: 100%;
    right: inherit;
    left: 50%;
    transform: rotate(135deg);
    margin-left: -14px;
  }
}

[data-contents="original"] .business-flow__item:last-child:before {
  display: none;
}

[data-contents="original"] .business-flow__ttl-wrap {
  display: table;
  width: 320px;
  margin-bottom: 10px;
  height: 180px;
  background: #0064aa;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow__ttl-wrap {
    width: 100%;
    height: auto;
    padding: 10px 0;
  }
}

[data-contents="original"] .business-flow__ttl {
  border: none;
  padding: 0;
  color: #fff;
  display: table-cell;
  background: transparent;
  box-shadow: none;
  border: none;
  vertical-align: middle;
  font-size: 30px;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow__ttl {
    font-size: 15px;
  }
}

[data-contents="original"] .business-flow__txt {
  font-size: 18px;
  letter-spacing: 0.1em;
  margin: 0;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow__txt {
    font-size: 13px;
  }
}

[data-contents="original"] .business-flow--reverse .business-flow__item {
  float: right;
  margin-right: 60px;
  margin-left: 0 !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow--reverse .business-flow__item {
    margin-right: 0;
    float: none;
  }
}

[data-contents="original"] .business-flow--reverse .business-flow__item:first-child {
  margin-left: 0 !important;
  margin-right: 0;
}

[data-contents="original"] .business-flow--reverse .business-flow__item:before {
  right: inherit;
  left: -35px;
  transform: rotate(-135deg);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-flow--reverse .business-flow__item:before {
    left: 50%;
    transform: rotate(135deg);
  }
}

[data-contents="original"] .page-bg-box .business-flow {
  margin-bottom: 0;
}

[data-contents="original"] .page-bg-box .business-flow__item {
  width: 280px;
  margin-left: 60px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box .business-flow__item {
    margin-left: 0;
    width: 100%;
  }
}

[data-contents="original"] .page-bg-box .business-flow__item:first-child {
  margin-left: 0;
}

[data-contents="original"] .page-bg-box .business-flow__item:before {
  top: 70px;
  right: -35px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box .business-flow__item:before {
    top: 100%;
    right: inherit;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box .business-flow__item:last-child {
    margin-bottom: 10px;
  }
}

[data-contents="original"] .page-bg-box .business-flow__ttl-wrap {
  width: 280px;
  height: 150px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .page-bg-box .business-flow__ttl-wrap {
    width: 100%;
    height: auto;
  }
}

[data-contents="original"] .business-fig {
  float: left;
  width: 50%;
  text-align: center;
}

[data-contents="original"] .business-fig__ttl {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.06em;
  margin: 0 0 10px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-fig__ttl {
    font-size: 14px;
  }
}

[data-contents="original"] .business-check-box {
  border: 1px solid #193b88;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-check-box {
    padding: 10px;
  }
}

[data-contents="original"] .business-check-box__ttl {
  position: relative;
  font-size: 21px;
  font-weight: bold;
  color: #193b88;
  margin: 0 0 10px;
  padding-left: 20px;
}

[data-contents="original"] .business-check-box__ttl:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  content: "";
  border-left: 10px solid #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-check-box__ttl:before {
    border-left: 5px solid #193b88;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-check-box__ttl {
    font-size: 15px;
    padding-left: 10px;
  }
}

[data-contents="original"] .business-check-box__list {
  margin: 0;
}

[data-contents="original"] .business-check-box__list > li {
  list-style: none;
  font-size: 22px;
  color: #193b88;
  padding-left: 35px;
  background-image: url(../img/common/ico_check_01.png);
  background-repeat: no-repeat;
  background-position: left 4px;
  background-size: 21px auto;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-check-box__list > li {
    font-size: 13px;
    background-size: 12px auto;
    padding-left: 18px;
    line-height: 1.6;
    background-position: left 2px;
  }
}

[data-contents="original"] .business-check-box__sub-list li {
  position: relative;
  padding-left: 20px;
  list-style: none;
  line-height: 1.4;
}

[data-contents="original"] .business-check-box__sub-list li:before {
  position: absolute;
  left: 0;
  top: 10px;
  width: 10px;
  height: 10px;
  background: #193b88;
  border-radius: 50%;
  content: "";
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .business-check-box__ttl:before {
  margin-top: -6px;
}

[data-contents="original"] .business-txt-list {
  margin-bottom: 40px;
}

[data-contents="original"] .business-txt-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .business-txt-list li {
  position: relative;
  font-size: 22px;
  color: #193b88;
  letter-spacing: 0.1em;
  padding-left: 20px;
  box-sizing: border-box;
  list-style: none;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-txt-list li {
    font-size: 13px;
    padding-left: 15px;
  }
}

[data-contents="original"] .business-txt-list li:before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #193b88;
  content: "";
  border-radius: 50%;
  left: 0;
  top: 10px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-txt-list li:before {
    width: 7px;
    height: 7px;
    top: 5px;
  }
}

[data-contents="original"] .business-txt-list li a {
  color: #0071bc;
}

[data-contents="original"] .business-txt-list--3 {
  width: 600px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-txt-list--3 {
    width: 100%;
  }
}

[data-contents="original"] .business-txt-list--3 li {
  float: left;
  width: 33.3%;
}

[data-contents="original"] .business-txt-list--2 {
  width: 500px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-txt-list--2 {
    width: 100%;
  }
}

[data-contents="original"] .business-txt-list--2 li {
  float: left;
  width: 50%;
}

[data-contents="original"] .business-txt-list--2-to-1 {
  width: 100%;
}

[data-contents="original"] .business-txt-list--2-to-1 li {
  float: left;
}

[data-contents="original"] .business-txt-list--2-to-1 li:nth-child(odd) {
  width: 380px;
  clear: both;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .business-txt-list--2-to-1 li:nth-child(odd) {
    width: 100%;
  }
}

[data-contents="original"] .business-txt-list--2-to-1 li.to1 {
  width: 100%;
}

[data-contents="original"] .business-txt-list--mb0 {
  margin-bottom: 0;
}

[data-contents="original"] .product-price {
  margin: 0;
  vertical-align: bottom;
  color: #193b88;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-price {
    margin-top: 5px !important;
    font-size: 13px;
  }
}

[data-contents="original"] .product-price .en-font, [data-contents="original"] .product-price .pager {
  font-size: 52px;
  font-weight: bold;
  letter-spacing: 0.08em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-price .en-font, [data-contents="original"] .product-price .pager {
    font-size: 24px;
  }
}

[data-contents="original"] .product-price__note {
  display: inline-block;
  font-size: 18px;
  margin-left: 5px;
  color: #000;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-price__note {
    font-size: 10px;
  }
}

[data-contents="original"] .product-price__tel {
  margin-left: 10px;
  width: 164px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-price__tel {
    display: block;
    margin-left: 0;
    margin-bottom: 5px;
    width: 120px;
  }
}

[data-contents="original"] .product-contact-list {
  margin-left: -30px;
  margin-bottom: 10px;
}

[data-contents="original"] .product-contact-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list {
    margin-left: 0;
  }
}

[data-contents="original"] .product-contact-list__item {
  float: left;
  margin-left: 30px;
  width: 340px;
  height: 60px;
  list-style: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list__item {
    width: 280px;
    height: 40px;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .product-contact-list__link {
  display: block;
  background: #0064aa;
  color: #fff;
  font-size: 22px;
  background-repeat: no-repeat;
  line-height: 60px;
  opacity: 1;
  transition: opacity 300ms;
}

[data-contents="original"] .product-contact-list__link:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list__link {
    font-size: 15px;
    line-height: 40px;
    padding: 0 !important;
    text-align: center;
  }
}

[data-contents="original"] .product-contact-list__link--download {
  padding: 0 0 0 100px;
  background-position: 40px center;
  background-image: url(../img/common/ico_download_01.png);
  background-size: 27px auto;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list__link--download {
    background-size: 16px auto;
    background-position: 20px center;
  }
}

[data-contents="original"] .product-contact-list__link--phone {
  padding: 0 0 0 100px;
  background-position: 42px center;
  background-image: url(../img/common/ico_phone_01.png);
  background-size: 27px auto;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list__link--phone {
    background-size: 16px auto;
    background-position: 20px center;
  }
}

[data-contents="original"] .product-contact-list__link--mail {
  padding: 0 0 0 130px;
  background-position: 60px center;
  background-image: url(../img/common/ico_mail_01.png);
  background-size: 40px auto;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list__link--mail {
    background-size: 24px auto;
    background-position: 20px center;
  }
}

[data-contents="original"] .product-contact-list .en-font, [data-contents="original"] .product-contact-list .pager {
  font-size: 29px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-contact-list .en-font, [data-contents="original"] .product-contact-list .pager {
    font-size: 24px;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .product-contact-list__link {
  line-height: 68px;
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .product-contact-list__link--download {
  background-position: 40px 8px;
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .product-contact-list__link--phone {
  background-position: 42px 8px;
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .product-contact-list__link--mail {
  background-position: 60px 14px;
}

[data-contents="original"] .product-dtl dd {
  margin-left: 320px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .product-dtl dd {
    margin-left: 0;
  }
}

/* ------------------------------------------------
  recruit
---------------------------------------------- */
[data-contents="original"] .recruit-wrap {
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-wrap {
    padding: 40px 0;
  }
}

[data-contents="original"] .recruit-bnr {
  position: relative;
  width: 1080px;
  box-sizing: border-box;
  border: 2px solid #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr {
    width: 100%;
  }
}

[data-contents="original"] .recruit-bnr__txt {
  display: table;
  height: 317px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr__txt {
    width: 100% !important;
    height: 80px;
    float: none !important;
    text-align: center;
    border: none !important;
    border-bottom: 2px solid #193b88 !important;
  }
}

[data-contents="original"] .recruit-bnr__txt-inner {
  display: table-cell;
  vertical-align: middle;
}

[data-contents="original"] .recruit-bnr__txt-sub {
  font-size: 36px;
  font-weight: bold;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr__txt-sub {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

[data-contents="original"] .recruit-bnr__txt-year {
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr__txt-year {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr__img {
    float: none !important;
  }
}

[data-contents="original"] .recruit-bnr__ttl {
  line-height: 1.2;
  letter-spacing: 0.12em;
  text-align: left;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr__ttl {
    font-size: 18px !important;
    text-align: center;
    font-weight: bold !important;
  }
}

[data-contents="original"] .recruit-bnr__ttl:after {
  display: none;
}

[data-contents="original"] .recruit-bnr__link {
  display: block;
  opacity: 1;
  transition: opacity 300ms;
}

[data-contents="original"] .recruit-bnr__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

[data-contents="original"] .recruit-bnr__link:hover {
  opacity: 0.7;
}

[data-contents="original"] .recruit-bnr--new {
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr--new {
    margin-bottom: 20px;
  }
}

[data-contents="original"] .recruit-bnr--new .recruit-bnr__img {
  float: right;
}

[data-contents="original"] .recruit-bnr--new .recruit-bnr__txt {
  float: right;
  background: #193b88;
  color: #fff;
  padding-left: 33px;
  width: 271px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr--new .recruit-bnr__txt {
    padding-left: 0;
  }
}

[data-contents="original"] .recruit-bnr--new .recruit-bnr__ttl {
  font-size: 32px;
  font-weight: bold;
  overflow: inherit !important;
}

[data-contents="original"] .recruit-bnr--career {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr--career {
    margin-top: 20px;
  }
}

[data-contents="original"] .recruit-bnr--career .recruit-bnr__img {
  float: left;
}

[data-contents="original"] .recruit-bnr--career .recruit-bnr__txt {
  float: left;
  text-align: center;
  width: 268px;
  border-right: 2px solid #193b88;
  background: #fff;
  box-sizing: border-box;
  border-left: none;
}

[data-contents="original"] .recruit-bnr--career .recruit-bnr__ttl {
  font-size: 32px;
  color: #193b88;
  font-weight: bold;
  text-align: center;
  overflow: inherit !important;
}

[data-contents="original"] .recruit-bnr-list {
  text-align: center;
  margin: 0;
}

[data-contents="original"] .recruit-bnr-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr-list {
    padding: 0 5px;
  }
}

[data-contents="original"] .recruit-bnr-list li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr-list li {
    position: relative;
    margin: 0;
    float: left;
    width: 50%;
    display: block;
  }
  [data-contents="original"] .recruit-bnr-list li:nth-child(odd) {
    left: -5px;
  }
  [data-contents="original"] .recruit-bnr-list li:nth-child(even) {
    left: 5px;
  }
}

[data-contents="original"] .recruit-bnr-list__link {
  display: block;
  opacity: 1;
  transition: opacity 300ms;
}

[data-contents="original"] .recruit-bnr-list__link:hover {
  opacity: .7;
  text-decoration: none;
}

[data-contents="original"] .recruit-bnr-list__ttl {
  margin: 0 0 10px;
  text-align: center;
  font-size: 20px;
  color: #193b88;
  box-shadow: none;
  border: none;
  background: transparent;
  font-weight: bold;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr-list__ttl {
    font-size: 16px;
  }
}

[data-contents="original"] .recruit-bnr-list--mb {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-bnr-list--mb {
    margin-bottom: 40px;
  }
}

[data-contents="original"] .recruit-nav__list {
  margin-left: -80px;
}

[data-contents="original"] .recruit-nav__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-nav__list {
    margin-left: 0;
  }
}

[data-contents="original"] .recruit-nav__item {
  float: left;
  margin-left: 80px;
  margin-bottom: 80px;
  width: 500px;
  list-style: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-nav__item {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 40px;
  }
}

[data-contents="original"] .recruit-nav__item:nth-child(odd) {
  clear: both;
}

[data-contents="original"] .recruit-nav__link {
  display: block;
}

[data-contents="original"] .recruit-nav__link:hover {
  text-decoration: none;
}

[data-contents="original"] .recruit-nav__link:hover img {
  transform: scale(1.1);
}

[data-contents="original"] .recruit-nav__img {
  overflow: hidden;
}

[data-contents="original"] .recruit-nav__img img {
  transition: transform 300ms;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-nav__img {
    position: relative;
    height: 200px;
    overflow: hidden;
  }
  [data-contents="original"] .recruit-nav__img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
}

[data-contents="original"] .recruit-nav__ttl {
  padding: 27px 0;
  line-height: 1;
  margin: 0 0 20px;
  font-size: 23px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.28em;
  background: #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-nav__ttl {
    margin-bottom: 10px;
    font-size: 18px;
    padding: 15px 0;
  }
}

[data-contents="original"] .recruit-nav__ttl:after {
  display: none;
}

[data-contents="original"] .recruit-nav__txt {
  margin: 0;
  font-size: 20px;
  color: #193b88;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-nav__txt {
    font-size: 13px;
  }
}

[data-contents="original"] .recruit-message {
  position: relative;
  margin-bottom: 70px;
}

[data-contents="original"] .recruit-message:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message {
    margin-bottom: 40px;
  }
}

[data-contents="original"] .recruit-message .page-block__line-ttl {
  letter-spacing: 0;
}

[data-contents="original"] .recruit-message__txt {
  position: relative;
  width: 543px;
  font-size: 20px;
  line-height: 2;
  z-index: 2;
  font-weight: normal;
  letter-spacing: 0.08em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message__txt {
    width: 100%;
    font-size: 13px;
    float: none !important;
    margin-bottom: 10px;
  }
}

[data-contents="original"] .recruit-message__img {
  position: relative;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message__img {
    float: none !important;
  }
}

[data-contents="original"] .recruit-message__name-wrap {
  position: absolute;
  bottom: 18px;
  right: -23px;
  width: 236px;
  height: 100px;
  box-sizing: border-box;
  background: #002870;
  color: #fff;
  box-sizing: border-box;
  padding: 10px 20px 10px 30px;
  box-shadow: 0 0 10px rgba(248, 248, 249, 0.75);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message__name-wrap {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    text-align: center;
    box-shadow: none;
  }
}

[data-contents="original"] .recruit-message__name-wrap:before {
  position: absolute;
  top: 0;
  left: -14px;
  border-right: 14px solid #002870;
  border-bottom: 14px solid transparent;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message__name-wrap:before {
    display: none;
  }
}

[data-contents="original"] .recruit-message__name {
  font-size: 16px;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message__name {
    font-size: 10px;
  }
}

[data-contents="original"] .recruit-message__name span {
  margin-top: 10px;
  font-size: 27px;
  display: block;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message__name span {
    font-size: 15px;
  }
}

[data-contents="original"] .recruit-message--01 .recruit-message__txt {
  float: right;
}

[data-contents="original"] .recruit-message--01 .recruit-message__img {
  float: left;
}

[data-contents="original"] .recruit-message--02 .recruit-message__txt {
  float: left;
}

[data-contents="original"] .recruit-message--02 .recruit-message__img {
  position: absolute;
  top: 110px;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message--02 .recruit-message__img {
    position: relative;
    top: 0;
  }
}

[data-contents="original"] .recruit-message--03 {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-message--03 {
    margin-bottom: 40px;
  }
}

[data-contents="original"] .recruit-message--03 .recruit-message__txt {
  width: auto;
}

[data-contents="original"] .recruit-message--03 .recruit-message__img {
  float: left;
  margin: 0 20px 10px 0;
}

[data-contents="original"] .recruit-talk {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk {
    padding-bottom: 40px;
  }
}

[data-contents="original"] .recruit-talk__mv {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__mv {
    margin-bottom: 20px;
  }
}

[data-contents="original"] .recruit-talk__top-txt {
  font-size: 21px;
  text-align: center;
  color: #193b88;
  letter-spacing: 0.08em;
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__top-txt {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

[data-contents="original"] .recruit-talk__theme {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__theme {
    width: 110px;
    margin: 0 auto 5px;
  }
}

[data-contents="original"] .recruit-talk__ttl {
  margin: 0 0 50px;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  letter-spacing: 0.12em;
  color: #193b88;
}

[data-contents="original"] .recruit-talk__ttl:after {
  display: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__ttl {
    margin: 0 0 20px;
    font-size: 16px;
  }
}

[data-contents="original"] .recruit-talk__block:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block {
    margin: 0 0 20px !important;
  }
}

[data-contents="original"] .recruit-talk__block-txt {
  font-size: 20px;
  line-height: 2;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block-txt {
    font-size: 13px;
  }
}

[data-contents="original"] .recruit-talk__block-txt--right {
  text-align: right;
}

[data-contents="original"] .recruit-talk__block-txt--center {
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block-txt--center {
    padding-top: 20px;
  }
}

[data-contents="original"] .recruit-talk__block-name {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block-name {
    font-size: 10px;
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block-img {
    width: 85px;
  }
}

[data-contents="original"] .recruit-talk__block--01 {
  margin: 0 60px 30px 140px;
}

[data-contents="original"] .recruit-talk__block--01 .recruit-talk__block-img {
  float: right;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--01 .recruit-talk__block-img {
    margin: 0 0 0 10px;
  }
}

[data-contents="original"] .recruit-talk__block--01 .recruit-talk__block-txt {
  margin: 0 200px 0 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--01 .recruit-talk__block-txt {
    margin: 0;
  }
}

[data-contents="original"] .recruit-talk__block--01 .recruit-talk__block-name {
  margin-left: 50px;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--01 .recruit-talk__block-name {
    margin-left: 35px;
  }
}

[data-contents="original"] .recruit-talk__block--02 {
  margin: 0 140px 30px 60px;
}

[data-contents="original"] .recruit-talk__block--02 .recruit-talk__block-img {
  float: left;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--02 .recruit-talk__block-img {
    margin: 0 10px 0 0;
  }
}

[data-contents="original"] .recruit-talk__block--02 .recruit-talk__block-txt {
  margin: 0 0 0 200px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--02 .recruit-talk__block-txt {
    margin: 0;
  }
}

[data-contents="original"] .recruit-talk__block--02 .recruit-talk__block-name {
  margin-left: 25px;
  color: #F91E46;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--02 .recruit-talk__block-name {
    margin-left: 15px;
  }
}

[data-contents="original"] .recruit-talk__block--03 {
  margin: 0 60px 30px 140px;
}

[data-contents="original"] .recruit-talk__block--03 .recruit-talk__block-img {
  float: right;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--03 .recruit-talk__block-img {
    margin: 0 0 0 10px;
  }
}

[data-contents="original"] .recruit-talk__block--03 .recruit-talk__block-txt {
  margin: 0 200px 0 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--03 .recruit-talk__block-txt {
    margin: 0;
  }
}

[data-contents="original"] .recruit-talk__block--03 .recruit-talk__block-name {
  margin-left: 50px;
  color: #F91E46;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--03 .recruit-talk__block-name {
    margin-left: 35px;
  }
}

[data-contents="original"] .recruit-talk__block--04 {
  margin: 0 140px 30px 60px;
}

[data-contents="original"] .recruit-talk__block--04 .recruit-talk__block-img {
  float: left;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--04 .recruit-talk__block-img {
    margin: 0 10px 0 0;
  }
}

[data-contents="original"] .recruit-talk__block--04 .recruit-talk__block-txt {
  margin: 0 0 0 200px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--04 .recruit-talk__block-txt {
    margin: 0;
  }
}

[data-contents="original"] .recruit-talk__block--04 .recruit-talk__block-name {
  margin-left: 25px;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block--04 .recruit-talk__block-name {
    margin-left: 15px;
  }
}

[data-contents="original"] .recruit-talk__block + .recruit-talk__theme {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__block + .recruit-talk__theme {
    margin-top: 40px;
  }
}

[data-contents="original"] .recruit-talk__img {
  margin: 100px 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk__img {
    margin: 20px 0 40px;
  }
}

[data-contents="original"] .recruit-talk-fig-block {
  background: #e5ebf3;
  margin: 50px 0;
  padding: 25px 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block {
    margin: 20px 0;
  }
}

[data-contents="original"] .recruit-talk-fig-block .container {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block .container {
    display: block;
  }
}

[data-contents="original"] .recruit-talk-fig-block__txt-wrap {
  position: relative;
  width: 720px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block__txt-wrap {
    width: 100%;
    text-align: center;
  }
}

[data-contents="original"] .recruit-talk-fig-block__txt-wrap-inner {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  padding: 0 0 0 100px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block__txt-wrap-inner {
    position: relative;
    padding: 0;
    top: 0;
    left: 0;
    transform: translate(0);
  }
}

[data-contents="original"] .recruit-talk-fig-block__ttl {
  background: #193b88;
  color: #fff;
  display: inline-block;
  padding: 10px 30px;
  line-height: 1;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 25px;
  border: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block__ttl {
    font-size: 12px;
    margin: 0 0 10px;
  }
}

[data-contents="original"] .recruit-talk-fig-block__txt {
  font-size: 21px;
  letter-spacing: 0.1em;
  font-weight: normal;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block__txt {
    font-size: 13px;
    margin: 0 0 20px;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-talk-fig-block__fig {
    width: 178px;
    margin: 0 auto;
  }
}

[data-contents="original"] _:-ms-lang(x), [data-contents="original"] .recruit-talk-fig-block__ttl {
  padding: 16px 30px 10px;
}

[data-contents="original"] .recruit-internship {
  background: #fff;
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 30px 65px 1px;
  border: 1px solid #999;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-internship {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 15px 15px 1px;
  }
}

[data-contents="original"] .recruit-internship__list {
  margin-left: -6px;
}

[data-contents="original"] .recruit-internship__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-internship__list {
    margin-left: 0;
    padding: 0 4px;
  }
}

[data-contents="original"] .recruit-internship__list li {
  list-style: none;
  float: left;
  margin-left: 6px;
  width: 232px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-internship__list li {
    position: relative;
    width: 50%;
    margin-left: 0;
    margin-bottom: 4px;
  }
  [data-contents="original"] .recruit-internship__list li:nth-child(odd) {
    left: -2px;
  }
  [data-contents="original"] .recruit-internship__list li:nth-child(even) {
    left: 2px;
  }
}

[data-contents="original"] .recruit-table {
  margin-bottom: 50px;
}

[data-contents="original"] .recruit-table th {
  width: 150px;
  background: #f0f0f0;
  padding: 30px 30px;
  box-sizing: border-box;
  font-size: 16px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-table th {
    display: block;
    width: 100%;
    padding: 10px 10px;
    text-align: center;
    font-size: 13px;
  }
}

[data-contents="original"] .recruit-table td {
  padding: 30px 30px;
  font-size: 16px;
  background: #fff !important;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-table td {
    display: block;
    padding: 10px;
    font-size: 13px;
  }
}

[data-contents="original"] .recruit-table td strong {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .recruit-table td strong {
    font-size: 16px;
  }
}

/* ------------------------------------------------
  career
---------------------------------------------- */
.container-career {
  width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .container-career {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
  }
}

.career-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border-bottom: 3px solid #193b88;
  box-sizing: border-box;
  background: #fff;
  padding: 10px 0 0;
  z-index: 90;
}

@media screen and (max-width: 768px) {
  .career-header {
    padding: 10px 15px;
    height: 50px;
  }
  .career-header .header__logo {
    float: left;
    padding: 0;
  }
}

.career-header .container-career:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .career-header .container-career {
    padding: 0;
  }
}

.career-header__logo-sub {
  font-size: 12px;
  font-weight: bold;
  float: left;
  display: inline-block;
  border: 2px solid #193b88;
  margin-top: 26px;
  margin-left: 15px;
  padding: 5px 20px;
  line-height: 1;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .career-header__logo-sub {
    font-size: 10px;
    float: right;
    padding: 5px 10px;
    margin: 2px 0 0 0;
  }
}

.career-header__nav-list {
  float: right;
  margin-top: 26px;
}

@media screen and (max-width: 768px) {
  .career-header__nav-list {
    display: none;
  }
}

.career-header__nav-item {
  float: left;
  font-size: 12px;
  width: 100px;
  text-align: center;
  color: #193b88;
  font-weight: bold;
  border-left: 2px solid #193b88;
}

.career-header__nav-item a {
  color: #193b88;
  display: block;
  transition: all 300ms;
}

.career-header__nav-item a:hover {
  background: #193b88;
  color: #fff;
  text-decoration: none;
}

.career-wrap {
  background: #fff;
}

.career-wrap--dtl {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .career-wrap--dtl {
    margin-top: 0;
  }
}

.career-mv {
  position: relative;
  margin-top: 100px;
  min-width: 1080px;
}

@media screen and (max-width: 768px) {
  .career-mv {
    min-width: inherit;
    margin-top: 0;
  }
}

.career-mv__txt-wrap {
  position: absolute;
  top: 65px;
  left: 50%;
  margin-left: -535px;
}

@media screen and (max-width: 768px) {
  .career-mv__txt-wrap {
    position: static;
    top: 0;
    left: 0;
    margin-left: 0;
  }
}

.career-mv__ttl {
  font-size: 53px;
  letter-spacing: 0.16em;
  line-height: 1.4;
  color: #002870;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .career-mv__ttl {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 20px;
    width: 175px;
    transform: translateY(-50%);
    margin-top: -100px;
  }
}

.career-mv__txt {
  font-size: 17px;
  font-family: 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  color: #002870;
  font-weight: bold;
  letter-spacing: 0.14em;
}

@media screen and (max-width: 768px) {
  .career-mv__txt {
    font-size: 12px;
    padding: 0 15px;
    text-align: center;
  }
}

.career-block {
  position: relative;
}

.career-block__ttl {
  position: relative;
  text-align: center;
  font-size: 50px;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
  color: #193b88;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .career-block__ttl {
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.career-block__ttl:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  height: 5px;
  background: #193b88;
  content: "";
}

.career-block__ttl-sub {
  text-align: center;
  font-size: 20px;
  color: #193b88;
  font-weight: bold;
  margin-bottom: 30px;
  letter-spacing: 0.16em;
}

@media screen and (max-width: 768px) {
  .career-block__ttl-sub {
    font-size: 12px;
  }
}

.career-block--appeal {
  margin-top: -325px;
  padding-top: 50px;
  background: transparent;
}

@media screen and (max-width: 768px) {
  .career-block--appeal {
    margin-top: 0;
    padding: 40px 0;
  }
}

.career-block--appeal:before {
  position: absolute;
  bottom: 109px;
  width: 100%;
  height: 71px;
  background: url(../img/carrer/bg_mv_bottom_01.png) no-repeat center top;
  content: "";
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .career-block--appeal:before {
    display: none;
  }
}

.career-block--appeal:after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 109px;
  background: #f1f1f1;
  content: "";
}

@media screen and (max-width: 768px) {
  .career-block--appeal:after {
    display: none;
  }
}

.career-block--works {
  padding: 130px 0 30px;
  margin-top: -100px;
  background: #f1f1f1;
}

@media screen and (max-width: 768px) {
  .career-block--works {
    margin-top: 0;
  }
}

.career-block--interview {
  padding: 140px 0 1px;
  background: #193b88;
}

@media screen and (max-width: 768px) {
  .career-block--interview {
    padding: 40px 0 1px;
  }
}

.career-block--interview .career-block__ttl {
  color: #fff;
}

.career-block--interview .career-block__ttl:before {
  background: #fff;
}

.career-block--interview .career-block__ttl-sub {
  color: #fff;
}

.career-block--recruit {
  padding: 140px 0 90px;
}

@media screen and (max-width: 768px) {
  .career-block--recruit {
    padding: 40px 0;
  }
}

.appeal-list {
  position: relative;
  width: 825px;
  margin: 0 auto;
  z-index: 2;
}

.appeal-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .appeal-list {
    width: 100%;
    text-align: center;
  }
}

.appeal-list__item {
  position: relative;
  float: left;
  width: 235px;
  height: 235px;
  padding: 15px 10px;
  margin-left: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  text-align: center;
  background: rgba(25, 59, 148, 0.9);
  background: -moz-linear-gradient(left, rgba(25, 59, 148, 0.9) 0%, #00005a 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(25, 59, 148, 0.9)), color-stop(100%, #00005a));
  background: -webkit-linear-gradient(left, rgba(25, 59, 148, 0.9) 0%, #00005a 100%);
  background: -o-linear-gradient(left, rgba(25, 59, 148, 0.9) 0%, #00005a 100%);
  background: -ms-linear-gradient(left, rgba(25, 59, 148, 0.9) 0%, #00005a 100%);
  background: linear-gradient(to right, rgba(25, 59, 148, 0.9) 0%, #00005a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  color: #fff;
  letter-spacing: 0.1em;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .appeal-list__item {
    float: none;
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: 0 10px 15px;
    padding: 5px 10px;
  }
}

.appeal-list__item:before {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/carrer/bg_logo.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  content: "";
}

.appeal-list__num {
  display: block;
  margin-bottom: 15px;
  font-size: 19px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .appeal-list__num {
    font-size: 10px;
    margin-bottom: 5px;
  }
}

.appeal-list__ttl {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .appeal-list__ttl {
    margin-bottom: 5px;
    font-size: 12px;
  }
}

.appeal-list__txt {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .appeal-list__txt {
    font-size: 10px;
  }
}

.career-works {
  position: relative;
  margin-top: 40px;
}

.career-works:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.career-works:before {
  position: absolute;
  left: 50%;
  margin-left: -3.5px;
  width: 7px;
  height: 100%;
  background: #193b88;
  content: "";
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .career-works:before {
    display: none;
  }
}

.career-works__logo {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 30px;
  z-index: 5;
}

@media screen and (max-width: 768px) {
  .career-works__logo {
    width: auto;
    height: auto;
    margin: 0 auto 0;
  }
  .career-works__logo img {
    width: 100px;
  }
}

.career-works__logo-txt {
  position: absolute;
  right: -335px;
  top: 30px;
  font-size: 21px;
  color: #193b88;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .career-works__logo-txt {
    position: relative;
    right: 0;
    top: 0;
    margin: 5px 0 15px;
    font-size: 13px;
    text-align: center;
  }
}

.career-works-block {
  position: relative;
  margin: 0 auto 65px;
  width: 450px;
}

@media screen and (max-width: 768px) {
  .career-works-block {
    margin: 0 0 20px;
    width: 100%;
  }
}

.career-works-block__txt {
  position: relative;
  width: 450px;
  font-size: 15px;
  font-weight: normal;
  z-index: 5;
}

@media screen and (max-width: 768px) {
  .career-works-block__txt {
    width: 100%;
    background: #f1f1f1;
    padding: 10px 0;
    font-size: 13px;
  }
}

.career-works-block--01 {
  float: left;
}

@media screen and (max-width: 768px) {
  .career-works-block--01 {
    float: none;
  }
}

.career-works-block--01 .career-works-item:before {
  right: -50px;
}

@media screen and (max-width: 768px) {
  .career-works-block--01 .career-works-item:before {
    display: none;
  }
}

.career-works-block--02 {
  float: right;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .career-works-block--02 {
    float: none;
    margin-top: 0;
  }
}

.career-works-block--02 .career-works-item:before {
  left: -50px;
}

@media screen and (max-width: 768px) {
  .career-works-block--02 .career-works-item:before {
    display: none;
  }
}

.career-works-block:last-child {
  margin-bottom: 20px;
}

.career-works-block:last-child:before {
  position: absolute;
  top: 117px;
  right: -120px;
  width: 150px;
  height: 200px;
  background: #f1f1f1;
  transform: rotate(-20deg);
  content: "";
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .career-works-block:last-child:before {
    display: none;
  }
}

.career-works-item {
  position: relative;
  margin-bottom: 20px;
}

.career-works-item:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .career-works-item {
    margin-bottom: 0;
  }
}

.career-works-item:before {
  position: absolute;
  transform: rotate(-20deg);
  top: 50%;
  width: 60px;
  height: 7px;
  background: #193b88;
  content: "";
  z-index: 2;
}

.career-works-item__img {
  position: relative;
  float: left;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .career-works-item__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100px;
    height: 170px;
    overflow: hidden;
  }
}

.career-works-item__txt-wrap {
  position: relative;
  display: table;
  margin-left: 132px;
  width: 318px;
  height: 225px;
  background: #003a8a;
  z-index: 5;
}

@media screen and (max-width: 768px) {
  .career-works-item__txt-wrap {
    width: 100%;
    height: 170px;
    margin-left: 0;
    padding-left: 100px;
    box-sizing: border-box;
  }
}

.career-works-item__txt-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.career-works-item__num {
  position: absolute;
  top: 2px;
  right: 0;
  padding-right: 27px;
  border-bottom: 2px solid #fff;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .career-works-item__num {
    font-size: 12px;
  }
}

.career-works-item__ttl {
  padding-top: 17px;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .career-works-item__ttl {
    font-size: 18px;
    font-weight: bold;
  }
}

.career-works-item__ttl span {
  font-size: 22px;
  display: block;
}

@media screen and (max-width: 768px) {
  .career-works-item__ttl span {
    font-size: 14px;
  }
}

.career-works-item__txt {
  position: relative;
  font-size: 16px;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .career-works-item__txt {
    font-size: 12px;
  }
}

_:-ms-lang(x), .career-works-item__num {
  top: 10px;
  line-height: 1;
}

.career-interview {
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .career-interview {
    padding-top: 0;
  }
}

.career-interview-item {
  margin-bottom: 50px;
  background: #fff;
}

.career-interview-item__ttl {
  position: relative;
  font-size: 17px;
  margin-bottom: 10px;
  color: #193b88;
  font-weight: bold;
  border-bottom: 3px solid #c7c7c7;
  letter-spacing: 0.12em;
}

@media screen and (max-width: 768px) {
  .career-interview-item__ttl {
    font-size: 15px;
  }
}

.career-interview-item__ttl:before {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 230px;
  height: 3px;
  background: #193b88;
  content: "";
}

.career-interview-item__txt {
  font-size: 13px;
  letter-spacing: 0.12em;
  font-weight: normal;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .career-interview-item__txt {
    font-size: 12px;
    line-height: 1.7;
  }
}

.career-interview-item__txt + .career-interview-item__ttl {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .career-interview-item__txt + .career-interview-item__ttl {
    margin-top: 20px;
  }
}

.career-interview-item__txt-wrap {
  width: 650px;
  padding: 50px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .career-interview-item__txt-wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    float: none !important;
  }
}

.career-interview-item__img {
  position: relative;
}

@media screen and (max-width: 768px) {
  .career-interview-item__img {
    width: 200px;
    height: 250px;
    margin: 0 auto !important;
    overflow: hidden;
  }
}

.career-interview-item__name {
  position: absolute;
  bottom: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .career-interview-item__name {
    font-size: 12px;
    bottom: 10px;
  }
}

.career-interview-item__name span {
  font-size: 23px;
  display: block;
}

@media screen and (max-width: 768px) {
  .career-interview-item__name span {
    font-size: 18px;
  }
}

.career-interview-item--01 .career-interview-item__txt-wrap {
  float: right;
}

.career-interview-item--01 .career-interview-item__img {
  margin-right: 650px;
}

.career-interview-item--01 .career-interview-item__name {
  left: 25px;
}

.career-interview-item--02 .career-interview-item__txt-wrap {
  float: left;
}

.career-interview-item--02 .career-interview-item__img {
  margin-left: 650px;
}

.career-interview-item--02 .career-interview-item__name {
  right: 25px;
  text-align: right;
}

.career-recruit-block {
  text-align: center;
  margin-bottom: 50px;
}

.career-recruit-block__category {
  display: inline-block;
  border: 1px solid #193b88;
  margin: 60px 0;
  width: 330px;
  padding: 5px 0;
  font-size: 21px;
  color: #464646;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__category {
    font-size: 16px;
    width: 280px;
    margin: 20px 0;
  }
}

.career-recruit-block__list {
  margin-left: -50px;
}

.career-recruit-block__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .career-recruit-block__list {
    margin-left: 0;
  }
}

.career-recruit-block__item {
  position: relative;
  width: 300px;
  float: left;
  margin-left: 50px;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__item {
    margin-left: 0;
    float: none;
    width: 100%;
    height: auto !important;
    margin: 0 auto 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #193b88;
  }
}

.career-recruit-block__item > a {
  display: block;
}

.career-recruit-block__item > a:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.career-recruit-block__item > a:hover .career-recruit-block__dtl {
  opacity: .7;
}

.career-recruit-block__dtl {
  position: relative;
  height: 250px;
  margin-bottom: 10px;
  opacity: 1;
  transition: opacity 300ms;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__dtl {
    width: 180px;
    height: 150px;
    float: left;
  }
}

.career-recruit-block__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 250px;
  display: table;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__inner {
    width: 180px;
    height: 150px;
  }
}

.career-recruit-block__txt-wrap {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__txt-wrap {
    padding: 0 10px;
  }
}

.career-recruit-block__ttl {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__ttl {
    font-size: 16px;
  }
}

.career-recruit-block__ttl span {
  display: block;
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__ttl span {
    font-size: 15px;
  }
}

.career-recruit-block__txt {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__txt {
    font-size: 12px;
  }
}

.career-recruit-block__note {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__note {
    margin-left: 195px;
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.career-recruit-block__contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__contact {
    clear: both;
    position: relative;
  }
}

.career-recruit-block__contact li {
  display: inline-block;
  font-size: 13px;
  border: 2px solid #193b88;
  text-align: center;
  border-radius: 5px;
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__contact li {
    display: inline-block !important;
  }
}

.career-recruit-block__contact li a {
  display: block;
  padding: 5px 25px;
  line-height: 1;
  color: #193b88;
  transition: all 300ms;
  font-weight: bold;
}

.career-recruit-block__contact li a:hover {
  background: #193b88;
  color: #fff;
  text-decoration: none;
}

.career-recruit-block__announce {
  color: #193b88;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .career-recruit-block__announce {
    font-size: 14px;
  }
}

_:-ms-lang(x), .career-recruit-block__category {
  padding: 11px 0 5px;
}

.career-footer {
  background: #193b88;
  color: #fff;
  text-align: center;
}

.career-footer .container-career:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.career-footer__ttl {
  margin-bottom: 15px;
  padding-top: 15px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .career-footer__ttl {
    font-size: 16px;
  }
}

.career-footer__nav {
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .career-footer__nav {
    padding-bottom: 10px;
  }
}

.career-footer__nav li {
  display: inline-block;
  border-left: 1px solid #fff;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .career-footer__nav li {
    margin-bottom: 10px;
  }
}

.career-footer__nav a {
  display: block;
  padding: 10px 20px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  transition: all 300ms;
  min-width: 150px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .career-footer__nav a {
    font-size: 12px;
    min-width: inherit;
    padding: 5px 15px;
  }
}

.career-footer__nav a:hover {
  background: #fff;
  color: #193b88;
  text-decoration: none;
}

.career-footer__logo {
  display: block;
  float: left;
}

@media screen and (max-width: 768px) {
  .career-footer__logo {
    float: none;
    padding: 0;
  }
}

.career-footer__copyright {
  padding: 10px 0;
}

.career-footer__copyright .footer__copyright-txt {
  text-align: left;
  padding-top: 5px;
  margin-left: 290px;
  font-size: 13px;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .career-footer__copyright .footer__copyright-txt {
    text-align: left;
    margin-left: 0;
    font-size: 10px;
  }
}

.career-post {
  padding: 100px 0 1px;
  background: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .career-post {
    padding: 40px 0 10px;
  }
}

.career-post__ttl {
  font-size: 36px;
  font-weight: bold;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  .career-post__ttl {
    font-size: 18px;
  }
}

.career-post + .career-wrap {
  padding: 80px 0 0;
}

@media screen and (max-width: 768px) {
  .career-post + .career-wrap {
    padding: 40px 0 0;
  }
}

.career-post-container {
  background: #fff;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 30px 65px 1px;
  border: 1px solid #999;
}

@media screen and (max-width: 768px) {
  .career-post-container {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 15px 15px 1px;
  }
}

.career-table {
  margin-bottom: 50px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.career-table th {
  width: 150px;
  background: #f0f0f0;
  padding: 25px 20px;
  box-sizing: border-box;
  font-size: 16px;
  vertical-align: middle;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 768px) {
  .career-table th {
    display: block;
    width: 100%;
    padding: 10px 10px;
    text-align: center;
    font-size: 13px;
    border-bottom: none;
  }
}

.career-table td {
  padding: 30px 30px;
  font-size: 16px;
  background: #fff !important;
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 768px) {
  .career-table td {
    display: block;
    padding: 10px;
    font-size: 13px;
    border-left: 1px solid #ccc;
  }
}

#contact_form {
  padding-top: 140px;
  margin-top: -90px;
}

@media screen and (max-width: 768px) {
  #contact_form {
    padding-top: 0;
    margin-top: 0;
  }
}

/* ------------------------------------------------
  form
---------------------------------------------- */
/*ここから*/
#mail table {
  margin-left: 15px;
  margin-right: 15px;
  display: block;
  width: auto;
}

#mail .col-input {
  width: 240px !important;
}

#mail .col-description {
  font-size: 10px;
}

.submit {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

#flashMessage {
  padding: 10px 20px;
  margin: 15px auto !important;
  color: #C30;
  font-weight: bold;
  border: 5px solid #C30;
}

.error-message {
  color: #C30;
  background: url(../../img/admin/warning.png) no-repeat left center;
  padding-left: 24px;
  font-weight: bold;
  font-size: small;
}

#MailMessageIndexForm .required {
  color: #FFF;
  font-size: 10px;
  background-color: #C30;
  padding: 4px 5px;
  font-weight: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

#MailMessageIndexForm .normal {
  color: #FFF;
  font-size: 10px;
  background-color: #CCC;
  padding: 4px 5px;
  font-weight: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.form-error {
  background-color: #FCF5EB;
}

.message {
  color: #C30;
  font-weight: bold;
  margin: 20px;
}

.error {
  color: #C30;
}

.error em {
  color: #06C;
  font-style: normal;
  font-weight: bold;
}

.submit {
  text-align: center;
}

/*ここまで*/
.form-note-list {
  margin: 40px 0;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .form-note-list {
    margin: 20px 0;
    font-size: 12px;
  }
}

.form-note-list li {
  margin-left: 1em;
  text-indent: -1em;
}

.form-note-list .required {
  color: #f00;
}

.globalsign-mark {
  float: right;
  margin-top: -118px;
}

@media screen and (max-width: 768px) {
  .globalsign-mark {
    margin-top: 0;
    float: none;
    margin-bottom: 40px;
  }
}

.form-submit {
  clear: both;
  display: block;
  background: #193b88;
  margin: 0 auto 100px;
  padding: 30px 0;
  text-align: center;
  opacity: 1;
  transition: opacity 300ms;
  -webkit-appearance: none;
  width: 100%;
  font-size: 28px;
  color: #fff;
  border: none;
  letter-spacing: 0.06em;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0;
  line-height: 1;
}

.form-submit:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .form-submit {
    font-size: 16px;
    padding: 20px 0;
    width: 80%;
    margin: 0 auto 40px;
  }
}

.form-submit + .form-submit {
  margin-top: -50px;
}

@media screen and (max-width: 768px) {
  .form-submit + .form-submit {
    margin-top: -20px;
  }
}

.row-table-01 {
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
  border-top: 2px solid #ccc;
  background: #fff;
  font-size: 16px;
  table-layout: fixed;
}

@media screen and (max-width: 768px) {
  .row-table-01 {
    margin: 20px 0;
    border-top: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }
}

.row-table-01 th {
  padding: 10px;
  background: #f0f0f0;
  width: 250px;
  border-bottom: 2px solid #d2d2d2;
  font-size: 16px;
  box-sizing: border-box;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .row-table-01 th {
    width: 100%;
    display: block;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    font-size: 13px;
    text-align: center;
  }
}

.row-table-01 td {
  padding: 10px 30px 10px 30px;
  border-bottom: 2px solid #d2d2d2;
  font-size: 16px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .row-table-01 td {
    display: block;
    width: 100%;
    padding: 15px 10px;
    font-size: 13px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    text-align: center !important;
  }
}

.row-table-01 td input[type="text"] {
  width: 400px;
  border: 2px solid #d2d2d2;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 6px 10px;
  background: #fafafa;
  box-sizing: border-box;
  border-radius: 0;
}

@media screen and (max-width: 768px) {
  .row-table-01 td input[type="text"] {
    width: 100%;
    border: 1px solid #d2d2d2;
    text-align: left !important;
  }
}

.row-table-01 textarea {
  width: 400px;
  height: 100px;
  border: 2px solid #d2d2d2;
  background: #fafafa;
  resize: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  font-size: 16px;
  border-radius: 0;
  padding: 5px 10px;
}

@media screen and (max-width: 768px) {
  .row-table-01 textarea {
    width: 100%;
    border: 1px solid #d2d2d2;
    text-align: left !important;
  }
}

#count_content {
  display: inline-block;
  background: #c8c8c8;
  min-width: 50px;
  height: 22px;
  line-height: 1;
  font-size: 14px;
  padding: 4px 10px;
  box-sizing: border-box;
  text-align: right;
  vertical-align: middle;
}

.required {
  background: none !important;
  color: #f00 !important;
  font-size: 12px !important;
}

.mail-after-attachment {
  margin-left: 10px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .mail-after-attachment {
    font-size: 12px;
  }
}

#MailMessagePostcode {
  width: 140px;
}

#MailMessageBirthdayYear {
  width: 80px;
}

@media screen and (max-width: 768px) {
  #MailMessageBirthdayYear {
    width: 50px;
  }
}

#MailMessageBirthdayMonth,
#MailMessageBirthdayDay {
  width: 80px;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  #MailMessageBirthdayMonth,
  #MailMessageBirthdayDay {
    width: 50px;
    margin-left: 10px;
  }
}

.checkbox {
  display: inline-block;
  margin: 0 5px 5px 0;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .checkbox {
    font-size: 13px;
  }
}

.search-result {
  color: #193b88;
  font-size: 21px;
}

@media screen and (max-width: 768px) {
  .search-result {
    font-size: 15px;
  }
}

.list-num {
  margin: 10px 0;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .list-num {
    font-size: 12px;
  }
}

.list-num a {
  text-decoration: underline;
}

.list-num a:hover {
  text-decoration: none;
}

.result-head {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .result-head {
    font-size: 16px;
  }
}

.result-head a {
  color: #193b88;
  text-decoration: underline;
}

.result-head a:hover {
  text-decoration: none;
}

.result-body {
  margin-bottom: 5px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .result-body {
    font-size: 13px;
  }
}

.result-link {
  word-break: break-all;
}

[data-path*="/search_indices"] .section {
  padding: 15px 0;
  border-bottom: 1px dashed #193b88;
}

[data-path*="/search_indices"] .pager {
  margin: 50px 0 100px;
}

@media screen and (max-width: 768px) {
  [data-path*="/search_indices"] .pager {
    margin: 20px 0 40px;
  }
}

[data-path*="/recruit/entry/confirm"] .section,
[data-path*="/form/confirm"] .section {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  [data-path*="/recruit/entry/confirm"] .section,
  [data-path*="/form/confirm"] .section {
    font-size: 13px;
  }
}

[data-path*="/recruit/entry/submit"] .section,
[data-path*="/form/submit"] .section {
  padding: 100px 0;
  font-size: 18px;
  text-align: center;
}

[data-path*="/recruit/entry/submit"] .section p:first-child,
[data-path*="/form/submit"] .section p:first-child {
  font-size: 24px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  [data-path*="/recruit/entry/submit"] .section p:first-child,
  [data-path*="/form/submit"] .section p:first-child {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  [data-path*="/recruit/entry/submit"] .section,
  [data-path*="/form/submit"] .section {
    padding: 40px 0;
    font-size: 13px;
  }
}

[data-path*="/recruit/entry/submit"] .section a,
[data-path*="/form/submit"] .section a {
  text-decoration: underline;
}

[data-path*="/recruit/entry/submit"] .section a:hover,
[data-path*="/form/submit"] .section a:hover {
  text-decoration: none;
}

.container--404 {
  font-size: 18px;
  padding: 100px 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container--404 {
    padding: 40px 15px;
    font-size: 13px;
  }
}

.container--404 h2 {
  font-size: 36px;
  letter-spacing: 0.08em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .container--404 h2 {
    font-size: 18px;
  }
}

/* ------------------------------------------------
  challenge
---------------------------------------------- */
[data-contents="original"] .challenge-top {
  position: relative;
  margin: 0 auto 205px;
  padding: 35px 0 15px;
  border: 1px solid #193b88;
  background: #f0f0f0;
  box-sizing: border-box;
  text-align: center;
}

[data-contents="original"] .challenge-top:before {
  position: absolute;
  bottom: -135px;
  left: 50%;
  width: 50px;
  height: 50px;
  border-right: 1px solid #193b88;
  border-top: 1px solid #193b88;
  content: "";
  margin-left: -25px;
  transform: rotate(135deg);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top:before {
    bottom: -70px;
  }
}

[data-contents="original"] .challenge-top.challenge-page {
  margin: 0 auto 140px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top.challenge-page {
    margin: 0 auto 100px;
  }
}

[data-contents="original"] .challenge-top.challenge-page:before {
  bottom: -80px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top.challenge-page:before {
    bottom: -60px;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top {
    width: 100%;
    margin: 0 0 120px;
    box-sizing: border-box;
    padding: 10px 0;
  }
}

[data-contents="original"] .challenge-top__list {
  text-align: center;
  margin: 0;
}

[data-contents="original"] .challenge-top__list-note {
  text-align: left;
  margin: 0;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top__list-note {
    font-size: 10px;
    padding: 0 10px;
  }
}

[data-contents="original"] .challenge-top__item {
  display: inline-block;
  list-style: none;
  margin: 0;
  width: 300px;
  height: 300px;
  background: #0064aa;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top__item {
    width: 110px;
    height: 110px;
    margin: 0 5px 5px;
  }
}

[data-contents="original"] .challenge-top__ttl {
  font-size: 23px;
  text-align: center;
  background: transparent;
  text-align: center;
  border: none;
  box-shadow: none;
  overflow: inherit !important;
}

[data-contents="original"] .challenge-top__inner {
  position: relative;
  display: table;
  width: 300px;
  height: 300px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top__inner {
    width: 110px;
    height: 110px;
  }
}

[data-contents="original"] .challenge-top__txt {
  display: table-cell;
  vertical-align: middle;
  padding-top: 10px;
  color: #fff;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.06em;
  font-weight: bold;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top__txt {
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-top__txt span {
  display: block;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top__txt span {
    font-size: 10px;
  }
}

[data-contents="original"] .challenge-top__vision {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 0;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top__vision {
    font-size: 10px;
    top: 10px;
  }
}

[data-contents="original"] .challenge-top-message {
  margin: 0 0 30px;
  font-size: 25px;
  text-align: center;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-top-message {
    font-size: 15px;
  }
}

[data-contents="original"] .challenge-top--no-arrow {
  margin-bottom: 30px;
}

[data-contents="original"] .challenge-top--no-arrow:before {
  display: none;
}

[data-contents="original"] .challenge-page__list {
  text-align: center;
  margin: 0;
}

[data-contents="original"] .challenge-page__item {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  width: 170px;
  height: 170px;
  background: #808080;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-page__item {
    width: 110px;
    height: 110px;
    margin: 0 5px 5px;
  }
}

[data-contents="original"] .challenge-page__ttl {
  font-size: 23px;
  text-align: center;
  background: transparent;
  text-align: center;
  border: none;
  padding: 0;
  box-shadow: none;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-page__ttl {
    font-size: 16px;
  }
}

[data-contents="original"] .challenge-page__inner {
  position: relative;
  display: table;
  width: 170px;
  height: 170px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-page__inner {
    width: 110px;
    height: 110px;
  }
}

[data-contents="original"] .challenge-page__txt {
  display: table-cell;
  vertical-align: middle;
  padding-top: 10px;
  color: #fff;
  font-size: 21px;
  line-height: 1.2;
  letter-spacing: 0.06em;
  font-weight: bold;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-page__txt {
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-page__step {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 0;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-page__step {
    font-size: 10px;
    top: 10px;
  }
}

[data-contents="original"] .challenge-section__ttl {
  position: relative;
  margin: 0 auto 20px;
  font-size: 60px;
  color: #193b88;
  padding: 0 0 23px;
  overflow: inherit !important;
}

[data-contents="original"] .challenge-section__ttl:after {
  display: none;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-section__ttl {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

[data-contents="original"] .challenge-section__ttl-sub {
  display: block;
  text-align: center;
  color: #193b88;
  font-size: 20px;
  letter-spacing: 0.22em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-section__ttl-sub {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-section__ttl:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 150px;
  height: 2px;
  background: #193b88;
  transform: translateX(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-section__ttl:before {
    width: 100px;
  }
}

[data-contents="original"] .challenge-links {
  padding-top: 50px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-links {
    padding-top: 20px;
  }
}

[data-contents="original"] .challenge-page-txt {
  margin-top: 50px !important;
  font-size: 29px;
  font-weight: bold;
  color: #193b88;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-page-txt {
    margin-top: 20px !important;
    font-size: 16px;
  }
}

[data-contents="original"] .challenge-kadai {
  margin-left: -30px;
}

[data-contents="original"] .challenge-kadai:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai {
    margin-left: 0;
  }
}

[data-contents="original"] .challenge-kadai li {
  float: left;
  width: 340px;
  list-style: none;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai li {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}

[data-contents="original"] .challenge-kadai__top {
  background: #193b88;
  height: 340px;
  padding: 60px 0 0;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai__top {
    width: 100%;
    height: auto;
    padding: 15px 0;
  }
}

[data-contents="original"] .challenge-kadai__bottom {
  height: 595px;
  box-sizing: border-box;
  border: 1px solid #193b88;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai__bottom {
    height: auto;
    margin-bottom: 10px;
    padding: 15px 15px 1px;
  }
  [data-contents="original"] .challenge-kadai__bottom img {
    margin: 0 auto 20px;
  }
}

[data-contents="original"] .challenge-kadai__number {
  color: #b4c8f5;
  font-size: 21px;
  margin: 0 0 50px;
  padding: 0;
  letter-spacing: 0.06em;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai__number {
    font-size: 13px;
    margin: 0 0 10px;
  }
}

[data-contents="original"] .challenge-kadai__number:after {
  display: none;
}

[data-contents="original"] .challenge-kadai__ttl {
  margin: 0;
  font-size: 26px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai__ttl {
    font-size: 15px;
  }
}

[data-contents="original"] .challenge-kadai__txt {
  font-size: 21px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-kadai__txt {
    font-size: 13px;
  }
}

[data-contents="original"] .challenge-bg-txt {
  margin-top: 0;
  margin-bottom: 50px;
  padding: 35px 10px;
  background: #193b88;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-bg-txt {
    font-size: 13px;
  }
}

[data-contents="original"] .challenge-line-txt {
  margin-top: 0;
  margin-bottom: 30px;
  padding: 5px 10px;
  background: #193b88;
  font-size: 26px;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-line-txt {
    font-size: 13px;
  }
}

[data-contents="original"] .challenge-table {
  margin: 0 0 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table {
    display: table;
    width: 100%;
  }
  [data-contents="original"] .challenge-table tr {
    display: table-row;
  }
}

[data-contents="original"] .challenge-table th {
  border-width: 10px;
  border-style: solid;
  border-collapse: separate;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table th {
    display: table-cell;
    border: 3px solid #f5f5f5;
  }
}

[data-contents="original"] .challenge-table td {
  border-width: 10px;
  border-style: solid;
  border-collapse: separate;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table td {
    display: table-cell;
    border: 3px solid #f5f5f5;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table thead {
    display: table-row-group;
  }
}

[data-contents="original"] .challenge-table thead th {
  padding: 0;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table thead th {
    width: 33.3%;
    display: table-cell;
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody {
    display: table-row-group;
  }
}

[data-contents="original"] .challenge-table tbody th {
  background: #ccc;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody th {
    font-size: 13px;
    padding: 20px;
  }
}

[data-contents="original"] .challenge-table tbody th span {
  display: block;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody th span {
    font-size: 11px;
  }
}

[data-contents="original"] .challenge-table tbody th .th-inner {
  position: relative;
  padding-left: 60px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody th .th-inner {
    padding-left: 35px;
  }
}

[data-contents="original"] .challenge-table tbody th .number {
  position: absolute;
  top: 50%;
  left: 10px;
  font-size: 25px;
  margin-right: 20px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody th .number {
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-table tbody td {
  background: #e6e6e6 !important;
  text-align: center;
  font-weight: bold;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody td {
    font-size: 15px;
    padding: 20px;
  }
}

[data-contents="original"] .challenge-table tbody td span {
  display: block;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table tbody td span {
    font-size: 11px;
  }
}

[data-contents="original"] .challenge-table tbody .bg th, [data-contents="original"] .challenge-table tbody .bg td {
  background: #193b88 !important;
  color: #fff;
}

[data-contents="original"] .challenge-table--01 th, [data-contents="original"] .challenge-table--01 td {
  border-color: #f5f5f5;
}

[data-contents="original"] .challenge-table--01 thead th {
  background: #f5f5f5;
}

[data-contents="original"] .challenge-table--01 tbody th {
  padding: 32px 20px;
  text-align: center;
}

[data-contents="original"] .challenge-table--01 tbody td {
  font-size: 36px;
  padding: 32px 20px;
}

[data-contents="original"] .challenge-table--02 th, [data-contents="original"] .challenge-table--02 td {
  border-color: #f0f0f0;
}

[data-contents="original"] .challenge-table--02 thead th {
  background: #f0f0f0;
}

[data-contents="original"] .challenge-table--02 tbody th {
  padding: 18px 20px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table--02 tbody th {
    padding: 15px 10px;
  }
}

[data-contents="original"] .challenge-table--02 tbody td {
  padding: 18px 20px;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table--02 tbody td {
    padding: 15px;
    font-size: 13px;
  }
}

[data-contents="original"] .challenge-table--03 {
  table-layout: fixed;
}

[data-contents="original"] .challenge-table--03 th, [data-contents="original"] .challenge-table--03 td {
  border-color: #f5f5f5;
}

[data-contents="original"] .challenge-table--03 thead th {
  background: #f5f5f5;
}

[data-contents="original"] .challenge-table--03 thead th:not(:first-child) {
  width: 250px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table--03 thead th:not(:first-child) {
    width: auto;
  }
}

[data-contents="original"] .challenge-table--03 tbody th {
  padding: 18px 0 18px 10px;
  text-align: left;
  background: #193b88;
  color: #fff;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table--03 tbody th {
    padding: 15px 10px;
  }
}

[data-contents="original"] .challenge-table--03 tbody td {
  font-size: 15px;
  padding: 20px 20px;
  background: #005096 !important;
  color: #fff;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-table--03 tbody td {
    padding: 15px;
    font-size: 13px;
  }
}

[data-contents="original"] .challenge-table--03 tbody td:nth-of-type(2n) {
  background: #5064aa !important;
}

[data-contents="original"] .challenge-system-fig {
  position: relative;
  border: 1px solid #193b88;
  background: #f0f0f0;
  margin-bottom: 20px;
  padding: 10px 10px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-fig {
    padding: 20px 10px 10px;
  }
}

[data-contents="original"] .challenge-system-fig__ttl {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  border: 1px solid #193b88;
  background: #f0f0f0;
  font-size: 21px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-fig__ttl {
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-system-flow {
  text-align: center;
  margin: 0 0 20px;
}

[data-contents="original"] .challenge-system-flow li {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0 3px 20px;
  vertical-align: top;
}

[data-contents="original"] .challenge-system-flow__item {
  margin: 0;
  width: 170px;
  height: 170px;
  background: #193b88;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-flow__item {
    width: 110px;
    height: 110px;
    margin: 0 5px 5px;
  }
}

[data-contents="original"] .challenge-system-flow__inner {
  position: relative;
  display: table;
  width: 170px;
  height: 170px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-flow__inner {
    width: 110px;
    height: 110px;
  }
}

[data-contents="original"] .challenge-system-flow__ttl {
  display: table-cell;
  vertical-align: middle;
  padding-top: 10px;
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.06em;
  font-weight: bold;
  margin: 0;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-flow__ttl {
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-system-flow__step {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 0;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-flow__step {
    font-size: 10px;
    top: 10px;
  }
}

[data-contents="original"] .challenge-system-flow__txt {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  margin: 0;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-flow__txt {
    font-size: 12px;
  }
}

[data-contents="original"] .challenge-system-flow__note {
  position: relative;
  display: table;
  padding: 10px 0;
  width: 170px;
  height: 84px;
  box-sizing: border-box;
  border-left: 1px solid #193b88;
  border-right: 1px solid #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-system-flow__note {
    width: 110px;
  }
}

[data-contents="original"] .challenge-system-flow__note:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 11px;
  height: 100%;
  border-top: 1px solid #193b88;
  border-bottom: 1px solid #193b88;
  content: "";
  box-sizing: border-box;
}

[data-contents="original"] .challenge-system-flow__note:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 100%;
  border-top: 1px solid #193b88;
  border-bottom: 1px solid #193b88;
  content: "";
  box-sizing: border-box;
}

[data-contents="original"] .challenge-fig-list {
  margin-left: -30px;
  margin-bottom: 50px;
}

[data-contents="original"] .challenge-fig-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-fig-list {
    margin-left: 0;
    margin-bottom: 0;
  }
}

[data-contents="original"] .challenge-fig-list li {
  width: 340px;
  float: left;
  list-style: none;
  margin: 0;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-fig-list li {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

[data-contents="original"] .challenge-fig-list__inner {
  position: relative;
  width: 340px;
  height: 340px;
  margin-bottom: 10px;
  border: 1px solid #193b88;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-fig-list__inner {
    width: 100%;
    height: auto;
    padding: 10px;
    margin-bottom: 5px;
  }
}

[data-contents="original"] .challenge-fig-list__inner img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-fig-list__inner img {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
  }
}

[data-contents="original"] .challenge-fig-list__ttl {
  margin: 0;
  text-align: center;
  font-size: 16px;
  color: #193b88;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-fig-list__ttl {
    font-size: 13px;
  }
}

[data-contents="original"] .challenge-ex-ttl {
  font-size: 30px;
  color: #0064aa;
  font-weight: bold;
  border: none;
  padding: 0;
  margin: 60px 0 10px;
  box-shadow: none;
  overflow: inherit !important;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-ex-ttl {
    font-size: 16px;
    margin: 40px 0 10px;
  }
}

[data-contents="original"] .challenge-ex-ttl span {
  font-size: 23px;
}

@media screen and (max-width: 768px) {
  [data-contents="original"] .challenge-ex-ttl span {
    font-size: 13px;
  }
}
