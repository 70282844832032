/* ------------------------------------------------
  form
---------------------------------------------- */
  /*ここから*/
  #mail table {
	margin-left:15px;
	margin-right:15px;
	display:block;
	width:auto;
}
#mail .col-input {
	width:240px!important;
}
#mail .col-description {
	font-size:10px;
}
.submit {
	text-align:center;
	margin-top:20px;
	margin-bottom:20px;
}
#flashMessage {
	padding:10px 20px;
	margin:15px auto!important;
	color:#C30;
	font-weight:bold;
	border:5px solid #C30;
}
.error-message {
	color:#C30;
	background: url(../../img/admin/warning.png) no-repeat left center;
	padding-left:24px;
	font-weight: bold;
	font-size: small;
}
#MailMessageIndexForm .required{
    color:#FFF;
    font-size:10px;
    background-color: #C30;
    padding:4px 5px;
    font-weight: normal;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    white-space: nowrap;
    margin-left:5px;
    margin-right:5px;
    vertical-align: middle;
}
#MailMessageIndexForm .normal{
    color:#FFF;
    font-size:10px;
    background-color: #CCC;
    padding:4px 5px;
    font-weight: normal;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    white-space: nowrap;
    margin-left:5px;
    margin-right:5px;
    vertical-align: middle;
}
.form-error {
	background-color:#FCF5EB;
}
.message {
	color:#C30;
	font-weight:bold;
	margin:20px;
}
.error {
	color:#C30;
}
.error em {
	color:#06C;
	font-style:normal;
	font-weight:bold;
}
.submit {
	text-align:center;
}
  
  
  
  /*ここまで*/


.form-note-list {
  margin: 40px 0;
  font-size: 16px;
  @include mq(sp) {
    margin: 20px 0;
    font-size: 12px;
  }
  li {
    margin-left: 1em;
    text-indent: -1em;
  }
  .required{
    color: #f00;
  }
}
.globalsign-mark {
  float: right;
  margin-top: -118px;
  @include mq(sp) {
    margin-top: 0;
    float: none;
    margin-bottom: 40px;
  }
}
.form-submit {
  clear: both;
  display: block;
  background: #193b88;
  margin: 0 auto 100px;
  padding: 30px 0;
  text-align: center;
  @include hover-opacity;
  -webkit-appearance: none;
  width: 100%;
  font-size: 28px;
  color: #fff;
  border: none;
  letter-spacing: 0.06em;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0;
  line-height: 1;
  @include mq(sp) {
    font-size: 16px;
    padding: 20px 0;
    width: 80%;
    margin: 0 auto 40px;
  }
  +.form-submit {
    margin-top: -50px;
    @include mq(sp) {
      margin-top: -20px;
    }
  }
}

.row-table-01 {
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
  border-top: 2px solid #ccc;
  background: #fff;
  font-size: 16px;
  table-layout: fixed;
  @include mq(sp) {
    margin: 20px 0;
    border-top: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }
  th {
    padding: 10px;
    background: #f0f0f0;
    width: 250px;
    border-bottom: 2px solid #d2d2d2;
    font-size: 16px;
    box-sizing: border-box;
    vertical-align: middle;
    @include mq(sp) {
      width: 100%;
      display: block;
      border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
      font-size: 13px;
      text-align: center;
    }
  }
  td {
    padding: 10px 30px 10px 30px;
    border-bottom: 2px solid #d2d2d2;
    font-size: 16px;
    box-sizing: border-box;
    @include mq(sp) {
      display: block;
      width: 100%;
      padding: 15px 10px;
      font-size: 13px;
      border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
      text-align: center !important;
    }
    input[type="text"] {
      width: 400px;
      border: 2px solid #d2d2d2;
      -webkit-appearance: none;
      font-size: 16px;
      padding: 6px 10px;
      background: #fafafa;
      box-sizing: border-box;
      border-radius: 0;
      @include mq(sp) {
        width: 100%;
        border: 1px solid #d2d2d2;
        text-align: left !important;
      }
    }
  }
  textarea {
    width: 400px;
    height: 100px;
    border: 2px solid #d2d2d2;
    background: #fafafa;
    resize: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-size: 16px;
    border-radius: 0;
    padding: 5px 10px;
    @include mq(sp) {
      width: 100%;
      border: 1px solid #d2d2d2;
      text-align: left !important;
    }
  }

}
#count_content {
  display: inline-block;
  background: #c8c8c8;
  min-width: 50px;
  height: 22px;
  line-height: 1;
  font-size: 14px;
  padding: 4px 10px;
  box-sizing: border-box;
  text-align: right;
  vertical-align: middle;
}
.required {
  background: none !important;
  color: #f00 !important;
  font-size: 12px !important;
}
.mail-after-attachment {
  margin-left: 10px;
  font-size: 14px;
  @include mq(sp) {
    font-size: 12px;
  }
}
#MailMessagePostcode {
  width: 140px;
}
#MailMessageBirthdayYear {
  width: 80px;
  @include mq(sp) {
    width: 50px;
  }
}
#MailMessageBirthdayMonth,
#MailMessageBirthdayDay{
  width: 80px;
  margin-left: 30px;
  @include mq(sp) {
    width: 50px;
    margin-left: 10px;
  }
}
.checkbox {
  display: inline-block;
  margin: 0 5px 5px 0;
  font-size: 18px;
  @include mq(sp) {
    font-size: 13px;
  }
}


// search

.search-result {
  color: $blue;
  font-size: 21px;
  @include mq(sp) {
    font-size: 15px;
  }
}
.list-num {
  margin: 10px 0;
  font-size: 16px;
  @include mq(sp) {
    font-size: 12px;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.result-head {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
  @include mq(sp) {
    font-size: 16px;
  }
  a {
  color: $blue;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.result-body {
  margin-bottom: 5px;
  font-size: 16px;
  @include mq(sp) {
    font-size: 13px;
  }
}
.result-link {
  word-break: break-all;
}
[data-path*="/search_indices"] {
  .section {
    padding: 15px 0;
    border-bottom: 1px dashed $blue;
  }
  .pager {
    margin: 50px 0 100px;
    @include mq(sp) {
      margin: 20px 0 40px;
    }
  }
}
[data-path*="/recruit/entry/confirm"],
[data-path*="/form/confirm"]{
  .section {
    font-size: 18px;
    @include mq(sp) {
      font-size: 13px;
    }
  }
}
[data-path*="/recruit/entry/submit"],
[data-path*="/form/submit"] {
  .section {
    padding: 100px 0;
    font-size: 18px;
    text-align: center;
    p:first-child {
      font-size: 24px;
      margin-bottom: 30px;
      @include mq(sp) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    @include mq(sp) {
      padding: 40px 0;
      font-size: 13px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.container--404 {
  font-size: 18px;
  padding: 100px 0;
  text-align: center;
  @include mq(sp) {
    padding: 40px 15px;
    font-size: 13px;
  }
  h2 {
        font-size: 36px;
    letter-spacing: 0.08em;
    font-weight: bold;
    @include mq(sp) {
      font-size: 18px;
    }
  }
} 
